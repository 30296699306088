import React from 'react';
import useDataGrid from '../use-data-grid';

const useColumn = (field: string | boolean) => {

    const grid = useDataGrid();

    //sort type
    const [sortType, setSortType] = React.useState<'asc' | 'desc' | 'none'>('none');

    //is filter active
    const [isActiveFilter, setIsActiveFilter] = React.useState(false);

    //toggles sort type
    const toggleSortType = () => {
        if (typeof field === 'string') {
            const type = sortType;
            if (type === 'none') {
                setSortType('asc');
                grid.setActiveColumnSort(field, 'asc');
            }
            if (type === 'asc') {
                setSortType('desc');
                grid.setActiveColumnSort(field, 'desc');
            }
            if (type === 'desc') {
                setSortType('asc');
                grid.setActiveColumnSort(field, 'asc');
            }
        }
    }

    //hides column
    const hideColumn = (hide: boolean) => {
        if (typeof field === 'string') {
            grid.editColumnField(field, { enabled: (hide) ? false : true })
        }
    }

    //set active filters
    const setActiveFilters = (filterType?: 'select' | 'search') => {
        if (typeof field === 'string' && isActiveFilter && typeof filterType === 'string') {
            grid.updateActiveColumnFilters([...grid.activeColumnFilters, { field: field, type: filterType }]);
        }
        else {
            grid.updateActiveColumnFilters(grid.activeColumnFilters.filter((filter) => filter.field !== field));
        }
    }

    //checks to see if column needs to be hidden
    const isHidden = () => {
        let thisColumn = grid.columnFields.find((item) => item.field === field);
        if (!thisColumn?.enabled) {
            return true;
        }
        return false
    }

    //activates filter icon in column
    const activateFilterIndicator = (value?: string) => {
        if (value && value.length > 0) {
            setIsActiveFilter(true)
        } else {
            setIsActiveFilter(false);
        }
    }

    //resets sort state
    const resetSort = () => {
        if (grid.activeColumnSort.field !== field) {
            setSortType('none');
        }
    }

    //sets column sort to true if column is sortable
    const isColumnSort = (isSortable?: boolean) => {
        if (isSortable) {
            grid.setIsColumnSort(true);
        }
    }

    return {
        hideColumn,
        isHidden,
        toggleSortType,
        setActiveFilters,
        activateFilterIndicator,
        resetSort,
        isColumnSort,
        sortType,
        isActiveFilter
    }
}

export default useColumn;