import React from 'react';
import { ListItemHeader, ListItemDivider, ListItem, InputOption } from '../../atoms';
import IconWithDropdown from '../icon-with-dropdown';
import { useI18N, useInAppMessage, useNonInitialEffect } from '../../../hooks';

interface Props {
    className?: string;
}

export default function LanguageSwitcher({ className }: Props) {

    const i18n = useI18N();
    const message = useInAppMessage();
    const [activeOption, setActiveOption] = React.useState({ value: i18n.localeKey, text: '' });
    const [disabled, setDisabled] = React.useState(false);
    useNonInitialEffect(() => {
        setActiveOption({ value: i18n.localeKey, text: '' });
        setDisabled(false);
    }, [i18n.localeKey]);

    return (
        <>
            <IconWithDropdown disabled={disabled} icon={<i className={`far fa-language`}></i>} caret={false} toolTipPlacement={'left'} className={`${className}`} text={<>Change Language <br /> Cambiar idioma</>}>
                <>
                    <ListItemHeader text={i18n.translateThis('Pick a language', i18n.localize.languageSwitcher.headerText)} />
                    <InputOption
                        option={{ value: 'en', text: 'English' }}
                        index={0}
                        activeOption={activeOption}
                        onSelect={() => {
                            if (i18n.localeKey !== 'en') {
                                setDisabled(true);
                                message.createLoading('Setting language to English.', {dismiss : 500});
                                i18n.setLocaleKey('en');
                            }
                        }}
                    />
                    <InputOption
                        option={{ value: 'es', text: 'Spanish' }}
                        index={0}
                        activeOption={activeOption}
                        onSelect={() => {
                            if (i18n.localeKey !== 'es') {
                                setDisabled(true);
                                message.createLoading('Estableciendo idioma a español', {dismiss : 500});
                                i18n.setLocaleKey('es');
                            }
                        }}
                    />
                </>
            </IconWithDropdown>
        </>
    )
}