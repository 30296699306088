import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-treble';
import { useConfig, useLogin, useStartUp, useResize, usePageState, useResponsive, useI18N, useNonInitialEffect } from '../../hooks';
import { useAppStore } from '../../_Store';

interface Props {
    children: JSX.Element;
}

export default function StartUp({ children }: Props) {

    const [{ ptrui_isAuth: isAuth }] = useAppStore();
    const login = useLogin();
    const history = useHistory();
    const location = useLocation();
    const Ref = React.useRef(null);
    const config = useConfig();
    const startup = useStartUp();
    const respond = useResponsive();
    const page = usePageState();
    const dimensions = useResize(Ref);
    const shouldCompRender = (config.isFrontEndLogin) ? (login.isAuth) ? true : false : true;
    const loginURL = (startup.initialPath.length > 1) ? `/login?redirect:${startup.initialPath}` : '/login';
    const altUrlLogin = config.loginPageInfo.altUrlLogin ? (startup.initialPath.length > 1) ? `${config.loginPageInfo.altUrlLogin}` + (config.loginPageInfo.altUrlLogin !== startup.initialPath ? `?redirect:${startup.initialPath}` : '') : config.loginPageInfo.altUrlLogin : '';
    const i18n = useI18N();

    // watches app resolution
    React.useEffect(() => {
        respond.setRes(dimensions);
    }, [dimensions]);

    //resets page states when navigating
    React.useLayoutEffect(() => {
        if (!page.hasWidgetBar) {
            page.collapse();
        }

        else if (page.hasWidgetBar) {
            if (page.isWidgetBarOpen) {
                page.expand();
            }
            else {
                page.collapse();
            }

        }
    }, [location.pathname, page.hasWidgetBar]);

    //makes sure app redirects to login screen if not authenticated (only for front end login)
    React.useEffect(() => {
        if (!shouldCompRender) {
            if (altUrlLogin)
                history.push(altUrlLogin);
            else
                history.push(loginURL);
        }
    }, [location.pathname]);

    //sets i18n localize data
    useNonInitialEffect(() => {
        i18n.fetchLocalizeData(i18n.localeKey);
    }, [i18n.localeKey]);

    return (
        <>

            {/** Redirects to login page if not Auth. When Auth it redirects to intial navigated page*/}
            {
                (config.isFrontEndLogin) ?
                    (isAuth) ? <Redirect to={"/"} /> : !altUrlLogin ? <Redirect to={loginURL} /> : <Redirect to={altUrlLogin} /> : <Redirect to={startup.initialPath} />
            }

            <div ref={Ref} className={'ptrui-startup-container'}>
                {children}
            </div>
        </>
    )
}