import React from 'react';
import GridProvider from '../../molecules/grid-provider';
import Content from '../../organisms/content';
import WatchLayout from './watch-layout';

export interface GridProps {
    children?: JSX.Element | JSX.Element[];
    height?: string;
    loading?: boolean;
    data: any[];
    as: JSX.Element | object;
    exportPDF?: boolean;
    exportExcel?: boolean;
    fetchStatus?: any;
    pageSize?: number;
    totalDataCount?: number;
    onRefresh?: () => void;
    layoutType?: 1 | 2 | 3;
    exportFields?: {
        field: string;
        label?: string;
    }[];
    isNested?: boolean;
    onSelect?: (selectedRows: any[]) => void;
    onFilterChange?: (filterQueries: {
        target: string;
        query: string | number | {
            from?: string | undefined;
            to?: string | undefined;
        } | null;
        exact: boolean;
        type: 'string' | 'number' | 'dateRange';
        [key: string]: any;
    }[]) => void;
    onBottomScroll?: (meta: { activePage: number, isPageEnd: boolean }) => void;
    persistSelected?: boolean;
}

function CardGridComp({ children, height, loading, data, as, exportExcel, exportPDF, fetchStatus, totalDataCount, onRefresh, layoutType, exportFields, isNested, onFilterChange, pageSize, onBottomScroll, onSelect, persistSelected }: GridProps) {

    return (
        <>
            <Content.Panel bodyClassName={`p-0`} bgNone={isNested}>
                <GridProvider
                    data={data}
                    height={height}
                    loading={loading}
                    as={as}
                    pageSize={pageSize}
                    exportExcel={exportExcel}
                    exportPDF={exportPDF}
                    fetchStatus={fetchStatus}
                    totalDataCount={totalDataCount}
                    onRefresh={onRefresh}
                    layoutType={layoutType}
                    fields={exportFields}
                    filterReturnType={'or'}
                    isNested={isNested}
                    onFilterChange={onFilterChange}
                    onBottomScroll={onBottomScroll}
                    onSelect={onSelect}
                    persistSelected={persistSelected}
                    disableClientRendering
                >
                    <>
                        <WatchLayout layoutType={layoutType || 3} />
                        {children}
                    </>
                </GridProvider>
            </Content.Panel>
        </>
    )
}

export default CardGridComp;