import React from 'react';
import { default as CardGridComp } from './card-grid';
import Header from './header';
import Body from './body';
import Footer from './footer';
import Search from './search';
import UilityBar from './utility-bar';
import LayoutOptions from './layout-options';
import SelectedActions from './selected-actions';
import ExportToExcel from './export-to-excel';
import ExportToPDF from './export-to-pdf';
import RemoveItem from './remove-item';
import RefreshData from './refresh-data';
import Counters from './counters';
import AlphaFilter from './alpha-filter';
import { GridProps } from './card-grid';
import { useGrid } from './use-data-grid';

interface ICardGrid {
    (props: GridProps): JSX.Element;
    Header: typeof Header;
    Body: typeof Body;
    Footer: typeof Footer;
    Search: typeof Search;
    UtilBar: typeof UilityBar;
    SelectedActions: typeof SelectedActions;
    ExportToExcel: typeof ExportToExcel;
    ExportToPDF: typeof ExportToPDF;
    LayoutOptions: typeof LayoutOptions;
    RefreshData: typeof RefreshData;
    RemoveItem: typeof RemoveItem;
    Counters: typeof Counters;
    AlphaFilter: typeof AlphaFilter;
    useGrid: typeof useGrid;
}

const ClientCardGrid: ICardGrid = (props) => <CardGridComp {...props} />;
ClientCardGrid.Header = Header;
ClientCardGrid.Body = Body;
ClientCardGrid.Footer = Footer;
ClientCardGrid.Search = Search;
ClientCardGrid.UtilBar = UilityBar;
ClientCardGrid.SelectedActions = SelectedActions;
ClientCardGrid.LayoutOptions = LayoutOptions;
ClientCardGrid.ExportToExcel = ExportToExcel;
ClientCardGrid.ExportToPDF = ExportToPDF;
ClientCardGrid.RemoveItem = RemoveItem;
ClientCardGrid.RefreshData = RefreshData;
ClientCardGrid.Counters = Counters;
ClientCardGrid.AlphaFilter = AlphaFilter;
ClientCardGrid.useGrid = useGrid;

export default ClientCardGrid;