import { createStore } from "treble-gsm";

export interface IFilterStore {
  columnSortState: boolean | React.MutableRefObject<null>;
  isColumnSort: boolean;
  searchQueries: {
    target: string;
    query: string | { from?: string; to?: string } | null;
    exact: boolean;
    type: 'string' | 'number' | 'dateRange';
    filterType: 'alpha' | 'search';
    ptrui_cacheKey: string;
  }[];
  activeColumnSort: {
    field: string,
    type: 'desc' | 'asc' | 'none'
  },
  activeColumnFilters: {
    field: string,
    type: 'search' | 'select'
  }[],
  disableColumnFilters: boolean;
  filterDebounceTime: number;
}

const actionKeys = {
  ['changeColumnSortState']: 'changeColumnSortState',
  ['updateSearchQueries']: 'updateSearchQueries',
  ['setActiveColumnSort']: 'setActiveColumnSort',
  ['setIsColumnSort']: 'setIsColumnSort',
  ['updateActiveColumnFilters']: 'updateActiveColumnFilters',
  ['updateDisableColumnFilters']: 'updateDisableColumnFilters',
  ['setFilterDebounceTime']: 'setFilterDebounceTime'
}

export type TFilterActions = typeof actionKeys

const FilterStore = createStore([

  {
    action: actionKeys.changeColumnSortState,
    state: {
      columnSortState: false,
    },
  },
  {
    action: actionKeys.updateSearchQueries,
    state: {
      searchQueries: [],
    },
    features: {
      // process: (items: any) => {
      //   const processedState = items.map((item: any, index: number) => ({ ...item, ptrui_cacheKey: index }));
      //   console.log(processedState);
      //   return processedState
      // }
    }
  },
  {
    action: actionKeys.setActiveColumnSort,
    state: {
      activeColumnSort: {
        field: '',
        type: 'none'
      }
    }
  },
  {
    action: actionKeys.setIsColumnSort,
    state: {
      isColumnSort: false
    },
    features: {
      check: ({ dispatchValue }) => {
        if (dispatchValue === false) {
          return false;
        }
        return true;
      }
    }
  },
  {
    action: actionKeys.updateActiveColumnFilters,
    state: {
      activeColumnFilters: []
    }
  },
  {
    action: actionKeys.updateDisableColumnFilters,
    state: {
      disableColumnFilters: false
    }
  },
  {
    action: actionKeys.setFilterDebounceTime,
    state: {
      filterDebounceTime: 300
    }
  }
]);

export default FilterStore;
