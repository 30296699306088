import { useDebounce, useInput } from '../../../../hooks';
import useDataGrid from '../use-data-grid';

const useSearch = (fields: string[], onChange?: (value?: string) => void) => {
    const grid = useDataGrid();

    const { value: inputValue, bind: bindToInput, setValue: setInputValue } = useInput('');

    const updateQuery = useDebounce((value: string, searchQueries: { target: string, ptrui_cacheKey: string }[]) => {
        const queries = searchQueries.map((item) => {
            if (fields.includes(item.target) && item.ptrui_cacheKey.includes('search')) {
                return {
                    ...item,
                    target: item.target,
                    query: value.toLowerCase(),
                    exact: false,
                    type: "string"
                };
            }
            return item;
        });
        if (onChange) {
            onChange(value);
        }
        grid.updateSearchQueries(queries as any);
    }, grid.filterDebounceTime);

    const onReset = (value?: string) => {
        if (typeof value === 'string' && value.length === 0) {
            setInputValue('');
        }
    }

    return {
        updateQuery,
        onReset,
        setInputValue,
        bindToInput,
        inputValue
    }
}

export default useSearch;