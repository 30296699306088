import React from "react";
import { default as BasicGridComp } from "./basic-grid";
import Table from "./table";
import Column from "./column";
import Row from "./row";
import Cell from "./cell";
import Header from '../client-grid/header';
import Footer from './footer';
import ExportToExcel from "./export-to-excel";
import ExportToPDF from "./export-to-pdf";
import RefreshData from "./refresh-data";
import UtilityBar from "./utility-bar";
import { useGrid } from './use-data-grid';
import { GridProps } from './basic-grid';

interface IDataGridComp<P> {
    (props: P): JSX.Element;
    Table: typeof Table;
    Column: typeof Column;
    Row: typeof Row;
    Cell: typeof Cell;
    ExportToExcel: typeof ExportToExcel;
    ExportToPDF: typeof ExportToPDF;
    RefreshData: typeof RefreshData;
    Header: typeof Header;
    Footer: typeof Footer;
    UtilBar: typeof UtilityBar;
    useGrid: typeof useGrid;
}

//BasicGrid sub components are added as methods to main DataGrid comp to allow for dot syntax initialization
const BasicGrid: IDataGridComp<GridProps> = (props) => <BasicGridComp {...props} />;
BasicGrid.Table = Table;
BasicGrid.Column = Column;
BasicGrid.Row = Row;
BasicGrid.Cell = Cell;
BasicGrid.ExportToExcel = ExportToExcel;
BasicGrid.ExportToPDF = ExportToPDF;
BasicGrid.RefreshData = RefreshData;
BasicGrid.Header = Header;
BasicGrid.Footer = Footer;
BasicGrid.UtilBar = UtilityBar;
BasicGrid.useGrid = useGrid;

export default BasicGrid;
