import React from 'react';
interface Props {
    children?: string;
    i18n?: string;
}
export default function Translate({ children, i18n }: Props) {

    return (
        <>
            {
                (typeof i18n === 'string') ? i18n : children
            }
        </>
    )
}