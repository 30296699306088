import React from 'react';
import { useInAppMessage } from '../../../../hooks';
import { ExportBtnPDF } from "../../../molecules";
import useDataGrid from '../../data-grid/use-data-grid';

interface Props {
    className?: string;
    iconOnly?: boolean;
}
export default function ExportToPDF({ className, iconOnly }: Props) {

    const grid = useDataGrid();
    const message = useInAppMessage();
    const count = grid.tableData.length;
    const onExport = () => {
        const resolveExportMSG = () => message.createSuccess(`Exported ${count} ${(count === 1) ? 'row' : 'rows'}!`, { dismiss: 5000 });
        message.createLoading(`Exporting ${count} ${(count === 1) ? 'row' : 'rows'}...`);
        setTimeout(() => {
            resolveExportMSG();
        }, 2000);
    }

    return (
        <>
            <ExportBtnPDF
                className={className}
                small
                data={grid.tableData}
                fields={grid.columnFields}
                iconOnly={iconOnly}
                onExport={onExport}
            />
        </>
    )
}