import React from "react";
import { useDataGridStore } from "../../_Store";
import styles from './noResults.module.scss';
import { useI18N } from '../../../../../hooks';

interface Props {
  width: number
}

function NoResults({ width }: Props) {
  const i18n = useI18N();
  const [{ fallBackGridHeight }] = useDataGridStore();
  return (
    <>
      <tr>
        <td
          className={styles.noResults}
          style={{ height: fallBackGridHeight }}
        >
          <div
            className={`${(width > 0) ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center`}
            style={{ height: "100%", width: `${width + 'px'}` }}
          >
            <div className={styles.icons}>
              <i className="fad fa-search text-info"></i>
              <i className="fad fa-sad-tear text-info"></i>
            </div>
            <h5 className="pt-3 text-info">{i18n.translateThis('Sorry, No Results', i18n.localize.sorryNoResults)}...</h5>
          </div>
        </td>
      </tr>
    </>
  );
}

export default React.memo(NoResults);
