import React from 'react';
import { default as CardComp } from './card';
import Header from './header';
import Footer from './footer';
import Body from './body';
import Remove from './remove';
import { ICard } from './interfaces';

const Card: ICard.ICardContainer = (props) => (<CardComp {...props} />);
Card.Header = Header;
Card.Footer = Footer;
Card.Body = Body;
Card.Remove = Remove;

export default Card;