import React from 'react';
import { useInput, useDebounce, useNonInitialEffect } from '../../../hooks';
import { InputField } from '../../atoms';
import useAutoSuggest from './use-auto-suggest';
import { useAutoSuggestStore } from './Store';
import { useI18N } from '../../../hooks';

const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => {

    const [{ prependIcon, inputClassNames, inputSize, isDisabled, placeHolderText, searchQuery, toggleMeta, activeInputValue, defaultInputValue }] = useAutoSuggestStore();
    const { value, bind, reset : resetValue, setValue } = useInput('');

    const autoSuggest = useAutoSuggest();
    const i18n = useI18N();
    
    const searchQueries = useDebounce((searchValue: string) => {
        autoSuggest.setSearchQuery({
            ...searchQuery,
            query: searchValue
        });
    }, 250);

    const handleKeyDownEvent = (e: any) => {
        const keyCode = e.keyCode;
        if (keyCode === 46) {
            autoSuggest.resetActiveInputValue();
            autoSuggest.resetSearchQuery();
            resetValue();
        }

        else if (keyCode !== 38 && keyCode !== 40 ) {
            let newChar = "";
            if(keyCode >= 32 && (keyCode !== 37 && keyCode !== 39))
                newChar = e.key;
            else if(keyCode === 8){
                newChar = e.currentTarget.value.substr(0, e.currentTarget.selectionStart - 2) +  e.currentTarget.value.substr(0, e.currentTarget.selectionStart + 1);
            }
            
            searchQueries(value + newChar);

            if (toggleMeta.isOpen !== true) {
                onClick(e);
            }
        }
    }

    useNonInitialEffect(() => {
        setValue(activeInputValue.text);
        //makes sure menu closes when item is selected and enter is clicked
        if (toggleMeta.event !== null) {
            if (toggleMeta.metadata.source !== 'select') {
                onClick(toggleMeta.event);
            }
        }
    }, [activeInputValue])

    useNonInitialEffect(() => {
        autoSuggest.setActiveInputValue(defaultInputValue);
    }, [defaultInputValue.text]);

    return (
        <>
            <InputField
                {...bind}
                icon={(typeof prependIcon === 'boolean') ? (prependIcon) ? <i className="fas fa-search"></i> : undefined : prependIcon}
                placeHolder={ placeHolderText ? placeHolderText :  i18n.translateThis('Search...', i18n.localize.customToggle.search) }
                size={inputSize}
                disabled={isDisabled}
                compRef={ref}
                type={'text'}
                onKeyDown={(e) => {
                    e.persist(); handleKeyDownEvent(e)
                }}
            />
        </>
    )
});

export default CustomToggle


