import {
  createStore,
  useScopedTreble,
  useTreble,
  TUseTreble,
  IMiddlewareData
} from "treble-gsm";
import {
  FilterStore,
  TableDataStore,
  UIStore,
  IFilterStore,
  IUIStore,
  ITableDataStore,
  TUIActions,
  TTableDataActions,
  TFilterActions
} from "./extend-store";

//interface for state items (used to describe StoreItems returned by useTreble)
export interface IStoreItems extends IFilterStore, IUIStore, ITableDataStore {
  [key: string]: any;
}
export interface IStoreActions extends TUIActions, TTableDataActions, TFilterActions { };

export type TStore = TUseTreble<IStoreItems, IStoreActions>;

export interface IGridMiddlewareData extends IMiddlewareData<IStoreItems> { };


//create scoped store context
const dataGridContext = useScopedTreble();

//create custom treble hook
export const useGridProviderStore = () => useTreble(dataGridContext) as TStore;

//treble store
export const Store = createStore([], {
  context: dataGridContext,
  extendStore: [FilterStore, TableDataStore, UIStore],
});

