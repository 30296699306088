import jsPDF from 'jspdf';
import 'jspdf-autotable';

const exportPDF = async (data: any[], columnFields: { field: string, label?: string, title?: string }[], callback?: () => void) => {

    //process data so it can be converted into a pdf
    const flattenedRowData = data?.map((item) => {
        const arrayItem = columnFields?.map(({ field }) => {
            return item[field];
        });
        return arrayItem;
    });

    const flattendColumnFields = columnFields?.map(({ field, label, title }) => {
        if (label) {
            return label
        }
        if (title) {
            return title
        }
        return field;
    });

    const doc = new jsPDF('landscape');
    (doc as any).autoTable({
        headStyles: { fontSize: 7, wordBreak : "break-word" },
        bodyStyles: { fontSize: 7 },
        margin: 3,
        head: [flattendColumnFields],
        body: flattenedRowData,
    });

    doc.save(`grid-data.pdf`);
    if (typeof callback === 'function') {
        callback();
    }

};

export default exportPDF;