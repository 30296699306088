import React from 'react';
import { ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from '../../../utils';
import styles from './listItemCollapsed.module.scss';

interface Props {
    className?: string;
    count?: number;
    icon?: JSX.Element;
    text?: string | JSX.Element;
    onClick?: () => void;
    children?: JSX.Element | JSX.Element[];
    path?: string;
    as?: React.ElementType<any>;
    caret?: boolean;
    caretDir?: 'up' | 'down' | 'left' | 'right';
    toolTip?: boolean;
    toolTipPlacement?: 'top' | 'left' | 'right' | 'bottom';
    disabled?: boolean;
}

export default function ListItemCollapsed({ className, icon, text, children, path, onClick, as, caret, caretDir, toolTip, toolTipPlacement, count, disabled }: Props) {

    const isCounter = (typeof count === 'number') ? true : false;

    return (
        <>
            <div className={`${styles.compContainer} ${(disabled) ? '' : 'cursor'}`} onClick={() => { (onClick) ? onClick() : null }}>
                <OverlayTrigger
                    show={(toolTip === false) ? false : undefined}
                    key={(toolTipPlacement) ? toolTipPlacement : 'right'}
                    placement={`${(toolTipPlacement) ? toolTipPlacement : 'right'}` as any}
                    overlay={
                        <Tooltip id={`tooltip-${(toolTipPlacement) ? toolTipPlacement : 'right'}`}>
                            <p className='mb-0'>{text}</p>
                        </Tooltip>
                    }
                >
                    <ListGroup.Item as={(as) ? as : 'div'} className={`${className}`}>
                        <Link to={path}>
                            <>
                                {
                                    (icon) ?
                                        <span className={`d-flex`}>
                                            {icon}
                                            {
                                                (isCounter) ?
                                                    <div className={'pl-1'}>
                                                        <div>{count}</div>
                                                    </div> : null
                                            }
                                            {
                                                (caret) ?
                                                    <i className={`fas fa-caret-${(caretDir) ? caretDir : 'right'} pl-2 pt-1 ${styles.caretIcon}`} style={{ opacity: (disabled) ? .5 : 1 }}></i> : null
                                            }
                                        </span> : null
                                }
                                {children}
                            </>
                        </Link>
                    </ListGroup.Item>
                </OverlayTrigger>
            </div>
        </>
    )
}