/*
  RenderData
  - handles the different types of data rendering for the Grid.Table component
*/
import React from "react";
import Row from '../row';
import { GridErrorSplash, GridLoadingSplash, GridRenderData } from '../../../molecules';
import useDataGrid from "../use-data-grid";

export default function RenderData() {

  const grid = useDataGrid();

  return (
    <>
      {
        (grid.isLoading) ?
          <GridLoadingSplash
            width={grid.width}
            height={grid.splashHeight}
          />

          : (grid.fetchStatusCode !== 'No Errors Detected') ?
            <GridErrorSplash
              width={grid.width}
              height={grid.splashHeight}
              error={grid.fetchStatusCode}
            />

            : (grid.cachedTableData.length === 0) ?
              null

              : <>
                <GridRenderData
                  as={(grid.CustomRowComp) ? grid.CustomRowComp : Row}
                  activePage={0}
                  pageSize={grid.cachedTableData.length}
                  data={grid.tableData}
                  onScrollBottom={grid.onScrollBottom}
                  onScrollTop={grid.onScrollTop}
                />
              </>
      }
    </>
  );
}

