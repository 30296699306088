import React from 'react';
import { GridSplash } from '../../atoms';
import { useI18N } from '../../../hooks';

interface Props {
    width?: number;
    height?: number;
    text?: string;
    smallText?: string;
    error?: null | object | string;
    as?: 'div' | 'tr';
}
export default function GridErrorSplash({ width, height, text, error, as }: Props) {
    const i18n = useI18N();
    const Splash = () => (
        <GridSplash
            width={width}
            height={height}
            icon={<i className="fad fa-exclamation-triangle text-danger"></i>}
            text={`${(text) ? text : i18n.translateThis('Something Went Wrong!', i18n.localize.gridSplash.text)}`}
            textColor={'text-danger'}
            smallTextColor={'text-danger'}
            smallText={`${i18n.translateThis('Fetch Error:', i18n.localize.gridSplash.smallText)}: ${(error) ? error.toString() : ''}`}
        />
    )

    return (
        <>
            {
                (as === 'div') ?
                    <div style={{ height: height }}>
                        <Splash />
                    </div> :
                    (as === 'tr') ?
                        <tr>
                            <td className={'p-0 border-0'}>
                                <Splash />
                            </td>
                        </tr> :
                        <tr>
                            <td className={'p-0 border-0'}>
                                <Splash />
                            </td>
                        </tr>
            }
        </>
    )
}