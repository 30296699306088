import React from 'react';
import { GridSplash } from '../../atoms';
import { useI18N } from '../../../hooks';

interface Props {
    width?: number;
    height?: number;
    text?: string;
    smallText?: string;
    as?: 'div' | 'tr';
}
export default function GridNoResultsSplash({ width, height, text, smallText, as }: Props) {
    const i18n = useI18N();
    const Splash = () => (
        <GridSplash
            width={width}
            height={height}
            icon={
                <>
                    <i className="fad fa-search text-info"></i>
                    <i className="fad fa-sad-tear text-info"></i>
                </>
            }
            text={`${(text) ? text : i18n.translateThis('Sorry, No Results', i18n.localize.sorryNoResults) + '...'}`}
            textColor={'text-info'}
            smallText={smallText}
        />
    );

    return (
        <>
            {
                (as === 'div') ?
                    <div style={{ height: height }}>
                        <Splash />
                    </div> :
                    (as === 'tr') ?
                        <tr>
                            <td className={'p-0 border-0'}>
                                <Splash />
                            </td>
                        </tr> :
                        <tr>
                            <td className={'p-0 border-0'}>
                                <Splash />
                            </td>
                        </tr>
            }
        </>
    )
}