import React from 'react';
import { useNonInitialEffect } from '../../../../hooks';
import { AlphaFilter as AlphaFilterMol } from '../../../molecules';
import useDataGrid from '../use-data-grid';
import useAlphaFilter from './use-alpha-filter';

interface Props {
    className?: string;
    targetField: string;
    onChange?: (value?: string) => void;
    disabled?: boolean;
}
export default function AlphaFilter({ className, targetField, onChange, disabled }: Props) {

    const [activeChar, setActiveChar] = React.useState('all');
    const { deleteProcess, ...grid } = useDataGrid();
    const filter = useAlphaFilter(targetField, onChange);
    const isDisabled = (disabled) ? true : (grid.isLoading) ? true : (deleteProcess.isDeleting) ? true : false;
    grid.searchQueries

    //listens for value change and then updates search query
    useNonInitialEffect(() => {
        filter.updateQuery((activeChar === 'all') ? '' : activeChar, grid.searchQueries as any);
    }, [activeChar]);

    //adds default search query objects based on specified targets
    React.useEffect(() => {
        grid.addSearchQuery({
            target: targetField,
            query: '',
            exact: false,
            type: "string",
            ptrui_cacheKey: `alpha_0`,
            filterType: 'alpha'
        })
    }, []);

    return (
        <>
            <AlphaFilterMol
                className={className}
                activeChar={activeChar}
                onClick={setActiveChar}
                disabled={isDisabled}
            />
        </>
    )
}