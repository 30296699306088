import React from 'react';
import { Button } from 'react-bootstrap';
import { ListItem, ListItemDivider, ListItemHeader } from '../../atoms';
import BtnWithDropdown from '../btn-with-dropdown';
import IconWithDropdown from '../icon-with-dropdown';
import { useDataExports } from '../../../hooks';
import styles from './exportBtnExcel.module.scss';
import { useI18N } from '../../../hooks';

interface Props {
    iconOnly?: boolean;
    small?: boolean;
    large?: boolean;
    className?: string;
    label?: string;
    data: any[];
    fields: {
        field: string;
        label?: string;
        [key: string]: any;
    }[];
    options?: {
        label: string,
        onClick: () => void;
    }[];
    onExport?: () => void;
    disabled?: boolean;
}
export default function ExportBtnExcel({ iconOnly, small, large, label, data, fields, options, className, onExport: onExportFN, disabled }: Props) {
    const i18n = useI18N();
    const { exportToExcel } = useDataExports();
    const size = (small) ? 'sm' : (large) ? 'lg' : undefined;
    const btnLabel = (label) ? label : i18n.translateThis('Export Excel', i18n.localize.exportBtnExcel.exportExcel);
    const onExport = () => {
        if (onExportFN) {
            onExportFN();
        }
        setTimeout(() => {
            exportToExcel(data, fields);
        }, 2000);
    }

    return (
        <>
            {
                (iconOnly) ?
                    (options) ?
                        <IconWithDropdown
                            icon={<i className={`fas fa-file-excel text-success ${styles.iconOnly}`} style={{ opacity: (disabled) ? .50 : 1 }}></i>}
                            className={className}
                            classNameToggle={'pt-0 pr-2'}
                            caret
                            drop={'down'}
                            text={btnLabel}
                            toolTip={false}
                            disabled={disabled}
                        >
                            <>
                                <ListItemHeader text={btnLabel} />
                                <ListItemDivider />
                                {
                                    options.map(({ label, onClick }, index) => (
                                        <ListItem
                                            key={index}
                                            text={label}
                                            onClick={onClick}
                                        />
                                    ))
                                }
                            </>
                        </IconWithDropdown> :
                        <i
                            className={`fas fa-file-excel text-success cursor ${styles.iconOnly} ${className}`}
                            onClick={onExport}
                        ></i> :
                    (options) ?
                        <BtnWithDropdown
                            small={(size === 'sm') ? true : false}
                            large={(size === 'lg') ? true : false}
                            variant={'success'}
                            label={btnLabel}
                            icon={<i className="fas fa-file-excel text-white"></i>}
                            className={className}
                            caret
                            caretDir={'down'}
                            disabled={disabled}
                        >
                            <>
                                <ListItemHeader text={btnLabel} />
                                <ListItemDivider />
                                {
                                    options.map(({ label, onClick }, index) => (
                                        <ListItem
                                            key={index}
                                            text={label}
                                            onClick={onClick}
                                        />
                                    ))
                                }
                            </>
                        </BtnWithDropdown> :
                        <Button
                            variant={'success'}
                            size={size}
                            className={className}
                            onClick={onExport}
                            disabled={disabled}
                        >
                            <i className="fas fa-file-excel text-white pr-2"></i>
                            {btnLabel}
                        </Button>
            }
        </>
    )
}