import React from 'react';
import useDataGrid from '../use-data-grid';

const useCard = () => {

    const grid = useDataGrid();

    //alows selecting of Rows and saves row data to state
    const onSelect = (data: { [key: string]: any }, isActive: boolean, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        //allows for multi select on ctrl(windows) or cmd(macOS)
        if (e.metaKey || e.ctrlKey) {
            if (!isActive) {
                grid.addSelectedRow(data);
            }
            else if (isActive) {
                grid.removeSelectedRow(data);
            }
        }
        //allows for batch select when shift key is pressed
        else if (e.shiftKey) {
            //setDisableTextHighlight(true);
            const start = grid.selectedRows[grid.selectedRows.length - 1]?.ptrui_sortKey;
            const end = data?.ptrui_sortKey;
            if (typeof start === 'number' && typeof end === 'number') {
                const range = grid.tableData.filter((row) => {
                    if (row.ptrui_sortKey >= start && row.ptrui_sortKey <= end) {
                        return row;
                    }
                });
                grid.updateSelectedRows(range);
            }
        }
        //default is single select row
        else {
            if (!isActive) {
                grid.selectRow(data);
            }
            else if (isActive) {
                //need to check business logic for this.
                //grid.unSelectAllRows();
            }
        }
    }

    return {
        onSelect
    }

}

export default useCard;