/*
    Spanish Locales
*/
import ILocalize from './interface';

const es: ILocalize = {
    header: {
        userMenu: {
            logoutLabel: 'Cerrar sesión',
            logoutModal: {
                message: '¿Estás seguro de que quieres cerrar sesión?',
                buttons: {
                    cancel: 'No, gracias',
                    confirm: 'Cerrar sesión'
                }
            },
            pleaseSignIn : "Por favor Inicie Sesion",
        },
        languageSwitcher: 'Elija un idioma'
    },
    footer: {
        copyright: 'Copyright 2021 ProTrans International In. All rights reserved.'
    },
    mainMenu: {
        title: 'Menú principal',
        home: 'Casa'
    },
    subMenuBar: {
        search: {
            placeHolder: 'Buscar'
        }
    },
    timeSelector : {
        chooseATimeLabel : 'Establece una hora'
    },
    dateSelector : {
        chooseADateLabel : 'Establece una fecha',
        chooseDateRangeLabel : 'Establece un rango'
    },
    dayPicker : {
        reset : "Reiniciar",
        today : "Hoy"
    },
    customToggle : {
        search : 'Buscar...'
    },
    optionSelector : {
        chooseAnOption : "Elige una opcion"
    },
    btnRemove : {
        removeItem : 'Eliminar Elemento'
    },
    InputError : {
        fetchError : 'Fetch Error'
    },
    RefetchDataBtn : {
        refetchData : 'Refetch Data',
        fetching : '...Fetching'
    },
    AddItemToGrid : {
        addItem : 'Agregar Elemento'
    },
    columnSelector : {
        selectColumns : 'Seleccione Columnas',
        selectAll : 'Seleccionar Todos',
        columns : 'Columnas'
    },
    createGridItem : {
        createItem : 'Create Item',
        noForm : 'No Form has been added yet.'
    },
    exportBtnExcel : {
        exportExcel : 'Exportar a Excel'
    },
    exportBtnPDF : {
        exportPdf : 'Exportar a PDF'
    },
    fileUpload : {
        upload : "Subir"
    },
    gridSplash : {
        text : "Something Went Wrong!",
        smallText : "Fetch Error:"
    },
    input : {
        search : 'Buscar'
    },
    languageSwitcher : {
        headerText : 'Elegir un idioma',
        settingLanguage : 'Estableciendo idioma a español'
    },
    refreshData: {
        fetching : 'Fetching...',
        refreshData : 'Recargar Data',
        refreshError : 'Recargar Error'
    },
    removeGridItems : {
        deleteQuestion : "Desea eliminar",
        cancel : "Cancelar"
    },
    resetFilters : {
        resetFilters : 'Resetear Filtros'
    },
    selectedActions : {
        showSelectedOnly : 'Solo Mostrar Seleccionados',
        selectAll : 'Seleccionar Todo',
        clearAll : 'Limpiar Seleccion',
        selected : 'Seleccionado',
    },
    alertMenu : {
        alerts : 'ALERTAS'
    },
    exportTo : {
        exporting : 'Exportando',
        exported : 'Exportado',
        item : 'item',
        items : 'items',
    },
    confirmDialog : {
        areYouSure : "Esta seguro",
        cancel : "Cancelar",
        confirm : "Confirmar"
    },
    languageModal : {
        title : 'Change Language'
    },
    checkAuth: {
        authenticating : 'Autenticando'
    },
    loginForm: {
        signingIn : 'Signing In'
    },
    cardGrid : {
        search : "Buscar"
    },
    clientCardGrid : {
        search : "Buscar"
    },
    clientGrid : {
        search : "Buscar"
    },
    dataCardGrid : {
        search : "Buscar"
    },
    dataGrid : {
        search : "Buscar"
    },
    loading : "Cargando...",
    fetching : "Fetching...",
    sorryNoResults : "Disculpe, No Hay Resultados",
    all : "Todos",
    filtered : "Filtrado",
    selected : "Seleccionado",
    current: "Actual"
}

export default es;