import React from 'react';
import { useDataExports, useI18N, useInAppMessage, useResponsive } from '../../../../hooks';
import { ExportBtnExcel } from "../../../molecules";
import useDataGrid from '../../data-grid/use-data-grid';

interface Props {
    className?: string;
    iconOnly?: boolean;
    disabled?: boolean;
    enableMobile?: boolean;
}
export default function ExportToExcel({ className, iconOnly, disabled, enableMobile }: Props) {
    const i18n = useI18N();
    const { localeKey } = i18n;
    const { deleteProcess, ...grid } = useDataGrid();
    const exports = useDataExports();
    const message = useInAppMessage();
    const res = useResponsive();
    const isDisabled = (disabled) ? true : (grid.isLoading) ? true : (deleteProcess.isDeleting) ? true : false;
    const isIconOnly = (iconOnly) ? true : (!res.lgDown && enableMobile) ? true : false;
    const onExport = (count: number, data: any[]) => {
        const resolveExportMSG = () => message.createSuccess(`Exported ${count} ${(count === 1) ? 'item' : 'items'}!`, { dismiss: 5000 });
        message.createLoading(`Exporting ${count} ${(count === 1) ? 'item' : 'items'}...`);
        setTimeout(() => {
            exports.exportToExcel(data, grid.fields, resolveExportMSG);
        }, 2000);
    }
    const selectedOption = (grid.isOnRowSelect) ? [{
        label: `(${grid.selectedRows.length || 0}) ${i18n.translateThis('Selected', i18n.localize.selected)}`,
        onClick: () => onExport(grid.selectedRows.length || 0, grid.selectedRows)
    }] : []

    const customOptions = (typeof grid.exports.customOptions === 'object') ?
        grid.exports.customOptions.filter(
            (item) => {
                if (item.type === 'excel') {
                    return item;
                }
            }) : [];

    const options = [
        {
            label: `(${grid.cachedTableData.length || 0}) ${i18n.translateThis('All', i18n.localize.all)}`,
            onClick: () => onExport((typeof grid.cachedTableData.length === 'number') ? grid.cachedTableData.length : 0, grid.cachedTableData)
        },
        {
            label: `(${grid.tableData.length || 0}) ${i18n.translateThis('Filtered', i18n.localize.filtered)}`,
            onClick: () => onExport((typeof grid.tableData.length === 'number') ? grid.tableData.length : 0, grid.tableData)
        },
        ...selectedOption,
        ...customOptions
    ]

    return (
        <>
            <ExportBtnExcel
                className={className}
                small
                data={grid.tableData}
                fields={grid.columnFields}
                options={options as any}
                iconOnly={isIconOnly}
                disabled={isDisabled}
            />
        </>
    )
}