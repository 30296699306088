import { GridProvider } from '../../molecules';

//hook used internally to update Grid Store
const useDataGrid = () => GridProvider.useStore();

//hook that can be used externally by downstream devs
const useGrid = () => {
    const {
        setLoading,
        setFetchStatus,
        updateTableData,
        updateCachedTableData,
        resetGridPosition,
        prependRow,
        appendRow,
        selectedRows,
        activePage,
        cachedTableData,
        tableData,
        isLoading,
        searchQueries: filterQueries,
        totalDataCount,
        resetSearchQueries: resetColumnFilters,
        onRefresh: refetchData,
        isRowSelected,
        customItemProps: customRowProps,
        updateDataCount: setTotalDataCount,
        updateByPtruiKey,
        removeByPtruiKey,
        updateByPredicate,
        removeByPredicate,
    } = useDataGrid();


    return {
        setLoading,
        setFetchStatus,
        updateTableData,
        updateCachedTableData,
        resetGridPosition,
        resetColumnFilters,
        prependRow,
        appendRow,
        isRowSelected,
        setTotalDataCount,
        selectedRows,
        activePage,
        cachedTableData,
        tableData,
        isLoading,
        searchQueries: filterQueries,
        totalDataCount,
        refetchData,
        customRowProps,
        updateByPtruiKey,
        removeByPtruiKey,
        updateByPredicate,
        removeByPredicate,
    }
}

export { useGrid };
export default useDataGrid;