import globals from '../global';
import { useAppStore } from '../_Store';

const useResponsive = () => {

    const [{ ptrui_appResize }, Store, Utils] = useAppStore();

    const width = ptrui_appResize.width;

    const height = ptrui_appResize.height;

    const breakpoints = {
        sm: globals.breakpoints.sm,
        md: globals.breakpoints.md,
        lg: globals.breakpoints.lg,
        xl: globals.breakpoints.xl
    }

    //breaks points are based on breakpoint down
    const smDown = (width >= breakpoints.sm) ? true : false;

    const mdDown = (width >= breakpoints.md) ? true : false;

    const lgDown = (width >= breakpoints.lg) ? true : false;

    const xlDown = (width >= breakpoints.xl) ? true : false;;

    const setRes = (dimensions: { width: number, height: number }) => Store.update(Utils.actions.ptrui_setAppResize, dimensions);


    return (
        {
            smDown,
            mdDown,
            lgDown,
            xlDown,
            width,
            height,
            breakpoints,
            setRes
        }
    )
}

export default useResponsive;