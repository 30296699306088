/*
    Protrans React Component Store
    - Allows certain UI components to use global app state for functionality
    - example: CardGrid is able to persist check state
*/

import { createStore } from "treble-gsm";

const actionKeys = {
    ['ptrui_updateCardGridCheckList']: 'ptrui_updateCardGridCheckList',
    ['ptrui_updateSelectedGridItems']: 'ptrui_updateSelectedGridItems'
}
export interface IStoreComps {
    ptrui_CardGridCheckList: {//this is a legacy store item (will need to be removed)
        [key: string]: number[] | string[];
    };
    ptrui_selectedGridItems: {
        [key: string]: {
            ptrui_cacheKey: number;
            [key: string]: any;
        }[]
    }
}

export type TStoreCompActions = typeof actionKeys;

const StoreComps = createStore([
    {
        action: actionKeys.ptrui_updateCardGridCheckList,
        state: {
            ptrui_CardGridCheckList: {},
        },
    },
    {
        action: actionKeys.ptrui_updateSelectedGridItems,
        state: {
            ptrui_selectedGridItems: {},
        },
    }
]);

export default StoreComps;
