import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './panelHeader.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    className?: string;
    bgNone?: boolean;
}

export default function PanelHeader({ children, className, bgNone }: Props) {

    return (
        <>
            <Card.Header className={`${styles.header} ${(bgNone) ? 'bg-transparent' : 'bg-white'} ${className} d-flex justify-content-between align-items-center`} >
                {children}
            </Card.Header>
        </>
    )
}