import React from 'react';
import { useInAppMessage, useNonInitialLayoutEffect, useResponsive } from '../../../../hooks';
import RemoveGridItem from '../../../molecules/remove-grid-item';
import useDataGrid from '../../client-grid/use-data-grid';

interface Props {
    className?: string;
    disabled?: boolean;
    iconOnly?: boolean;
    onRemove?: (
        selectedRows: {
            ptrui_cacheKey: number,
            [key: string]: any
        }[]) => boolean;
    enableMobile?: boolean;
}
export default function RemoveItem({ className, disabled, onRemove, iconOnly, enableMobile }: Props) {

    const { deleteProcess, ...grid } = useDataGrid();
    const message = useInAppMessage();
    const res = useResponsive();
    const isDisabled = (disabled) ? true : (grid.isLoading) ? true : (deleteProcess.isDeleting) ? true : false;
    const isIconOnly = (iconOnly) ? true : (!res.lgDown && enableMobile) ? true : false;
    const items = (grid.selectedRows) ? grid.selectedRows : [];

    //show success message
    useNonInitialLayoutEffect(() => {
        if (deleteProcess.success) {
            setTimeout(() => {
                message.createSuccess(
                    <>
                        {items.length} {(items?.length > 1) ? 'items' : 'item'} deleted!
                    </>
                );
            }, 0);
        }
    }, [deleteProcess.success]);

    //shows fail message
    useNonInitialLayoutEffect(() => {
        if (deleteProcess.failed) {
            setTimeout(() => {
                message.createError(
                    <>
                        {items?.length} {(items?.length > 1) ? 'items' : 'item'} failed to delete!
                    </>
                );
            }, 0);
        }
    }, [deleteProcess.failed]);

    return (
        <>
            <RemoveGridItem
                small
                iconOnly={isIconOnly}
                label={'Delete'}
                deleteBtnLabel={'Delete'}
                modalSize={'md'}
                itemsToRemove={items}
                onRemove={() => deleteProcess.run(items, onRemove)}
                className={className}
                disabled={isDisabled}
                modalText={
                    <>
                        Are you sure you want to <strong>delete {items?.length} {(items?.length > 1) ? 'items' : 'item'}</strong> ?
                    </>
                }
            />
        </>
    )
}
