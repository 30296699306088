import React from 'react';
import { LoadingIndicator } from '../../../atoms';
import { useLogin, useI18N } from '../../../../hooks';

interface Props {
    children?: JSX.Element | JSX.Element[];
    loading?: boolean;
}

export default function LoginForm({ children }: Props) {
    const i18n = useI18N();
    const login = useLogin();

    return (
        <>
            {
                (login.loading) ?
                    <div className='d-flex justify-content-center pt-4'>
                        <div className={'d-flex flex-column align-items-center'}>
                            <LoadingIndicator size={4} />
                            <p className='text-center pt-3'><small>{i18n.translateThis('Signing In', i18n.localize.loginForm.signingIn)}...</small></p>
                        </div>
                    </div> : children

            }
        </>
    )
}