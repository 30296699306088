/*
  DataGrid
*/
import React from "react";
import { Content } from '..';
import { GridProvider } from '../../molecules';

export interface GridProps {
  children: JSX.Element | JSX.Element[];
  height?: string;
  className?: string;
  loading?: boolean;
  data: any[];
  as?: JSX.Element | object;
  exportPDF?: boolean;
  exportExcel?: boolean;
  fetchStatus?: any;
  totalDataCount?: number;
  columnTitles?: boolean;
  gridStyles?: React.CSSProperties;
  stickyColumns?: boolean;
  onRefresh?: () => void;
  isNested?: boolean;
  noPadding?: boolean;
  noOverflow?: boolean;
  rowProps?: any;
}

export default function BasicGrid({
  children,
  height,
  className,
  loading,
  data,
  as,
  exportPDF,
  exportExcel,
  fetchStatus,
  totalDataCount,
  columnTitles,
  gridStyles,
  stickyColumns,
  onRefresh,
  isNested,
  noPadding,
  noOverflow,
  rowProps
}: GridProps) {

  return (
    <>
      <Content.Panel bodyClassName={'p-0'} noPadding={noPadding}>
        <GridProvider
          isNested={isNested}
          height={height}
          className={className}
          loading={loading}
          data={data}
          as={as}
          exportPDF={exportPDF}
          exportExcel={exportExcel}
          fetchStatus={fetchStatus}
          totalDataCount={totalDataCount}
          columnTitles={columnTitles}
          gridStyles={gridStyles}
          stickyColumns={stickyColumns}
          onRefresh={onRefresh}
          noOverflow={noOverflow}
          customItemProps={rowProps}
        >
          <>
            {children}
          </>
        </GridProvider>
      </Content.Panel>
    </>
  );
}

