import React from 'react';
import RemoveGridItem from '../../remove-grid-item';

interface Props {
    iconOnly?: boolean;
    disabled?: boolean;
    className?: string;
    label?: string;
    itemToRemove?: { ptrui_cacheKey: number, [key: string]: any };
    onRemove?: (item?: { ptrui_cacheKey: number, [key: string]: any }[]) => boolean;
    modalText?: string | JSX.Element;
    onClick?: () => void;
}
function Remove({ label, iconOnly, disabled, className, itemToRemove, onRemove, modalText, onClick }: Props) {

    const removeThisItem = (itemToRemove) ? [itemToRemove] : [];

    return (
        <>
            <RemoveGridItem
                modalText={modalText}
                onClick={onClick}
                onRemove={onRemove}
                iconOnly={iconOnly}
                disabled={disabled}
                className={className}
                itemsToRemove={removeThisItem}
                hideCounter
                label={(label) ? label : 'Delete'}
                modalSize={'md'}
                small
            />
        </>
    )
}

Remove.displayName = 'CardRemove';
export default Remove;