import React from 'react';
import { useInAppMessage } from '../../../../hooks';
import RemoveGridItem from '../../../molecules/remove-grid-item';
import useDataGrid from '../../client-grid/use-data-grid';

interface Props {
    className?: string;
    disabled?: boolean;
    onRemove?: (
        selectedRows: {
            ptrui_cacheKey: number,
            [key: string]: any
        }[]) => boolean;
}
export default function RemoveRow({ className, disabled, onRemove: onRemoveFN }: Props) {

    const grid = useDataGrid();
    const message = useInAppMessage();
    const isDisabled = (disabled) ? true : (grid.isLoading) ? true : false;
    const [removeFailed, setRemoveFailed] = React.useState(false);
    const onRemove = (itemsToBeRemoved: { ptrui_cacheKey: number, [key: string]: any }[]) => {
        if (onRemoveFN) {
            if (onRemoveFN(itemsToBeRemoved)) {
                setRemoveFailed(false);
                setTimeout(() => message.createSuccess(<>{grid.selectedRows?.length} {(grid.selectedRows?.length > 1) ? 'rows' : 'row'} deleted!</>), 0);
                setTimeout(() => {
                    grid.removeCachedTableData(itemsToBeRemoved);
                    if (typeof grid.totalDataCount === 'number') {
                        grid.updateDataCount((grid.totalDataCount - grid.selectedRows.length));
                    }
                    grid.unSelectAllRows();
                    grid.resetActiveColumnSort();
                    grid.resetGridPosition();
                    grid.resetSearchQueries();
                }, 0);

                return true;
            }
            else {
                setRemoveFailed(true);

                return false;
            }
        }
        else {
            return false
        }
    }

    return (
        <>
            <RemoveGridItem
                small
                label={'Delete'}
                deleteBtnLabel={(removeFailed) ? 'Try Agian' : 'Delete'}
                modalSize={'md'}
                itemsToRemove={grid.selectedRows || []}
                onRemove={onRemove}
                className={className}
                disabled={isDisabled}
                modalText={
                    <>
                        {(removeFailed) ? <><strong className={'text-danger'}>Sorry, delete failed....</strong><br /></> : null}
                        Are you sure you want to <strong>delete {grid.selectedRows?.length} {(grid.selectedRows?.length > 1) ? 'rows' : 'row'}</strong> ?
                    </>
                }
            />
        </>
    )
}