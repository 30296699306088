/*
    Header
    - holds grid title or custom header actions
*/
import React from "react";
import { Col } from 'react-bootstrap';
import { Content } from '../..';

interface Props {
  title?: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
}
function Header({ title, children, className }: Props) {
  return (
    <>
      <Content.PanelHeader className={className}>
        <>
          {
            (title) ?
              <Col><h5 className="m-0">{title}</h5></Col> : null
          }
          {children}
        </>
      </Content.PanelHeader>
    </>
  );
}

export default React.memo(Header);
