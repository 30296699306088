import React from 'react';
import { useAppStore } from '../_Store';

const useComp = () => {

    const [{ ptrui_selectedGridItems: selectedGridItems }, Store, Util] = useAppStore();

    const grid = {
        selectedItems: selectedGridItems,
        getSelectedById: (gridId?: string) => {
            if (gridId) {
                const selected = (selectedGridItems[gridId]) ? selectedGridItems[gridId] : [];
                return selected;
            }
            return [];
        },
        updateSelectedItems: (selectedItems: string | number[], gridId: string) => {
            if (selectedItems && gridId) {
                Store.update(Util.actions.ptrui_updateSelectedGridItems, {
                    ...selectedGridItems,
                    [gridId]: selectedItems
                })
            }
        }
    }

    return {
        grid
    }
}

export default useComp;