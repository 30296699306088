/*
 Footer
 - provides pagination and row counts
*/
import React from "react";
import Content from '../../content';
import Counters from '../counters';
import useDataGrid from '../use-data-grid';

interface Props {
  children?: JSX.Element | JSX.Element[];
  hideCounters?: boolean;
  className?: string;
}
export default function Footer({ children, hideCounters, className }: Props) {

  const grid = useDataGrid();

  return (
    <>
      <Content.PanelFooter className={`${className}`} bgNone={grid.isNestedComp}>
        <>
          <p className="mb-0"></p>
          {
            (children) ?
              <div className="flex-fill px-3">
                {children}
              </div> : null
          }
          <div className="d-flex align-items-center">
            {
              (hideCounters) ? null : <Counters />
            }

          </div>
        </>
      </Content.PanelFooter>
    </>
  );
}
