import React from "react";
import { default as DataGridComp } from "./data-grid";
import Header from "./header";
import Table from "./table";
import Column from "./column";
import Footer from "./footer";
import Row from "./row";
import Cell from "./cell";
import ColumnSelector from './column-selector';
import ExportToPDF from "./export-to-pdf";
import ExportToExcel from "./export-to-excel";
import SelectedActions from "./selected-actions";
import ResetFilters from './reset-filters';
import RefreshData from './refresh-data';
import AddRow from './add-row';
import CreateRow from './create-row';
import RemoveRow from './remove-row';
import UtilBar from './utility-bar';
import { useGrid } from './use-data-grid';
import { GridProps } from './data-grid';

interface IDataGridComp<P> {
    (props: P): JSX.Element;
    Header: typeof Header;
    Table: typeof Table;
    Column: typeof Column;
    ColumnSelector: typeof ColumnSelector;
    ExportToExcel: typeof ExportToExcel;
    ExportToPDF: typeof ExportToPDF;
    SelectedActions: typeof SelectedActions;
    ResetFilters: typeof ResetFilters;
    RefreshData: typeof RefreshData;
    AddRow: typeof AddRow;
    CreateRow: typeof CreateRow;
    RemoveRow: typeof RemoveRow;
    Row: typeof Row;
    UtilBar: typeof UtilBar;
    Cell: typeof Cell;
    Footer: typeof Footer;
    useGrid: typeof useGrid;
}

//ClientGrid sub components are added as methods to main ClientGrid comp to allow for dot syntax initialization
const DataGrid: IDataGridComp<GridProps> = (props) => <DataGridComp {...props} />;
DataGrid.Header = Header;
DataGrid.Table = Table;
DataGrid.Column = Column;
DataGrid.ColumnSelector = ColumnSelector;
DataGrid.ExportToPDF = ExportToPDF;
DataGrid.ExportToExcel = ExportToExcel;
DataGrid.ResetFilters = ResetFilters;
DataGrid.SelectedActions = SelectedActions;
DataGrid.RefreshData = RefreshData;
DataGrid.AddRow = AddRow;
DataGrid.RemoveRow = RemoveRow;
DataGrid.CreateRow = CreateRow;
DataGrid.Row = Row;
DataGrid.Cell = Cell;
DataGrid.Footer = Footer;
DataGrid.UtilBar = UtilBar;
DataGrid.useGrid = useGrid;

export default DataGrid;
