import { GridProvider } from '../../molecules';

//hook used internally to update Grid Store
const useDataGrid = () => GridProvider.useStore();

//hook that can be used externally by downstream devs
const useGrid = () => {
    const {
        setLoading,
        setFetchStatus,
        updateCachedTableData: updateTableData,
        resetGridPosition,
        appendRow,
        prependRow,
        selectedRows,
        activePage,
        cachedTableData: tableData,
        isLoading,
        searchQueries: filterQueries,
        totalDataCount,
        resetSearchQueries: resetColumnFilters,
        onRefresh: refetchData,
        updateDataCount: setTotalDataCount,
        customItemProps: customRowProps,
        updateByPtruiKey,
        removeByPtruiKey,
        updateByPredicate,
        removeByPredicate,
    } = useDataGrid();


    return {
        setLoading,
        setFetchStatus,
        updateTableData,
        resetGridPosition,
        resetColumnFilters,
        appendRow,
        prependRow,
        refetchData,
        setTotalDataCount,
        selectedRows,
        activePage,
        cachedTableData: tableData,
        isLoading,
        filterQueries,
        totalDataCount,
        customRowProps,
        updateByPtruiKey,
        removeByPtruiKey,
        updateByPredicate,
        removeByPredicate,
    }
}

export { useGrid };
export default useDataGrid;