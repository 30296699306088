import React from 'react';
import { useAppStore } from '../../../../_Store';
import { Translate } from '../../../../utils';
import { useI18N } from '../../../../hooks';
import styles from './mainMenuBtn.module.scss';

export default function MainMenuBtn() {

    const [{ ptrui_collapsedPage, ptrui_collapsedPageWidth, ptrui_isAppMobile }, Store, Util] = useAppStore();
    const i18n = useI18N();

    return (
        <>
            <div
                className={`${styles.btnContainer} d-none d-lg-flex align-items-center cursor`}
                style={(ptrui_collapsedPage || ptrui_isAppMobile) ? { overflow: 'hidden' } : { width: ptrui_collapsedPageWidth }}
                onClick={() => {
                    Store.update(Util.actions.ptrui_updateMainMenuState, true);
                    Store.update(Util.actions.ptrui_updateAlertMenuState, false);
                }}
            >
                <i className={`${styles.icon} fas fa-bars d-flex justify-content-center text-white`}></i>
                {
                    (ptrui_collapsedPage || ptrui_isAppMobile) ? null :
                        <p className="text-left mb-0 pl-2" style={{ minWidth: '150px' }}><Translate i18n={i18n.localize.mainMenu.title}>Main Menu</Translate></p>
                }
            </div>
            {/* Mobile */}
            <div
                className={`${styles.btnContainer} d-flex d-lg-none align-items-center cursor`}
                style={{ overflow: 'hidden' }}
                onClick={() => {
                    Store.update(Util.actions.ptrui_updateMainMenuState, true);
                    Store.update(Util.actions.ptrui_updateAlertMenuState, false);
                }}
            >
                <i className={`${styles.icon} fas fa-bars d-flex justify-content-center text-white`}></i>

            </div>
        </>
    )
}