import React from 'react';
import { AlphaChar } from '../../atoms';

interface Props {
    activeChar?: string;
    onClick?: (char: string) => void;
    className?: string;
    disabled?: boolean;
}
export default function AlphaFilter({ activeChar, onClick, className, disabled }: Props) {

    const alphabet = [...Array.from('abcdefghijklmnopqrstuvwxyz'), 'all'];

    return (
        <>
            <div className={`d-flex pb-3 ${className}`} style={{ opacity: (disabled) ? .50 : 1 }}>
                {
                    alphabet.map((char) => {
                        return (
                            <React.Fragment key={char}>
                                <AlphaChar
                                    char={char}
                                    active={(activeChar === char) ? true : false}
                                    onClick={(onClick && !disabled) ? onClick : () => null}
                                />
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </>
    )
}