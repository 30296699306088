import React from 'react';
import GridProvider from '../../molecules/grid-provider';
import Content from '../../organisms/content';
import WatchLayout from './watch-layout';

export interface GridProps {
    id?: string;
    children?: JSX.Element | JSX.Element[];
    classNameCardContainer?: string;
    height?: string;
    loading?: boolean;
    data: any[];
    as: JSX.Element | object;
    pageSize?: number;
    disablePaging?: boolean;
    exportPDF?: boolean;
    exportExcel?: boolean;
    fetchStatus?: any;
    totalDataCount?: number;
    customExportOptions?: {
        type: 'excel' | 'pdf',
        label: string,
        onClick: () => void
    }[];
    debounceFilters?: number;
    onSelect?: (selectedRows: any[]) => void;
    onRefresh?: () => void;
    rowProps?: {
        [key: string]: any;
    }
    layoutType?: 1 | 2 | 3;
    exportFields?: {
        field: string;
        label?: string;
    }[];
    isNested?: boolean;
    persistSelected?: boolean;
}

function CardGridComp({ children, height, loading, data, as, pageSize, disablePaging, exportExcel, exportPDF, fetchStatus, totalDataCount, customExportOptions, debounceFilters, onSelect, onRefresh, rowProps, layoutType, exportFields, isNested, id, persistSelected }: GridProps) {

    return (
        <>
            <Content.Panel bodyClassName={`p-0`} bgNone={isNested}>
                <GridProvider
                    id={id}
                    data={data}
                    height={height}
                    loading={loading}
                    as={as}
                    pageSize={pageSize}
                    exportExcel={exportExcel}
                    exportPDF={exportPDF}
                    fetchStatus={fetchStatus}
                    totalDataCount={totalDataCount}
                    customExportOptions={customExportOptions}
                    debounceFilters={debounceFilters}
                    onSelect={onSelect}
                    onRefresh={onRefresh}
                    rowProps={rowProps}
                    layoutType={layoutType}
                    fields={exportFields}
                    disablePaging={disablePaging}
                    filterReturnType={'or'}
                    isNested={isNested}
                    persistSelected={persistSelected}
                >
                    <>
                        <WatchLayout layoutType={layoutType || 3} />
                        {children}
                    </>
                </GridProvider>
            </Content.Panel>
        </>
    )
}

export default CardGridComp;