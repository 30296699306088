import React from 'react';
import { default as CardMol } from '../../../molecules/card';
import useDataGrid from '../use-data-grid';
import styles from './card.module.scss';

interface Props {
    rowData: {
        ptrui_cacheKey: number;
        ptrui_sortKey: number;
        [key: string]: any;
    }
}
export default function Card({ rowData }: Props) {

    const grid = useDataGrid();
    const RenderComp = grid.CustomRowComp;
    return (
        <>
            <div className={`px-2 py-2 ${styles.compContainer}`}>
                <CardMol
                    className={`${styles.card}`}
                    data={rowData}
                >
                    {
                        (RenderComp) ? <RenderComp rowData={rowData} /> : <></>
                    }
                </CardMol>
            </div>
        </>
    )
}