/*
  RenderData
  - handles the different types of data rendering for the Grid.Table component
*/
import React from "react";
import Row from '../row';
import { GridNoResultsSplash, GridErrorSplash, GridLoadingSplash, GridNoColumnsSplash, GridRenderData } from '../../../molecules';
import useDataGrid from "../use-data-grid";

export default function RenderData() {

  const grid = useDataGrid();
  const shouldGridRefresh = (grid.shouldCachedDataRefresh) ? grid.isLoading : false;
  const onPageEnd = (grid.isClientRenderDisabled) ? grid.updateIsPageEnd : () => null;

  return (
    <>
      {
        (grid.areAllColumnsDisabled()) ?
          <GridNoColumnsSplash
            width={grid.width}
            height={grid.splashHeight}
          />

          : (shouldGridRefresh) ?
            <GridLoadingSplash
              width={grid.width}
              height={grid.splashHeight}
            />

            : (grid.fetchStatusCode !== 'No Errors Detected') ?
              <GridErrorSplash
                width={grid.width}
                height={grid.splashHeight}
                error={grid.fetchStatusCode}
              />

              : (grid.cachedTableData.length === 0) ?
                <GridNoResultsSplash
                  width={grid.width}
                  height={grid.splashHeight}
                />

                : <>
                  <GridRenderData
                    as={(grid.CustomRowComp) ? grid.CustomRowComp : Row}
                    activePage={grid.activePage}
                    pageSize={grid.pagingOptions.size}
                    data={grid.cachedTableData}
                    onScrollBottom={grid.onScrollBottom}
                    onScrollTop={grid.onScrollTop}
                    onPageCountChange={grid.setPageCount}
                    gridType={'table'}
                    totalDataCount={(typeof grid.totalDataCount === 'number') ? grid.totalDataCount : 0}
                    onPageEnd={onPageEnd}
                    onFetch={grid.scrollToIdAfterFetch}
                    isFetchRendering
                  />
                </>
      }
    </>
  );
}

