import React from 'react';
import { InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import { useInput, useDebounce } from '../../../../hooks';
import { useCardGridStore } from '../_Store/Store';
import { useI18N } from '../../../../hooks';

export default function CardGridSearch() {
    const i18n = useI18N();
    const { value, bind } = useInput("");
    const [{ searchQuery, searchDebounce, checkedList, queriedCardGridData, alphaFilter }, Store, Util] = useCardGridStore();
    const searchQueries = useDebounce((updatedSearchQuery: { targets: string[], query: string }) => {
        Store.update(Util.actions.updateSearchQuery, updatedSearchQuery);
        Store.update(Util.actions.updateActivePage, 0);
    }, searchDebounce | 250);

    return (
        <>
            <>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                disabled={(alphaFilter.active === 'checked' && checkedList.length === 0 && Object.values(queriedCardGridData).length === 0)}
                                placeholder={i18n.translateThis('Search', i18n.localize.input.search)}
                                {...bind}
                                onKeyUp={(e: any) => { e.persist(); searchQueries({ ...searchQuery, query: value }); }}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </>
        </>
    )
}