import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Content from '../../content';
import useDataGrid from '../use-data-grid';
import styles from './utilityBar.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    className?: string;
}
export default function UilityBar({ children, className }: Props) {

    const grid = useDataGrid();

    return (
        <>
            <Content.PanelHeader className={`${styles.compContainer} ${(grid.isNestedComp) ? 'bg-transparent' : ''} ${className}`}>
                <Col>
                    <Row>
                        <Col className={`d-flex justify-content-end`}>
                            {children}
                        </Col>
                    </Row>
                </Col>
            </Content.PanelHeader>
        </>
    )
}