import React from 'react';
import { useNonInitialEffect } from '../../../../hooks';
import { InputField } from '../../../atoms';
import useDataGrid from '../use-data-grid';
import useSearch from './use-search';
import { useI18N } from '../../../../hooks';

interface Props {
    onChange?: (value?: string) => void;
    placeHolder?: string;
    targetFields: string[];
    className?: string;
    width?: string;
}
export default function Search({ targetFields, onChange, placeHolder, className, width }: Props) {
    const i18n = useI18N();
    const { deleteProcess, ...grid } = useDataGrid();
    const search = useSearch(targetFields, onChange);
    const isDisabled = (grid.disableColumnFilters) ? true : (grid.isLoading) ? true : (deleteProcess.isDeleting) ? true : false;

    //listens for value change and then updates search query
    useNonInitialEffect(() => {
        search.updateQuery(search.inputValue, grid.searchQueries);
    }, [search.inputValue]);

    //addes default search query objects based on specified targets
    React.useEffect(() => {
        targetFields.forEach((field: string, index: number) => (
            grid.addSearchQuery({
                target: field,
                query: '',
                exact: false,
                type: "string",
                ptrui_cacheKey: `search_${index}`
            })
        ));
    }, []);

    return (
        <>
            <InputField
                {...search.bindToInput}
                icon={<i className="fas fa-search"></i>}
                className={`pt-2 ${className}`}
                placeHolder={(placeHolder) ? placeHolder : i18n.translateThis('Search', i18n.localize.cardGrid.search)}
                type={'text'}
                disabled={isDisabled}
                width={width}
            />
        </>
    )
}