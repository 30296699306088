﻿import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { useI18N } from '../../../hooks';

interface Props {
    onUploadFile: any;
    notAllowedExtentions? : string[]
}

function FileUpload({ onUploadFile, notAllowedExtentions }: Props) {
    const i18n = useI18N();
    const [selectedFile, setselectedFile] = useState<object | undefined>();
    const [warning, setWarning] = useState("");

    const handleSelectFile = (event: any) => {
        event.preventDefault();
        if(notAllowedExtentions && notAllowedExtentions?.filter(x => x === event.target.files[0].type).length === 1)
        {   
            const notAllowedExt = event.target.files[0].name.substr(event.target.files[0].name.indexOf(".") + 1);
            event.target.value = null;
            setWarning("type " + notAllowedExt + " is not supported");
            return;
        }
        else{
            setWarning("");
        }
        setselectedFile(event.target.files[0]);
    };

    const handleUploadFile = (event: any) => {
        event.preventDefault();
        onUploadFile(selectedFile);
    };

    return (
        <>
            <Form>
                <Form.Control
                    type="file"
                    onChange={(event: any) => {
                        handleSelectFile(event as any);
                    }}
                ></Form.Control>
                {
                    warning.length > 0 &&
                    <div style={{color:"red"}}>{warning}</div>
                }
                <br />
                <Form.Row>
                    <Col sm="9">&nbsp;</Col>
                    <Col sm="3">
                        <Button
                            variant="primary"
                            onClick={(event: any) => {
                                handleUploadFile(event as any);
                            }}
                        >
                            {i18n.translateThis('Upload', i18n.localize.fileUpload.upload)}
                        </Button>
                    </Col>
                </Form.Row>
            </Form>
        </>
    );
}

export default FileUpload;
