import React from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { useI18N } from '../../../hooks'

export default function InputError() {
    const i18n = useI18N();

    return (
        <>
            <Dropdown.ItemText className='d-flex align-items-center'>
                <span className='text-danger'><i className="fas fa-exclamation-triangle pr-2"></i>{i18n.translateThis('Fetch Error', i18n.localize.InputError.fetchError)}</span>
            </Dropdown.ItemText>
        </>
    )
}