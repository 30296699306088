import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useI18N } from '../../../hooks';

interface Props {
    message?: string;
    children?: JSX.Element;
}

export default function InputToolTip({ message, children }: Props) {
    const i18n = useI18N();
    return (
        <>
            <Dropdown.ItemText className={`pb-2 d-flex align-items-center `}>
                {
                    (children) ?
                        children :
                        <span className='text-muted'>
                            {(message) ? message : i18n.translateThis('Sorry, No Results', i18n.localize.sorryNoResults)}
                        </span>
                }
            </Dropdown.ItemText>
        </>
    )
}