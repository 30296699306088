import useDataGrid from '../use-data-grid';

const useAlphaFilter = (field: string, onChange?: (value?: string) => void) => {

    const grid = useDataGrid();

    const updateQuery = (value: string, searchQueries: { target: string, ptrui_cacheKey: string, filterType: 'alpha', [key: string]: any }[]) => {
        const queries = searchQueries.map((item) => {
            if (field.includes(item.target) && item.ptrui_cacheKey.includes('alpha')) {
                return {
                    ...item,
                    target: item.target,
                    query: value.toLowerCase(),
                    exact: false,
                    type: "string"
                };
            }
            return item;
        });
        if (onChange) {
            onChange(value);
        }
        grid.updateSearchQueries(queries as any);
    };

    return {
        updateQuery
    }
}

export default useAlphaFilter;