/*
  RenderData
  - handles the different types of data rendering for the Grid.Table component
*/
import React from "react";
import Card from '../card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { GridNoResultsSplash, GridErrorSplash, GridLoadingSplash, GridRenderData } from '../../../molecules';
import useDataGrid from "../use-data-grid";

export default function RenderData() {

  const grid = useDataGrid();

  return (
    <>
      {
        (grid.isLoading) ?
          <GridLoadingSplash
            width={grid.width}
            height={grid.splashHeight}
            as={'div'}
          />

          : (grid.fetchStatusCode !== 'No Errors Detected') ?
            <GridErrorSplash
              width={grid.width}
              height={grid.splashHeight}
              error={grid.fetchStatusCode}
              as={'div'}
            />

            : (grid.cachedTableData.length === 0 || grid.tableData.length === 0) ?
              <GridNoResultsSplash
                width={grid.width}
                height={grid.splashHeight}
                as={'div'}
              />

              : <>
                <Col className='p-4'>
                  <Row>
                    <GridRenderData
                      as={Card}
                      activePage={grid.activePage}
                      pageSize={grid.pagingOptions.size}
                      data={grid.tableData}
                      onScrollBottom={grid.onScrollBottom}
                      onScrollTop={grid.onScrollTop}
                      onPageEnd={grid.setIsOnPageEnd}
                      gridType={'card'}
                      colCount={grid.layoutType}
                    />
                  </Row>
                </Col>
              </>
      }
    </>
  );
}

