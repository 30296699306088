import "./_auto-complete.scss"
import React from 'react';
//import Autosuggest from 'react-autosuggest';
import axios from 'axios';

interface Props {
    placeholder: string,
    value: { value: string, text: string },
    setValue: React.Dispatch<React.SetStateAction<{ value: string, text: string }>>
    method?: string
    urlBase?: string
    urlComplemement?: string,
    data?: object,
    valueField?: string,
    textField: string,
    fieldCriteria?: string,
    nameResponseData?: string
}

function AutoComplete(props: Props) {
    const placeholder = props.placeholder;
    //State
    const value = props.value;
    const setValue = props.setValue;
    const nameResponseData = props.nameResponseData;
    const method = props.method === null ? "GET" : props.method;
    //valueField and textField, only textField is required if valueField is null takes the value of textField
    const valueField = props.valueField == null ? props.textField : props.valueField;
    const textField = props.textField;
    //Url Base
    const urlBase = props.urlBase ? props.urlBase : '';
    //urlComplemement is a complement an entire url example http://localhost:1545/CRM/CustomerHierarchy/Index 'CRM/CustomerHierarchy/Index' would be the complement but can be more nested
    const urlComplemement = props.urlComplemement || "";
    //data is additional data yo have to POST to a method
    const data: any = method === ("POST" || method === "PUT") ? props.data : {};
    const urlHasQuestionMark = urlComplemement.indexOf("?") != -1;
    //url in case of method Get will has a querystring fieldCriteria in case of Post will be added in the additional data in method onSuggestionsFetchRequested
    let url: string = `${urlBase}/${urlComplemement}`;
    url += `${(props.fieldCriteria == null ? "" : (urlHasQuestionMark ? "&" : "?") + props.fieldCriteria + "=fieldCriteriaValue")}`;

    const [suggestions, setSuggestions] = React.useState([]);

    const onSuggestionsFetchRequested = async ({ value }: any) => {
        if (!value) {
            setSuggestions([]);
            return;
        }
        try {
            let response: any;
            if (method === "GET") {
                response = await axios.get(url.replace("fieldCriteriaValue", value));
            }
            else {
                if (props.fieldCriteria)
                    data[props.fieldCriteria] = value;
                response = await axios.post(url, data);
            }

            let responseData = response.data;

            if (nameResponseData)
                if (response.data[nameResponseData])
                    responseData = response.data[nameResponseData]

            setSuggestions(
                responseData.map((row: any) => ({
                    value: row[valueField],
                    text: row[textField]
                }))
            );

        } catch (e) {
            setSuggestions([]);
        }
    };
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    }
    const getSuggestionValue = (suggestion: { value: string, text: string; }) => suggestion.text;
    const renderSuggestion = (suggestion: { value: string, text: string }) => (
        <>
            <input type="hidden" value={suggestion.value} />
            <div>
                {suggestion.text}
            </div>
        </>
    );
    const onSuggestionSelected = (event: { preventDefault: () => void; }, { suggestion, method }: any) => {
        if (method === "enter") {
            event.preventDefault();
        }
    }

    return (
        <div>
            {/* <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={{
                    placeholder: placeholder,
                    autoComplete: "abcd",
                    value: value.text,
                    name: "country",
                    onChange: (_event: React.FormEvent<any>, { newValue }: any) => {
                        if (_event.currentTarget.nodeName !== "INPUT") {
                            let parent = _event.currentTarget;
                            let controls: HTMLCollection = parent.children;
                            //When you selected one item the value an text is save
                            setValue({
                                value: (controls[0] as HTMLInputElement).value || "",
                                text: controls[1].innerHTML || ""
                            });
                        }
                        else {
                            //In case of you write somthing but no selection only save the text
                            setValue({
                                value: "",
                                text: newValue
                            });
                        }
                    }
                }}
            /> */}
        </div>
    );
}
export default AutoComplete;