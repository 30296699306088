import React from 'react';
import protransLogo from '../../../_assets/images/logo-protrans-colored.svg';
import protransWhiteLogo from '../../../_assets/images/logo-protrans-white.svg';
import optimizLogo from '../../../_assets/images/logo-optimiz-colored.svg';
import { Content } from '../../organisms';
import { LoadingIndicator } from '../../atoms';
import styles from './checkAuth.module.scss';
import { useAppStore } from '../../../_Store';
import { useI18N } from '../../../hooks';

export default function CheckAuthPage() {
    const i18n = useI18N();
    const [{ ptrui_appLogo: appLogo }] = useAppStore();

    return (
        <>
            <div className={styles.sceneContainer}>
                <div className={`d-flex align-items-center justify-content-center ${styles.formContainer}`} >
                    <div className={`${styles.formPanel}`}>
                        <div className={'d-flex justify-content-center'}>
                            {appLogo === "protrans" ? (

                                <img src={protransLogo} className={styles.logo} alt="Protrans" />

                            ) : appLogo === "optimiz" ? (

                                <img src={optimizLogo} className={styles.logo} alt="Optimiz" />

                            ) : null}
                        </div>

                        <Content.Panel>
                            <div className='d-flex justify-content-center pt-4'>
                                <div className={'d-flex flex-column align-items-center'}>
                                    <LoadingIndicator size={4} />
                                    <p className='text-center pt-3'><small>{i18n.translateThis('Authenticating', i18n.localize.checkAuth.authenticating)}...</small></p>
                                </div>
                            </div>
                        </Content.Panel>
                    </div>
                </div>
                <div className={`d-flex justify-content-end align-items-center ${styles.loginFooter}`}>
                    <img src={protransWhiteLogo} className={styles.logo} alt="Protrans" />
                </div>
            </div>

        </>
    )
}