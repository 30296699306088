import React from 'react';
import { SelectedActions as SelectedActionsMol } from '../../../molecules';
import useDataGrid from '../use-data-grid';

interface Props {
    iconOnly?: boolean;
    className?: string;
    disabled?: boolean;
}
export default function SelectedActions({ iconOnly, className, disabled }: Props) {

    const grid = useDataGrid();
    const count = grid.selectedRows.length || 0;
    const totalCount = (typeof grid.totalDataCount === 'number') ? grid.totalDataCount : 0;
    const isDisabled = (disabled) ? true : (grid.isLoading) ? true : false;

    return (
        <>
            <SelectedActionsMol
                small
                count={count}
                totalCount={totalCount}
                selectAllLabel={'Select All (Fetched)'}
                onSelectAllChange={(isChecked: boolean) => {
                    if (isChecked) {
                        grid.selectAllRows();
                        grid.setDisableColumnFields(true);
                    }
                    else {
                        grid.unSelectAllRows();
                        grid.setDisableColumnFields(false);
                    }
                }}
                onUnselectClick={() => {
                    grid.unSelectAllRows();
                    grid.resetSearchQueries();
                    grid.setDisableColumnFields(false);
                    grid.showAllRows();
                }}
                className={className}
                iconOnly={iconOnly}
                disabled={isDisabled}
            />
        </>
    )
}