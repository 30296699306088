import React from 'react';
import { useInput, useDebounce, useNonInitialEffect } from '../../../hooks';
import { InputField } from '../../atoms';
import useFilterList from './use-filter-list';
import { useFilterListStore } from './Store';
import { useI18N } from '../../../hooks';

const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => {

    const [{ inputSize, isDisabled, placeHolderText, searchQuery, toggleMeta, activeInputValue, defaultInputValue }] = useFilterListStore();

    const filterList = useFilterList();
    const i18n = useI18N();

    const { value, bind, setValue } = useInput('');

    const handleKeyDownEvent = (e: any) => {
        const keyCode = e.keyCode;

        if (keyCode === 8 || keyCode === 46) {
            filterList.resetActiveInputValue();
            filterList.resetSearchQuery();
        }

        else if (keyCode !== 38 && keyCode !== 40) {
            let newChar = "";
            if(keyCode >= 32)
                newChar = e.key;
            
            filterList.setSearchQuery({ query : value + newChar});

            if (toggleMeta.isOpen !== true) {
                onClick(e);
            }
        }
    }

    useNonInitialEffect(() => {
        setValue(activeInputValue.text);
        //makes sure menu closes when item is selected and enter is clicked
        if (toggleMeta.event !== null) {
            if (toggleMeta.metadata.source !== 'select') {
                onClick(toggleMeta.event);
            }
        }
    }, [activeInputValue])

    useNonInitialEffect(() => {
        filterList.setActiveInputValue(defaultInputValue);
    }, [defaultInputValue.text]);

    return (
        <>
            <InputField
                {...bind}
                caretDown
                placeHolder={(placeHolderText) ? placeHolderText : i18n.translateThis('Search...', i18n.localize.customToggle.search)}
                size={inputSize}
                disabled={isDisabled}
                compRef={ref}
                type={'text'}
                onClick={(e) => {
                    onClick({});
                }}
                onKeyDown={(e) => {
                    e.persist(); handleKeyDownEvent(e);
                }}
            />
        </>
    )
});

export default CustomToggle;


