import React from 'react';
import { default as CardMol } from '../../../molecules/card';
import { useNonInitialEffect } from '../../../../hooks';
import useDataGrid from '../use-data-grid';
import useCard from './use-card';
import styles from './card.module.scss';

interface Props {
    rowData: {
        ptrui_cacheKey: number;
        ptrui_sortKey: number;
        [key: string]: any;
    }
}
export default function Card({ rowData }: Props) {

    const { deleteProcess, ...grid } = useDataGrid();
    const card = useCard();
    const RenderComp = grid.CustomRowComp;
    const [isActive, setIsActive] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    React.useEffect(() => {
        setIsActive((grid.selectedRows.find((row) => row.ptrui_cacheKey === rowData?.ptrui_cacheKey)) ? true : false);
    }, [grid.selectedRows]);

    useNonInitialEffect(() => {
        setIsDeleting((isActive && deleteProcess.isDeleting) ? true : false);
    }, [deleteProcess.isDeleting, isActive]);

    return (
        <>
            <div className={`px-2 py-2 ${styles.compContainer}`}>
                <CardMol
                    className={`${styles.card}`}
                    disableActiveState={!grid.isOnRowSelect}
                    isActive={isActive}
                    data={rowData}
                    selectable={grid.isOnRowSelect}
                    onSelect={card.onSelect}
                    deleting={isDeleting}
                    deleteSuccess={grid.deleteSuccess}
                >
                    {
                        (RenderComp) ? <RenderComp rowData={rowData} isActive={isActive} /> : <></>
                    }
                </CardMol>
            </div>
        </>
    )
}