import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { BtnToggle, ListItemDropdown } from '../../atoms';

interface Props {
    children?: JSX.Element | JSX.Element;
    counter?: number;
    small?: boolean;
    large?: boolean;
    label?: string;
    icon?: JSX.Element;
    caret?: boolean;
    caretDir?: 'right' | 'left' | 'down' | 'up';
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string;
    className?: string;
    classNameDropdown?: string;
    disabled?: boolean;
}
export default function BtnWithDropdown({ children, small, large, icon, label, variant, className, classNameDropdown, counter, caret, caretDir, disabled }: Props) {

    return (
        <>
            <Dropdown>
                <BtnToggle
                    count={counter}
                    small={small}
                    large={large}
                    variant={variant}
                    label={label}
                    icon={icon}
                    className={className}
                    caret={caret}
                    caretDir={caretDir}
                    disabled={disabled}
                />
                <ListItemDropdown className={classNameDropdown}>
                    {children}
                </ListItemDropdown>
            </Dropdown>
        </>
    )
}