import React from 'react';
import { CreateGridItem } from '../../../molecules';
import useDataGrid from '../use-data-grid';
interface Props {
    iconOnly?: boolean;
    className?: string;
    label?: string;
    disabled?: boolean;
    onModalOpen?: () => void;
    onModalClose?: () => void;
    customForm?: ({ closeModal }: { closeModal: () => void }) => JSX.Element;
}
export default function CreateRow({ iconOnly, className, label, disabled, onModalOpen, onModalClose, customForm }: Props) {

    const grid = useDataGrid();
    const isDisabled = (disabled) ? true : (grid.isLoading) ? true : false;

    return (
        <>
            <CreateGridItem
                onModalOpen={onModalOpen}
                onModalClose={onModalClose}
                customForm={customForm}
                iconOnly={iconOnly}
                className={className}
                label={(label) ? label : 'Create Row'}
                small
                disabled={isDisabled}
                modalSize={'md'}
            />
        </>
    )
}