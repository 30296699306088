import React from 'react';
import { Accordion, Card, AccordionContext } from 'react-bootstrap';
import { useCleanupEffect } from '../../../hooks';
import styles from './accordionDropdown.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    eventKey: string;
    className?: string;
    noPadding?: boolean;
}

export default function AccordionDropdown({ children, eventKey, className, noPadding }: Props) {

    const currentEventKey = React.useContext(AccordionContext);
    const isOpen = (currentEventKey === '1') ? true : false;

    const ChildrenComps = () => {
        return (
            children
        )
    }

    return (
        <>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body className={`${(noPadding) ? 'py-0' : 'py-2'} px-0 ${styles.compContainer} ${className}`}>
                    {
                        (isOpen) && ChildrenComps()
                    }
                </Card.Body>
            </Accordion.Collapse>
        </>
    )
}