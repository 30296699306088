import { GridProvider } from '../../molecules';

//hook used internally to update Grid Store
const useDataGrid = () => GridProvider.useStore();

//hook that can be used externally by downstream devs
const useGrid = () => {
    const {
        setLoading,
        setFetchStatus,
        cachedTableData: tableData,
        appendRow,
        prependRow,
        isLoading,
        totalDataCount,
        customItemProps: customRowProps
    } = useDataGrid();


    return {
        setLoading,
        setFetchStatus,
        appendRow,
        prependRow,
        tableData,
        isLoading,
        totalDataCount,
        customRowProps
    }
}

export { useGrid };
export default useDataGrid;