import React from 'react';
import { Button } from 'react-bootstrap';
import LoadingIndicator from '../loading-indicator';
import { useI18N } from '../../../hooks';

interface Props {
    label?: string;
    fetchingLabel?: string;
    loading?: boolean;
    onClick?: (toggle: boolean) => void;
}
export default function RefetchDataBtn({ label, loading, fetchingLabel }: Props) {
    const i18n = useI18N();

    return (
        <>
            <Button variant={'info'} disabled={loading}>
                {
                    (!loading) ?
                        <>
                            <i className="fas fa-database pr-2"></i>
                            {
                                (label) ? label : i18n.translateThis('Refetch Data', i18n.localize.RefetchDataBtn.refetchData)
                            }
                        </> :
                        <>
                            <span className={'pr-2'}>
                                <LoadingIndicator size={1} color={'white'} />
                            </span>
                            {
                                (fetchingLabel) ? fetchingLabel : i18n.translateThis('...Fetching', i18n.localize.RefetchDataBtn.fetching)
                            }
                        </>
                }

            </Button>
        </>
    )
}