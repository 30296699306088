
import {
    createStore,
    useScopedTreble,
    useTreble,
    TUseTreble
} from "treble-gsm";
import { IAutoSuggest } from "../auto-suggest/interfaces";

interface IState {
    CustomDataComp?: IAutoSuggest.CustomSuggestionComp;
    onSelectHandler: IAutoSuggest.OnSelectHandler;
    data: IAutoSuggest.MenuItem[];
    fetchStatusCode: IAutoSuggest.FetchStatusCode;
    defaultInputValue: IAutoSuggest.MenuItem;
    activeInputValue: IAutoSuggest.MenuItem;
    focusedDataIndex: number,
    searchQuery: IAutoSuggest.SearchQuery,
    placeHolderText?: string;
    prependIcon?: JSX.Element;
    inputSize?: IAutoSuggest.InputSize;
    isDisabled?: boolean;
    inputClassNames?: string;
    toggleMeta: IAutoSuggest.ToggleMeta
}

const actionKeys = {
    ['setCustomDataComp']: 'setCustomDataComp',
    ['updateData']: 'updateData',
    ['updateFetchStatusCode']: 'updateFetchStatusCode',
    ['updateFocusedSuggestionIndex']: 'updateFocusedSuggestionIndex',
    ['updateSearchQuery']: 'updateSearchQuery',
    ['updateDefaultInputValue']: 'updateDefaultInputValue',
    ['udpateActiveInputValue']: 'udpateActiveInputValue',
    ['setPlaceHolderText']: 'setPlaceHolderText',
    ['updatePrependIcon']: 'updatePrependIcon',
    ['updateInputSize']: 'updateInputSize',
    ['updateIsDisabled']: 'updateIsDisabled',
    ['updateInputClassNames']: 'updateInputClassNames',
    ['updateToggleMeta']: 'updateToggleMeta'

}

type TActions = typeof actionKeys;

export type TStore = TUseTreble<IState, TActions>;

//create scoped store context
const FilterListContext = useScopedTreble();

//create custom treble hook
export const useFilterListStore = () => useTreble(FilterListContext) as TStore;

//treble store
export const Store = createStore([
    {
        action: actionKeys.setCustomDataComp,
        state: {
            CustomDataComp: null
        }
    },
    {
        action: actionKeys.updateToggleMeta,
        state: {
            toggleMeta: {
                isOpen: false,
                event: null,
                metadata: {
                    source: undefined
                }
            }
        }
    },
    {
        action: actionKeys.updateSearchQuery,
        state: {
            searchQuery: {
                query: ''
            }
        }
    },
    {
        action: actionKeys.updateFetchStatusCode,
        state: {
            fetchStatusCode: null
        }
    },
    {
        action: actionKeys.updateData,
        state: {
            data: []
        }
    },
    {
        action: actionKeys.updateDefaultInputValue,
        state: {
            defaultInputValue: {
                value: '',
                text: ''
            }
        }
    },
    {
        action: actionKeys.udpateActiveInputValue,
        state: {
            activeInputValue: {
                text: '',
                value: 0
            }
        }
    },
    {
        action: actionKeys.setPlaceHolderText,
        state: {
            placeHolderText: undefined
        }
    },
    {
        action: actionKeys.updatePrependIcon,
        state: {
            prependIcon: false
        }
    },
    {
        action: actionKeys.updateInputSize,
        state: {
            inputSize: undefined
        }
    },
    {
        action: actionKeys.updateIsDisabled,
        state: {
            isDisabled: false
        }
    },
    {
        action: actionKeys.updateInputClassNames,
        state: {
            inputClassNames: ''
        }
    },
    {
        action: actionKeys.updateFocusedSuggestionIndex,
        state: {
            focusedSuggestionIndex: 0
        }
    }

], {
    context: FilterListContext
});

