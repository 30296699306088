import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './defaultItem.module.scss';
import { useI18N } from '../../../../../../hooks';

export default function DefaultItem() {
    const i18n = useI18N();
    return (
        <>
            <Dropdown.ItemText className={styles.defaultItem}>
                <p className='mb-0'>{i18n.translateThis('Sorry, No Results', i18n.localize.sorryNoResults)}...</p>
            </Dropdown.ItemText>
        </>
    )
}