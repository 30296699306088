import React from 'react';
import { default as CardGridComp } from './card-grid';
import Header from './header';
import Body from './body';
import Footer from './footer';
import UilityBar from './utility-bar';
import LayoutOptions from './layout-options';
import ExportToExcel from './export-to-excel';
import ExportToPDF from './export-to-pdf';
import RefreshData from './refresh-data';
import Counters from './counters';
import { GridProps } from './card-grid';
import { useGrid } from './use-data-grid';

interface ICardGrid {
    (props: GridProps): JSX.Element;
    Header: typeof Header;
    Body: typeof Body;
    Footer: typeof Footer;
    UtilBar: typeof UilityBar;
    ExportToExcel: typeof ExportToExcel;
    ExportToPDF: typeof ExportToPDF;
    LayoutOptions: typeof LayoutOptions;
    RefreshData: typeof RefreshData;
    Counters: typeof Counters;
    useGrid: typeof useGrid;
}

const BasicCardGrid: ICardGrid = (props) => <CardGridComp {...props} />;
BasicCardGrid.Header = Header;
BasicCardGrid.Body = Body;
BasicCardGrid.Footer = Footer;
BasicCardGrid.UtilBar = UilityBar;
BasicCardGrid.LayoutOptions = LayoutOptions;
BasicCardGrid.ExportToExcel = ExportToExcel;
BasicCardGrid.ExportToPDF = ExportToPDF;
BasicCardGrid.RefreshData = RefreshData;
BasicCardGrid.Counters = Counters;
BasicCardGrid.useGrid = useGrid;

export default BasicCardGrid;