import React from 'react';
import { GridBackToTop } from '../../../atoms';
import RenderData from '../render-data';
import useDataGrid from '../use-data-grid';

export default function Body() {

    const grid = useDataGrid();
    const bodyRef = React.useRef<any>(null);

    //updates tBodyRef in store so it can be shared to other components like infiniteScroll settings
    React.useLayoutEffect(() => {
        grid.setTableBodyRef(bodyRef);
    }, [bodyRef]);

    return (
        <>
            <div
                ref={bodyRef}
                style={{
                    maxHeight: `${grid.height}`,
                    minHeight: `${grid.height}`,
                    overflowX: (grid.tableData.length === 0 || grid.isLoading === true) ? 'hidden' : 'auto'
                }}
            >
                <RenderData />
            </div>
            <GridBackToTop
                show={(grid.isPageEnd) ? (grid.activePage === 0) ? false : true : false}
                onClick={grid.resetGridPosition}
            />
        </>
    )
}