import React from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import styles from './loadingIndicator.module.scss';
import { useI18N } from '../../../../../../hooks';

export default function LoadingIndicator() {
    const i18n = useI18N();
    return (
        <>
            <Dropdown.ItemText className={`${styles.loadingIndicator} d-flex`}>
                <Spinner className={`${styles.spinner} mr-2`} animation={'border'} variant={'info'} />
                <p className='mb-0 text-info'>
                    {i18n.translateThis('Loading...', i18n.localize.loading)}
                </p>
            </Dropdown.ItemText>
        </>
    )
}