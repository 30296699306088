import React from 'react';
import { useInAppMessage, useResponsive } from '../../../../hooks';
import { ExportBtnExcel } from "../../../molecules";
import useDataGrid from '../../data-grid/use-data-grid';
import { useI18N } from '../../../../hooks';

interface Props {
    className?: string;
    iconOnly?: boolean;
    disabled?: boolean;
    enableMobile?: boolean;
}
export default function ExportToExcel({ className, iconOnly, disabled, enableMobile }: Props) {
    const i18n = useI18N();
    const grid = useDataGrid();
    const message = useInAppMessage();
    const count = grid.cachedTableData.length;
    const res = useResponsive();
    const isDisabled = (disabled) ? true : (grid.isLoading) ? true : false;
    const isIconOnly = (iconOnly) ? true : (!res.lgDown && enableMobile) ? true : false;
    const onExport = () => {
        const resolveExportMSG = () => message.createSuccess(i18n.translateThis('Exported', i18n.localize.exportTo.exported) + ` ${count} ${(count === 1) ? i18n.translateThis('item', i18n.localize.exportTo.item) : i18n.translateThis('items', i18n.localize.exportTo.items)}!`, { dismiss: 5000 });
        message.createLoading(i18n.translateThis('Exporting', i18n.localize.exportTo.exporting) + ` ${count} ${(count === 1) ? i18n.translateThis('item', i18n.localize.exportTo.item) : i18n.translateThis('items', i18n.localize.exportTo.items)}...`);
        setTimeout(() => {
            resolveExportMSG();
        }, 2000);
    }

    return (
        <>
            <ExportBtnExcel
                className={className}
                small
                data={grid.cachedTableData}
                fields={grid.fields}
                iconOnly={isIconOnly}
                disabled={isDisabled}
                onExport={onExport}
            />
        </>
    )
}