import React from 'react';
import { PanelFooter as PanelFooterAtom } from '../../../atoms';

interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[];
    bgNone?: boolean;
}
export default function PanelFooter({ className, children, bgNone }: Props) {

    return (
        <>
            <PanelFooterAtom className={className} bgNone={bgNone}>
                {children}
            </PanelFooterAtom>
        </>
    )
}