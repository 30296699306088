/*
    Column
    - component for table colums
*/
import React, { useRef } from "react";
import { GridColumn } from '../../../molecules';
import useDataGrid from '../use-data-grid';

interface Props {
  title: string | JSX.Element;
  field: string;
  width?: string;
  dataType?: 'string' | 'number' | 'boolean';
  hideTitle?: boolean;
  children?: JSX.Element | JSX.Element[];
}
export default function Column({ title, field, width, dataType, children, hideTitle }: Props) {

  const grid = useDataGrid();
  const columnRef = useRef(null);
  const memoChildren = React.useMemo(() => children, []);

  React.useLayoutEffect(() => {
    //creates static state in the store out of column props on render
    grid.addColumnField({
      field: field,
      title: title,
      width: width || '',
      enabled: true,
      dataType: dataType
    });
  }, []);

  //saved column ref to Store
  React.useEffect(() => {
    grid.setColumnRef(columnRef);
  }, [columnRef]);

  return (
    <>
      <GridColumn
        refProp={columnRef}
        width={width}
        title={(!hideTitle) ? title : ''}
      >
        <>
          {memoChildren}
        </>
      </GridColumn>
    </>
  );
}
