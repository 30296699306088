import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useI18N } from '../../../hooks';

interface Props {
    totalCount?: number;
    filterCount?: number;
    fetchedCount?: number;
    selectedCount?: number;
    className?: string;
    isLoading: boolean;
    error?: object | null | string;
    onClick?: () => void;
}
export default function BtnLayoutOptions({ totalCount, filterCount, fetchedCount, selectedCount, isLoading, error, onClick, className }: Props) {
    const i18n = useI18N();
    const isFilter = (typeof filterCount === 'number') ? true : false;
    const isFetched = (typeof fetchedCount === 'number') ? true : false;
    const isSelected = (typeof selectedCount === 'number') ? true : false;

    return (
        <>
            <ButtonGroup size="sm" className={className} onClick={isLoading ? () => null : (onClick) ? () => onClick() : () => null}>
                {
                    (isSelected) ?
                        <Button active={false} variant="dark" onClick={() => null}>
                            <i className="fas fa-check-square pr-1"></i>{" "}
                            {selectedCount}
                        </Button> : null
                }
                {
                    (isFilter) ?
                        <Button active={false} variant="info" onClick={() => null}>
                            <i className="fas fa-filter pr-1"></i>{" "}
                            {filterCount}
                        </Button> : null
                }
                {
                    (isFetched) ?
                        <Button active={false} variant="info" onClick={() => null}>
                            <i className="fas fa-file-search pr-1"></i>{" "}
                            {fetchedCount}
                        </Button> : null
                }
                <Button
                    active={false}
                    disabled={isLoading}
                    className={`${(error === 'No Errors Detected')
                        ? "btn-primary"
                        : "btn-danger"
                        }`}
                >
                    <i className="fas fa-database pr-1"></i>
                    {
                        (error === 'No Errors Detected') ?
                            (isLoading) ? i18n.translateThis('Fetching...', i18n.localize.fetching) : totalCount : "Error"
                    }
                </Button>
            </ButtonGroup>

        </>
    )
}