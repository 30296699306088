import React from 'react';
import { useIsInViewport } from '../../../hooks';
//import LoadingIndicator from '../loading-indicator';
import { useI18N } from '../../../hooks';

interface Props {
    children?: JSX.Element;
}
export default function GridLazyRow({ children }: Props) {
    const i18n = useI18N();
    const ref = React.useRef(null);
    const isInViewport = useIsInViewport(ref);

    return (
        <>
            <tr ref={ref}>
                {
                    (isInViewport) ?
                        children :
                        <td>
                            <div
                                className={`d-flex align-items-center justify-content-start`}
                                style={{ height: "100%" }}
                                >
                                <p>{i18n.translateThis('Loading...', i18n.localize.loading)}</p>
                            </div>
                        </td>
                }
            </tr>
        </>
    )
}