import React from 'react';
import { Card as BTCard } from 'react-bootstrap';
import { DeletingIndicator } from '../../atoms';
import cardStyles from './card.module.scss';
import { ICard } from './interfaces';

export default function Card({ children, className, minHeight, onSelect, selectable, data, isActive, deleting, deleteSuccess, noShadow, noHover, style, disableActiveState }: ICard.ICardComp) {

    const [active, setActive] = React.useState(false);
    const ref = React.useRef(null);
    const [isRemoved, setIsRemoved] = React.useState(false);
    const height = (ref.current !== null) ? (ref.current as any).clientHeight : undefined;
    const isDeleting = (deleting !== undefined) ? (deleting) ? true : false : false;
    const styleProps = (minHeight) ? { minHeight: minHeight, ...style } : { ...style };

    React.useLayoutEffect(() => {
        setActive((isActive) ? true : false);
    }, [isActive]);

    React.useEffect(() => {
        setIsRemoved((deleteSuccess && isActive) ? true : false);
    }, [deleteSuccess, isActive]);

    return (
        <>
            <BTCard
                ref={ref}
                className={`${cardStyles.compContainer} ${(noShadow) ? cardStyles.border : cardStyles.shadow} ${(noHover) ? '' : cardStyles.hover} ${(active && !isDeleting && !disableActiveState) ? cardStyles.active : ''} ${(selectable) ? 'cursor' : ''} ${(isRemoved) ? cardStyles.remove : ''} ${className}`}
                style={styleProps}
                id={`ptrui_card_${data?.ptrui_cacheKey}`}
                onClick={(e: any) => {
                    if (selectable && data && onSelect) {
                        onSelect(data, active, e);
                    }
                }}
            >
                <div className={`${cardStyles.deletingContainer} ${(isDeleting) ? 'd-flex' : 'd-none'} justify-content-center align-items-center`} style={{ height: height }}>
                    <DeletingIndicator size={4} portrait />
                </div>
                <div className={`${(isDeleting) ? 'd-none' : ''}`}>
                    {children}
                </div>
            </BTCard>
        </>
    )
}