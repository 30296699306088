import React from 'react';
import Col from 'react-bootstrap/Col';
import { TableRowPlaceholder } from '../../atoms';
import { useNonInitialEffect } from '../../../hooks';
import useGridRenderData from './use-grid-render-data';
import { useI18N } from '../../../hooks';

interface Props {
    as?: any;
    data?: any[];
    pagedData?: any[][];
    activePage: number;
    pageSize?: number;
    totalDataCount?: number;
    windowing?: boolean;
    onScrollTop?: (firstRowIndex: number, itemId?: 'card' | 'table') => void;
    onScrollBottom?: (lastRowIndex: number, itemId?: 'card' | 'table') => void;
    isFetchRendering?: boolean;
    onFetch?: (lastRowIndex: number, gridType?: 'card' | 'table') => void;
    onPageEnd?: (isPageEnd: boolean) => void;
    onPageCountChange?: (pageCount: number) => void;
    gridType?: 'card' | 'table';
    colCount?: 1 | 2 | 3;
}
export default function GridRenderData({ as, data: dataProp, pageSize, activePage, onScrollTop, onScrollBottom, isFetchRendering, totalDataCount: totalDataCountProp, onPageEnd, onFetch, onPageCountChange, gridType, colCount }: Props) {
    const i18n = useI18N();
    const data = (dataProp) ? dataProp : [];
    const totalDataCount = (totalDataCountProp) ? totalDataCountProp : 0;
    const render = useGridRenderData();
    const [renderData, setRenderData] = React.useState((data) ? render.chunkArray(data, pageSize || 15) : []);
    const Component = (as) ? as : null;
    const RenderComp = ({ rowData }: any) => ((Component !== null) ? <Component rowData={rowData} /> : null);
    const RenderCompMemo = React.useMemo(() => RenderComp, []);
    const isPageStart = (activePage === 0) ? true : false
    const isPageEnd = (activePage < (renderData.length - 1)) ? false : true;
    const isTotalCountReached = (data.length < totalDataCount) ? false : true;
    const isFetchingPlaceHolderHidden = (isTotalCountReached && isPageEnd) ? true : false;
    const current = render.windowData(renderData, activePage || 0);
    const firstRowIndex = (current) ? (current[0]?.ptrui_cacheKey !== undefined) ? current[0]?.ptrui_cacheKey : 0 : 0;
    const lastRowIndex = (current) ? (current[current.length - 1]?.ptrui_cacheKey !== undefined) ? current[current.length - 1]?.ptrui_cacheKey : 0 : 0;

    useNonInitialEffect(() => {
        setRenderData((data && pageSize) ? render.chunkArray(data, pageSize) : (data) ? data : [])
    }, [data, pageSize]);

    const scrollTop = () => {
        if (onScrollTop) {
            onScrollTop(firstRowIndex, gridType);
        }
    }

    const scrollBottom = () => {
        if (onScrollBottom) {
            onScrollBottom(lastRowIndex, gridType);
        }
    }

    React.useEffect(() => {
        if (onPageEnd) {
            onPageEnd(isPageEnd);
        }
    }, [isPageEnd]);

    //runs scroll bottom function after data changes and update page count
    React.useEffect(() => {
        if (onFetch && isPageEnd && data.length > 0) {
            onFetch(lastRowIndex, gridType);
        }
    }, [data]);

    //update page count
    React.useEffect(() => {
        if (onPageCountChange) {
            onPageCountChange(renderData.length);
        }
    }, [renderData]);

    return (
        <>
            <TableRowPlaceholder
                hide={isPageStart}
                count={1}
                onView={() => scrollTop()}
                gridType={gridType}
            />
            {
                render.windowData(renderData, activePage || 0)?.map((row: { [key: string]: any }) => {

                    return (
                        <React.Fragment key={row.ptrui_cacheKey}>
                            {
                                (gridType === 'card') ?
                                    <Col sm={(colCount === 1) ? '12' : (colCount === 2) ? '6' : (colCount === 3) ? '4' : '12'} className={'p-0'}>
                                        <RenderCompMemo rowData={row} />
                                    </Col>

                                    : (gridType === 'table') ? <RenderCompMemo rowData={row} /> : <RenderCompMemo rowData={row} />
                            }
                        </React.Fragment>
                    );
                })
            }
            <TableRowPlaceholder
                hide={(isFetchRendering) ? isFetchingPlaceHolderHidden : isPageEnd}
                label={(isFetchRendering && isPageEnd) ? i18n.translateThis('Fetching...', i18n.localize.fetching) : i18n.translateThis('Loading...', i18n.localize.loading)}
                count={1}
                onView={scrollBottom}
                gridType={gridType}
            />
        </>
    )
}