/*
    Calculate Grid Height
    calculates fallback grid height for when tbody in table do not have rows.
    Fixes overflow issue when displaying loader or no results icon
*/

import { IGridMiddlewareData } from '../Store';

const calcGridHeight = (data: IGridMiddlewareData) => {
  let dispatchValue = data.dispatchValue;
  let gridHeightUnit = dispatchValue.replace(/[0-9]/g, "");
  let gridHeightValue = parseInt(dispatchValue, 10);
  let theadHeightValue = data.storeState.theadRef?.current?.clientHeight + 17;
  let newCalculatedValue = gridHeightValue - theadHeightValue;
  let processedGridHeight = `${newCalculatedValue}${gridHeightUnit}`;
  return processedGridHeight;
};

export default calcGridHeight;
