import React from 'react';
import { Button } from 'react-bootstrap';
import { ModalBasic } from '../../molecules';
import styles from './confirmDialog.module.scss';
import { useI18N } from '../../../hooks';

interface Props {
    show: boolean,
    confirmAction: () => void;
    cancelAction: () => void;
    onHide: () => void,
    confirmText?: string,
    cancelText?: string,
    icon?: JSX.Element | boolean,
    children?: JSX.Element | JSX.Element[]
}

export default function ConfirmDialog({ show, onHide, confirmAction, icon, cancelAction, children, confirmText, cancelText }: Props) {
    const i18n = useI18N();
    return (
        <>
            <ModalBasic animation={true} backdrop={'static'} show={show} onHide={() => onHide()} size={'sm'} centered={false}>
                <ModalBasic.Body>
                    {
                        (icon !== false) ?
                            <div className='d-flex justify-content-center align-items-center p-4'>
                                {
                                    (icon) ?
                                        icon : <i className={`${styles.icon} fad fa-exclamation-triangle text-warning`}></i>
                                }
                            </div> : null
                    }

                    <div className='text-center'>
                        {
                            (children) ?
                                children :
                                <p className='text-center'>{i18n.translateThis('Are you sure', i18n.localize.confirmDialog.areYouSure)}?</p>
                        }
                    </div>
                </ModalBasic.Body>
                <ModalBasic.Footer className='d-flex justify-content-center'>
                    <div className='d-flex'>

                        <Button variant={'link'} className={'mr-2 text-danger'} onClick={() => cancelAction()}>
                            <i className="fas fa-ban pr-2"></i>
                            {
                                (cancelText) ? cancelText : i18n.translateThis('Cancel', i18n.localize.confirmDialog.cancel)
                            }
                        </Button>
                        <Button variant={'success'} onClick={() => confirmAction()}>
                            <i className="fas fa-check pr-2"></i>
                            {
                                (confirmText) ? confirmText : i18n.translateThis('Confirm', i18n.localize.confirmDialog.confirm)
                            }
                        </Button>

                    </div>
                </ModalBasic.Footer>
            </ModalBasic>
        </>
    )
}