import React from 'react';
import Col from 'react-bootstrap/Col';
import { LoadingIndicator } from '../../atoms';
import { useIsInViewport } from '../../../hooks';
import { useI18N } from '../../../hooks';

interface Props {
    hide?: boolean;
    count?: number;
    onView?: () => void;
    label?: string;
    gridType?: 'card' | 'table';
}
export default function TableRowPlaceholder({ hide, count, onView, label, gridType }: Props) {
    const i18n = useI18N();
    const ref = React.useRef(null);
    const isInViewport = useIsInViewport(ref);
    const rows: number[] = Array.from({ length: (count) ? count : 0 });

    const LoadingBody = ({ size, hideLabel }: { size: number, hideLabel?: boolean }) => (
        <div
            className={`d-flex align-items-center justify-content-start`}
            style={{ height: "100%" }}
        >
            <LoadingIndicator size={size} />
            {
                (hideLabel) ? null : <p className={'mb-0 pl-2 text-muted'}>{(label) ? label : i18n.translateThis('Loading...', i18n.localize.loading)}</p>
            }
        </div>
    );

    React.useEffect(() => {
        if (isInViewport) {
            if (onView) {
                onView();
            }
        }
    }, [isInViewport]);

    return (
        <>
            {
                rows.map((item, index) => (
                    <React.Fragment key={index}>
                        {
                            (gridType === 'card') ?
                                <Col ref={ref} sm={'12'} className={(hide) ? 'd-none' : 'd-flex justify-content-center pt-2 pb-4'}>
                                    <LoadingBody size={3} />
                                </Col> :
                                (gridType === 'table') ?
                                    <tr ref={ref} className={(hide) ? 'd-none' : ''} key={index}>
                                        <td>
                                            <LoadingBody size={1} />
                                        </td>
                                    </tr> :
                                    <tr ref={ref} className={(hide) ? 'd-none' : ''} key={index}>
                                        <td>
                                            <LoadingBody size={1} />
                                        </td>
                                    </tr>
                        }
                    </React.Fragment>
                ))
            }
        </>
    )
}

