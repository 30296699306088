import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

interface Props {
    active?: 1 | 2 | 3;
    onTripleLayout?: () => void;
    onDoubleLayout?: () => void;
    onSingleLayout?: () => void;
    className?: string;
    disabled?: boolean;
}
export default function BtnLayoutOptions({ onTripleLayout, onDoubleLayout, onSingleLayout, active, className, disabled }: Props) {

    return (
        <>
            <ButtonGroup size="sm" className={className}>
                {
                    (onTripleLayout) ?
                        <Button variant="secondary" active={(active === 3) ? true : false} disabled={disabled} onClick={disabled ? () => null : (onTripleLayout) ? () => onTripleLayout() : () => null}>
                            <i className="fas fa-th"></i>
                        </Button> : null
                }
                {
                    (onDoubleLayout) ?
                        <Button variant="secondary" active={(active === 2) ? true : false} disabled={disabled} onClick={disabled ? () => null : (onDoubleLayout) ? () => onDoubleLayout() : () => null}>
                            <i className="fas fa-th-large"></i>
                        </Button> : null
                }
                {
                    (onSingleLayout) ?
                        <Button variant="secondary" active={(active === 1) ? true : false} disabled={disabled} onClick={disabled ? () => null : (onSingleLayout) ? () => onSingleLayout() : () => null}>
                            <i className="fas fa-bars"></i>
                        </Button> : null
                }
            </ButtonGroup>
        </>
    )
}