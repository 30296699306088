import React from 'react';
import GridProvider from '../../molecules/grid-provider';
import Content from '../../organisms/content';
import WatchLayout from './watch-layout';

export interface GridProps {
    children?: JSX.Element | JSX.Element[];
    height?: string;
    loading?: boolean;
    data: any[];
    as: JSX.Element | object;
    exportPDF?: boolean;
    exportExcel?: boolean;
    fetchStatus?: any;
    totalDataCount?: number;
    onRefresh?: () => void;
    layoutType?: 1 | 2 | 3;
    exportFields?: {
        field: string;
        label?: string;
    }[];
    isNested?: boolean;
}

function CardGridComp({ children, height, loading, data, as, exportExcel, exportPDF, fetchStatus, totalDataCount, onRefresh, layoutType, exportFields, isNested }: GridProps) {

    return (
        <>
            <Content.Panel bodyClassName={`p-0`} bgNone={isNested}>
                <GridProvider
                    data={data}
                    height={height}
                    loading={loading}
                    as={as}
                    exportExcel={exportExcel}
                    exportPDF={exportPDF}
                    fetchStatus={fetchStatus}
                    totalDataCount={totalDataCount}
                    onRefresh={onRefresh}
                    layoutType={layoutType}
                    fields={exportFields}
                    disablePaging
                    filterReturnType={'or'}
                    isNested={isNested}
                    exportOptions={false}
                >
                    <>
                        <WatchLayout layoutType={layoutType || 3} />
                        {children}
                    </>
                </GridProvider>
            </Content.Panel>
        </>
    )
}

export default CardGridComp;