import React from "react";
import { TableRow, TableCell, TableCellContent } from '../../../atoms';
import useDataGrid from "../use-data-grid";

interface Props {
  children?: JSX.Element | JSX.Element[];
  rowData?: {
    [key: string]: any;
    ptrui_cacheKey: number;
  };
}
function Row({ children, rowData }: Props) {

  const grid = useDataGrid();
  const isStripped = (rowData) ? (rowData?.ptrui_cacheKey % 2 === 0) ? false : true : false;

  return (
    <>
      {
        (rowData) ?
          <>
            <TableRow
              isStripped={isStripped}
              data={rowData}
            >
              {
                grid.columnFields?.map(
                  ({ field, trebleKey }: { field: string, enabled: boolean, trebleKey: number }) => {
                    const isCustomCell = (children) ? (React.Children.map(children, (child) => { if (child?.props.targetField === field) { return child } }).length > 0) ? true : false : false;
                    const customClassName = (children) ? (React.Children.map(children, (child) => { if (child?.props.targetField === field) { return child } }))[0]?.props.className : '';
                    //data to be rendered in cell, does type checks to make sure data does not break component
                    const cellData: string | number | null = (typeof rowData[field] === 'string' || typeof rowData[field] === 'number') ? rowData[field] : null;

                    return (
                      <React.Fragment key={trebleKey}>
                        <TableCell
                          width={grid.columnWidths.find((item: any) => item.target === field)?.width}
                          className={(isCustomCell) ? customClassName : ''}
                        >
                          <TableCellContent
                            index={trebleKey}
                          >
                            <>
                              {
                                (isCustomCell) ?
                                  React.Children.map(children, (child) => {
                                    if (child?.props.targetField === field) {
                                      return child
                                    }
                                  }) :
                                  <p className={"p-0 m-0"}>
                                    {cellData}
                                  </p>
                              }
                            </>
                          </TableCellContent>
                        </TableCell>
                      </React.Fragment>
                    );
                  }
                )
              }
            </TableRow>
          </> : null
      }
    </>
  );
}
export default React.memo(Row);
