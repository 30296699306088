import React from 'react';
import { BtnCounters } from '../../../atoms';
import useDataGrid from "../use-data-grid";

interface Props {
    className?: string;
}
export default function Counters({ className }: Props) {

    const grid = useDataGrid();

    return (
        <>
            <BtnCounters
                className={className}
                isLoading={grid.isLoading}
                error={grid.fetchStatusCode}
                selectedCount={(grid.isOnRowSelect) ? grid.selectedRows.length : undefined}
                filterCount={(grid.tableData?.length) ? grid.tableData?.length : undefined}
                totalCount={(typeof grid.totalDataCount === 'number') ? grid.totalDataCount : (grid.tableData?.length) ? grid.tableData?.length : 0}
            />
        </>
    )
}