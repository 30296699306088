import React from 'react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import styles from './btnRemove.module.scss';
import { useI18N } from '../../../hooks';

interface Props {
    iconOnly?: boolean;
    disabled?: boolean;
    className?: string;
    size?: 'sm' | 'lg';
    label?: string;
    hideCounter?: boolean;
    itemsToRemove?: { ptrui_cacheKey: number, [key: string]: any }[];
    onClick?: (items?: { ptrui_cacheKey: number, [key: string]: any }[]) => void;
}
export default function BtnRemove({ iconOnly, disabled, className, size, label, hideCounter, itemsToRemove, onClick }: Props) {
    const i18n = useI18N();
    const onClickFN = () => (disabled) ? null : (onClick) ? onClick(itemsToRemove) : null;

    return (
        <>
            {
                (iconOnly) ?
                    <i onClick={onClickFN} className={`fas fa-trash-alt pr-3 text-danger ${(disabled) ? '' : 'cursor'} ${styles.iconOnly} ${className}`} style={{ opacity: (disabled) ? .50 : 1 }}></i> :
                    <Button
                        size={(size === 'sm') ? 'sm' : (size === 'lg') ? 'lg' : undefined}
                        variant={'danger'}
                        className={className}
                        onClick={onClickFN}
                        disabled={disabled}
                    >
                        <>
                            <span className={'pr-2'}>{<i className="fas fa-trash-alt text-white"></i>}</span>
                            <span className={'pr-1'}>{(label) ? label : i18n.translateThis('Remove Item', i18n.localize.btnRemove.removeItem)}</span>
                        </>
                        {
                            (!hideCounter) ?
                                <Badge variant="light">{itemsToRemove?.length || 0}</Badge> : null
                        }

                    </Button>
            }
        </>
    )
}