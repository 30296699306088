/*
  Alert Menu component
*/

import React, { useEffect } from "react";
import AlertCard from "./alert-card";
import { useAppStore } from "../../../_Store";
import { IAlertMenu } from "./interfaces";
import styles from './alertMenu.module.scss';
import { useI18N } from '../../../hooks';

export default function AlertMenu({ dataSet }: IAlertMenu) {
  const i18n = useI18N();
  //Store Items
  const [{ ptrui_alertMenuState, ptrui_alertMenuData }, Store, Util] = useAppStore();

  //update alertMenuData to App Store
  useEffect(() => {
    Store.update(Util.actions.ptrui_updateAlertMenuData, dataSet);
  }, [dataSet]);

  return (
    <>
      <div
        className={`${styles.alertMenu} ${ptrui_alertMenuState === false ? styles.closeMenu : styles.openMenu
          }`}
      >
        <div className={`${styles.listGroup} list-group list-group-flush`}>
          <div>
            <div className={`list-group-item list-group-item-action d-flex justify-content-between ${styles.listGroupItem} `}>
              <i className="fas fa-bell pl-3"></i>
              <span>{i18n.translateThis('ALERTS', i18n.localize.alertMenu.alerts)}</span>
              <i
                className="fas fa-times pr-3 cursor"
                onClick={() => {
                  Store.update(Util.actions.ptrui_updateAlertMenuState, false);
                }}
              ></i>
            </div>
            <div className={`${styles.cardContainer} p-3`}>
              {ptrui_alertMenuData?.map((card: any) => {
                return (
                  <AlertCard
                    key={card.alertID}
                    shipmentID={card.shipmentID}
                    type={card.type}
                    message={card.message}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
