import React from 'react';
import { useAppStore } from '../_Store';
import { useNonInitialEffect } from './use-non-initial-mount-effect';
import useInAppMessage from './use-in-app-message';
import * as locale_en from '../_localize/en';
const locale_es = () => import('../_localize/es');

//setTimeout(() => (message.createLoading('Setting language to English.')), 0);
//setTimeout(() => (message.createLoading('Setting language to Spanish.')), 0);

//i18n hook that contains all i18n related methods (ptrui library use only, not exposed to downstream development)
const useI18N = () => {

    const [{ ptrui_localeKey, ptrui_localize, ptrui_externali18nKeys }, Store, Util] = useAppStore();
    const [localize, setLocalize] = React.useState(ptrui_localize);
    const message = useInAppMessage();
    const localeKey = ptrui_localeKey || 'en';
    const setLocaleKey = (key: 'en' | 'es') => {
        if (key) {
            Store.update(Util.actions.ptrui_setLocaleKey, key);
        }
    }
    const fetchLocalizeData = (key: 'en' | 'es') => {

        if (key === 'en') {
            setTimeout(async () => {
                if (locale_en.default) {
                    Store.update(Util.actions.ptrui_setLocalize, locale_en.default);
                }
                //message.createSuccess('Language set to English');
            }, 0);
        }
        if (key === 'es') {
            setTimeout(async () => {
                const data = await locale_es();
                if (data) {
                    Store.update(Util.actions.ptrui_setLocalize, data);
                }
                //message.createSuccess('Language set to Spanish');
            }, 0);
        }
    }
    const translateThis = (text: string, i18n?: string) => {
        if (i18n) {
            if (i18n) {
                return i18n;
            }
            return text;
        }
        return text;
    }
    const translateExt = (key: string, keyWord: string) => {
        return ptrui_externali18nKeys ? ptrui_externali18nKeys["default"][key][keyWord] ?? 'No Set' : 'Not Set';
    }
    useNonInitialEffect(() => {
        setLocalize(ptrui_localize);
    }, [ptrui_localize]);

    // const autoDetectLanguage = () => {
    //     if (navigator.language === "en-US" || navigator.language === 'en') {
    //         toEnglish();
    //     }
    //     else if (navigator.language === 'es') {
    //         toSpanish();
    //     }
    // }

    return {
        localeKey,
        setLocaleKey,
        localize,
        fetchLocalizeData,
        translateThis,
        translateExt
    }

}

export default useI18N;