import { GridProvider } from '../../molecules';

//hook used internally to update Grid Store
const useDataGrid = () => GridProvider.useStore();

//hook that can be used externally by downstream devs
const useGrid = () => {
    const {
        deleteProcess,
        selectRow,
        selectedRows,
        updateTableData,
        updateCachedTableData,
        resetGridPosition,
        cachedTableData,
        tableData,
        isLoading,
        searchQueries: filterQueries,
        totalDataCount,
        onRefresh: refetchData,
        updateDataCount: setTotalDataCount
    } = useDataGrid();


    return {
        deleteProcess,
        selectRow,
        selectedRows,
        updateTableData,
        updateCachedTableData,
        resetGridPosition,
        cachedTableData,
        tableData,
        isLoading,
        filterQueries,
        totalDataCount,
        refetchData,
        setTotalDataCount
    }
}

export { useGrid };
export default useDataGrid;