import React from 'react';
import useDataGrid from '../use-data-grid';

const useColumnSelectFilter = (field: string, onChange: (value?: string) => void, options: { value?: string | number, text: string }[]) => {

    const grid = useDataGrid();

    const [activeOption, setActiveOption] = React.useState({ value: '', text: 'All' });

    const listItems = React.useMemo(() => [{ value: '', text: 'All' }, ...options], []);

    //saves value when option is selected
    const onSelect = (option?: { value: string | number, text: string }) => {

        if (option) {

            const queries = grid.searchQueries.map((item) => {
                if (item.target === field) {
                    return {
                        target: field,
                        query: (option?.value as string).toLowerCase(),
                        exact: true,
                        type: "string"
                    };
                }
                return item;
            });
            if (onChange) {
                onChange(option?.value.toString());
            }
            setActiveOption({ text: option?.text, value: option?.value.toString() });
            grid.updateSearchQueries(queries as any);
        }
    }

    //resets select input when filters are reset
    const onReset = () => {
        if (activeOption.value.length > 0) {
            const currentQuery = grid.searchQueries.find((item) => item.target === field)?.query;
            if (typeof currentQuery === 'string') {
                if (currentQuery.length === 0) {
                    setActiveOption({ value: '', text: 'All' });
                    if (onChange) {
                        onChange('');
                    }
                }
            }
        }
    }

    return {
        activeOption,
        listItems,
        onSelect,
        onReset
    }

}

export default useColumnSelectFilter;