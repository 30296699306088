import React from 'react';
import { RefreshData as RefreshDataMol } from '../../../molecules';
import useDataGrid from '../use-data-grid';
import { useResponsive } from '../../../../hooks';

interface Props {
    iconOnly?: boolean;
    className?: string;
    enableMobile?: boolean;
}

export default function RefreshData({ iconOnly, className, enableMobile }: Props) {

    const { deleteProcess, ...grid } = useDataGrid();
    const res = useResponsive();
    const isDisabled = (grid.isLoading) ? true : (deleteProcess.isDeleting) ? true : false;
    const isIconOnly = (iconOnly) ? true : (!res.lgDown && enableMobile) ? true : false;

    return (
        <>
            <RefreshDataMol
                small
                onClick={() => (grid.onRefresh) ? grid.onRefresh() : null}
                className={className}
                iconOnly={isIconOnly}
                loading={grid.isLoading}
                error={grid.fetchStatusCode}
                disabled={isDisabled}
            />
        </>
    )
}