import React from 'react';
import EditColumn from './gallery/edit-column';
import EditGroup from './gallery/edit-group';
import EditForm from './gallery/edit-form';
import IconComp from './icon-provider';
import { IIcon } from './interfaces';
import Save from './gallery/save';
import SingleSetting from './gallery/single-setting';
import New from './gallery/new';
import MultipleSetting from './gallery/multiple-setting';
import Collapse from './gallery/collapse';
import Uncollapse from './gallery/uncollapse';
import Close from './gallery/close';
import Tools from './gallery/tools';
import Search from './gallery/search';
import Roles from './gallery/roles';
import Database from './gallery/database';
import Shipping from './gallery/shipping';
import DropDownShow from './gallery/dropdownshow';
import DropDownHide from './gallery/dropdownhide';
import Upload from './gallery/upload';
import ErrorLate from './gallery/error-late';
import ErrorAttention from './gallery/error-attention';
import SuccessAttention from './gallery/success-attention';
import ExportExcel from './gallery/export-excel';
import Rate from './gallery/rate';
import SadFace from './gallery/sad-face';
import Count from './gallery/count';
import Filter from './gallery/filter';
import FilterReset from './gallery/filter-reset';
import Undo from './gallery/undo';
import Hazardous from './gallery/hazardous';
import Hazmat from './gallery/hazmat';
import optimizLogo from '../../../_assets/images/logo-optimiz-colored.svg';
import protransLogo from '../../../_assets/images/logo-protrans-colored.svg';
import protransWhiteLogo from '../../../_assets/images/logo-protrans-white.svg';

const Icon: IIcon.IIconContainer = (props) => (<IconComp {...props} />);
Icon.EditColumn = EditColumn;
Icon.EditGroup = EditGroup;
Icon.EditForm = EditForm;
Icon.Save = Save;
Icon.New = New;
Icon.SingleSetting = SingleSetting;
Icon.MultipleSetting = MultipleSetting;
Icon.Collapse = Collapse;
Icon.Uncollapse = Uncollapse;
Icon.Close = Close;
Icon.Tools = Tools;
Icon.Search = Search;
Icon.Roles = Roles;
Icon.Database = Database;
Icon.Shipping = Shipping;
Icon.DropDownShow = DropDownShow;
Icon.DropDownHide = DropDownHide;
Icon.Upload = Upload;
Icon.ErrorAttention = ErrorAttention;
Icon.ErrorLate = ErrorLate;
Icon.SuccessAttention = SuccessAttention;
Icon.ExportExcel = ExportExcel;
Icon.Rate = Rate;
Icon.SadFace = SadFace;
Icon.Count = Count;
Icon.Filter = Filter;
Icon.FilterReset = FilterReset;
Icon.Undo = Undo;
Icon.Hazardous = Hazardous;
Icon.Hazmat = Hazmat;
Icon.OptimizLogo = optimizLogo;
Icon.ProtransLogo = protransLogo;
Icon.ProtransWhiteLogo = protransWhiteLogo;

export default Icon;