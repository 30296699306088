import React from 'react';
import { ModalBasic } from '../../molecules';
import { useLanguageModal, useI18N } from '../../../hooks';

export default function LanguageModal() {
    const i18n = useI18N();
    const modal = useLanguageModal();

    return (
        <>
            <ModalBasic
                animation={true}
                centered={false}
                show={modal.isOpen}
                onHide={() => modal.close()}
                size={'md'}
                titleIcon={<i className={`far fa-language`}></i>}
                title={i18n.translateThis('Change Language', i18n.localize.languageModal.title)}
            >
                <ModalBasic.Body>
                    {modal.renderForm}
                </ModalBasic.Body>

            </ModalBasic>
        </>
    )
}