import React from 'react';
import { useResize, useNonInitialLayoutEffect, useComp } from '../../../hooks';
import { useGridProviderStore } from './_Store';

export interface ISearchQuery {
    target: string;
    query: string | number | {
        from?: string | undefined;
        to?: string | undefined;
    } | null;
    exact: boolean;
    type: 'string' | 'number' | 'dateRange';
    filterType?: 'alpha' | 'search';
    ptrui_cacheKey?: string;
}

const useStore = () => {

    const [State, Store, Util] = useGridProviderStore();
    const { grid: gridComp } = useComp();

    //#region GRID REFS

    const tableRef = State.gridTableRef;
    const bodyRef = State.gridTBodyRef;
    const columnRef = State.columnSortState;
    const loadMoreRef = State.loadMoreDataRef;
    const dataGridRef = State.gridRef;

    //saves Grid comp ref to Store
    const setRef = (gridRef: React.MutableRefObject<null>) => {
        Store.update(Util.actions.updateGridRef, gridRef);
    }

    //saves table ref to Store
    const setTableRef = (ref: React.MutableRefObject<any>) => {
        Store.update(Util.actions.updateGridTableRef, ref);
    }

    //saves body ref to store
    const setTableBodyRef = (ref: React.MutableRefObject<any>) => {
        Store.update(Util.actions.updateGridTBodyRef, ref);
    }

    //saves table header ref to store
    const setTheadRef = (ref: React.MutableRefObject<any>) => {
        Store.update(Util.actions.updateTheadRef, ref);
    }

    //saves column ref to store
    const setColumnRef = (ref: React.MutableRefObject<null> | boolean) => {
        Store.update("changeColumnSortState", ref);
    }

    //saves load more ref to store
    const setLoadMoreRef = (ref: React.MutableRefObject<any>) => {
        Store.update(Util.actions.updateLoadMoreDataRef, ref);
    }
    //#endregion GRID REFS

    //#region UI CONFIG

    const id = State.gridId;
    const height = State.gridHeight;
    const splashHeight = (parseInt(height) - 50);
    const { width } = useResize(dataGridRef);
    const activePage = State.activeGridPage;
    const fetchStatusCode = State.fetchStatusCode;
    const isLoading = State.loadingState;
    const isStickyColumns = State.stickyColumns;
    const isColumnTitles = State.columnTitleState;
    const isColumnSort = State.isColumnSort;
    const isColumnSelector = State.footerColumnSelector;
    const totalDataCount = State.totalDataCount;
    const ExpandableRowComp = State.ExpandableRow;
    const CustomRowComp = State.CustomRow;
    const customRowProps = State.customRowProps;
    const exports = State.exports;
    const pagingOptions = State.gridPagingOptions;
    const isClientRenderDisabled = State.isClientRenderDisabled;
    const isPageEnd = State.isPageEnd;
    const shouldCachedDataRefresh = State.shouldCachedDataRefresh;
    const onRefreshProp = State.onRefresh;
    const customItemProps = State.customItemProps;
    const pageCount = State.pageCount;
    const layoutType = State.colLayout;
    const isDeleting = State.isDeleting;
    const deleteSuccess = State.deleteSuccess;

    //sets grid component id
    const setId = (id?: number | string) => {
        if (id !== undefined) {
            Store.update(Util.actions.setGridId, id);
        }
    }

    //sets whether or not delete succeeds
    const setDeleteSuccess = (success?: boolean | null) => {
        if (success !== undefined) {
            Store.update(Util.actions.setDeleteSuccess, success);
        }
    }

    //sets whether or not the UI is deleting something
    const setIsDeleting = (shouldDelete?: boolean) => {
        if (shouldDelete !== undefined) {
            Store.update(Util.actions.setIsDeleting, shouldDelete);
        }
    }

    //sets layout type for items. (used for cards)
    const setLayoutType = (type?: 1 | 2 | 3) => {
        if (type !== undefined) {
            Store.update(Util.actions.setColLayout, type);
        }
    }

    //sets grid page count
    const setPageCount = (count?: number) => {
        if (count !== undefined) {
            Store.update(Util.actions.updatePageCount, count);
        }
    }
    const isNestedComp = State.isNestedComp;
    const isOverflow = State.isOverflow;

    //sets if grid has overflow
    const setIsOverflow = (isOverflow?: boolean) => {
        if (isOverflow !== undefined) {
            Store.update(Util.actions.setIsOverflow, (isOverflow) ? true : false);
        }
    }

    //sets whether or not a grid is nested (need this to tweak sticky column styles)
    const setIsNestedComp = (isNested?: boolean) => {
        Store.update(Util.actions.setIsNestedComp, (isNested) ? true : false);
    }

    //sets custom item props. can be accessed by hook
    const setCustomItemProps = (props?: { [key: string]: any }) => {
        if (props) {
            Store.update(Util.actions.setCustomItemProps, props);
        }
    }

    //sets whether or not we are at the end of the last page
    const setIsOnPageEnd = (isPageEnd?: boolean) => {
        Store.update(Util.actions.updateIsPageEnd, isPageEnd);
    }

    //sets onRefresh function
    const setOnRefresh = (fn?: () => void) => {
        if (fn) {
            Store.update(Util.actions.setOnRefresh, fn);
        }
    }

    //refetch data
    const onRefresh = () => {
        if (onRefreshProp) {
            setActiveColumnSort('', 'none');
            resetGridPosition();
            resetSearchQueries();
            onRefreshProp();
        }
    }

    //sets whether or not cached data refreshes or appends to current data (used for fetching more data while infinite scrolling)
    const setShouldCachedDataRefresh = (shouldRefresh: boolean) => {
        Store.update(Util.actions.updateShouldCachedDataRefresh, shouldRefresh)
    }



    //sets whether client rendering is disabled
    const disableClientRendering = () => {
        Store.update(Util.actions.setIsClientRenderDisabled, true);
    }

    //sets grid fetch status
    const setFetchStatus = (fetchStatus: any) => {
        if (fetchStatus !== undefined) {
            if (fetchStatus === null) {
                Store.update(Util.actions.updateFetchStatusCode, 'No Errors Detected');
            }
            else {
                Store.update(Util.actions.updateFetchStatusCode, fetchStatus);
            }
        }
    }

    //sets grid loading status
    const setLoading = (loading?: boolean) => {
        if (loading !== undefined) {
            Store.update(Util.actions.updateLoadingState, loading);

            //sets default fetch status while loading
            if (loading) {
                Store.update(Util.actions.updateFetchStatusCode, 'No Errors Detected');
            }
        }
    }

    //hides table header
    const disableTableHeaders = (columnTitles?: boolean) => {
        if (!columnTitles && columnTitles !== undefined) {
            Store.update(Util.actions.setColumnTitleState, columnTitles);

            //also sets sticky column to false to prevent weird overlap of nested tables
            Store.update(Util.actions.setStickyColumnState, false);
        }
    }

    //checks if row is expandable
    const isRowExpandable = State.expandableCheck;

    //used in the row component to determine if row is active
    const isRowSelected = (rowData: { ptrui_cacheKey: number, [key: string]: any }) => {
        return (selectedRows.find((row) => row.ptrui_cacheKey === rowData?.ptrui_cacheKey)) ? true : false;
    }

    //allows for sticky columns
    const enableStickyColumns = (isEnabled?: boolean) => {
        if (isEnabled !== undefined && typeof isEnabled === 'boolean') {
            Store.update(Util.actions.setStickyColumnState, isEnabled)
        }
    }
    //creates custom rows
    const setCustomRow = (as?: object | JSX.Element) => {
        if (as !== undefined) {
            Store.update(Util.actions.createCustomRow, as);
        }
    }

    //sets row props to be used by custom row component
    const updateRowProps = (props?: any) => {
        if (props) {
            Store.update(Util.actions.updateCustomRowProps, props);
        }
    }

    //sets up expandable rows
    const setExpandableRows = (expandable?: boolean | object | JSX.Element, checkLogic?: ((item: any) => boolean)) => {
        if (expandable) {
            Store.update(Util.actions.createExpandableRow, expandable);
        }
        if (checkLogic) {
            Store.update(Util.actions.setExpandableCheck, checkLogic);
        }
    }

    //enables column selector icon in grid footer
    const enableColumnSelector = (isEnabled?: boolean) => {
        if (isEnabled) {
            Store.update(Util.actions.showFooterColumnSelector, true)
        }
    }

    //enables and sets export options in footer
    const setExportOptions = (enablePDF?: boolean, enableExcel?: boolean, customOptions?: {
        type: "excel" | "pdf";
        label: string;
        onClick: () => void;
    }[]) => {
        Store.update(Util.actions.setExports, {
            pdf: enablePDF,
            excel: enableExcel,
            options: undefined,
            customOptions: customOptions
        });
    }

    //saves grid pagination options to Store
    const setPagingOptions = (pageSize?: number, pageGroupSize?: number) => {
        Store.update(Util.actions.updateGridPagingOptions, {
            page: true,
            size: pageSize || 15,
            groupSize: pageGroupSize,
        });
    }
    //Saves Grid height to Store
    const setHeight = (height?: string) => {
        Store.update(Util.actions.updateGridHeight, height || '400px');
    }

    //Creates a fallback height and saves to Store
    const setFallbackHeight = (height?: string) => {
        Store.update(Util.actions.calculateFallBackGridHeight, height);
    }

    //sets active grid page number
    const setActivePage = (page?: number) => {
        if (page !== undefined) {
            Store.update(Util.actions.updateActiveGridPage, page);
        }
    }

    //visually resets scroll position of table body vertical scroll bar
    const resetScrollBar = () => {
        bodyRef.current.scrollTop = 0;
    }

    //resets active pagination and UI to 0
    const resetGridPosition = () => {
        if (!isLoading) {
            resetScrollBar();
            setActivePage(0);
        }
    }

    //updates footer total data count
    const updateDataCount = (count?: number) => {
        if (count !== undefined) {
            Store.update(Util.actions.updateTotalDataCount, count);
        }
    }

    //updates page end state (lets component know when the last table data page has been reached)
    const updateIsPageEnd = (isPageEnd: boolean) => {
        Store.update(Util.actions.updateIsPageEnd, isPageEnd);
    }

    //#endregion UI CONFIG

    //#region COLUMNS

    const activeColumnSort = State.activeColumnSort;
    const activeColumnFilters = State.activeColumnFilters;
    const disableColumnFilters = State.disableColumnFilters;
    const columnFields = State.columnFields;
    const columnWidths = State.columnWidths;
    const filterDebounceTime = State.filterDebounceTime;

    //saves column field object to store. This appends to state array does not overwrite.
    const addColumnField = ({ field, title, width, enabled, dataType }: { field: string, title: string | JSX.Element, width: string, enabled: boolean, dataType?: 'string' | 'number' | 'boolean' }) => {
        if (field) {
            Store.append("createColumnFields", {
                field: field,
                title: title,
                enabled: enabled,
                dataType: dataType
            });
            Store.append("setColumnWidths", {
                target: field,
                width: width ? width : "auto"
            });
        }
    }

    //edits a current columnField data object
    const editColumnField = (targetField: string, props: { [key: string]: any }) => {
        const thisColumn = columnFields.find((item) => item.field === targetField);
        Store.edit("createColumnFields", { ...thisColumn, ...props });
    }

    //updates all column fields
    const updateColumnFields = (fields: { field: string | boolean, title: string | JSX.Element, isHidden?: boolean }[]) => {
        Store.update(Util.actions.createColumnFields, fields);
    }

    //saves active column filters to Store
    const updateActiveColumnFilters = (activeFilters: { field: string, type: 'select' | 'search' }[]) => {
        Store.update(Util.actions.updateActiveColumnFilters, activeFilters);
    }

    //appends active column filter to Store
    const addActiveColumnFilter = (activeFilter: { field: string, type: 'select' | 'search' }) => {
        Store.append(Util.actions.updateActiveColumnFilters, activeFilter);
    }

    //removes active column filter from Store
    const removeActiveColumnFilter = (activeFilter: { field: string, type: 'select' | 'search' }) => {
        Store.remove(Util.actions.updateActiveColumnFilters, activeFilter);
    }

    //sets rather or not a column sort exists
    const setIsColumnSort = (isSort: boolean) => {
        Store.update(Util.actions.setIsColumnSort, isSort)
    }

    //allows for manuel filter search debounce time to be set for column filters
    const setFilterDebounce = (time?: number) => {
        if (time) {
            Store.update(Util.actions.setFilterDebounceTime, time);
        }
    }

    //saves current active columns
    const setActiveColumnSort = (field: string, type: 'asc' | 'desc' | 'none') => {
        Store.update(Util.actions.setActiveColumnSort, {
            field: field,
            type: type
        });
    }

    //disable column fields
    const setDisableColumnFields = (isDisabled: boolean) => {
        Store.update(Util.actions.updateDisableColumnFilters, isDisabled);
    }

    //resets active column sort
    const resetActiveColumnSort = () => {
        Store.update(Util.actions.setActiveColumnSort, {
            field: '',
            type: 'none'
        })
    }

    const areAllColumnsDisabled = () => {
        const enabledColumns = columnFields.find((column) => column.enabled === true);
        if (enabledColumns) {
            return false
        }
        return true
    }

    //#endregion COLUMNS

    //#region DATA
    const cachedTableData = State.cachedTableData;
    const tableData = State.tableData;
    const fields = State.fields;

    //sets field props that can be used by components
    const setFields = (fields?: { field: string; label?: string; }[]) => {
        if (fields) {
            Store.update(Util.actions.setFields, fields);
        }
    }

    //saves a cache of fetched data to Store (cached table data only updates when grid fetches data)
    const updateCachedTableData = (data?: any[]) => {
        if (data) {
            Store.update(Util.actions.updateCachedTableData, data);
        }
    }

    //appends data to cached Table data (used to fetch more data for infinite scrolling)
    const appendCachedTableData = (data?: any[]) => {
        if (data) {
            Store.update(Util.actions.updateCachedTableData, [...cachedTableData, ...data]);
        }
    }

    //updates rendered table data (paging, filters, and sort are applied to this).
    const updateTableData = (data?: any[]) => {
        if (data) {
            if (!(data.length === 0 && tableData.length === 0)) {
                Store.update(Util.actions.updateTableData, data);
            }
        }
    }

    //applies filter queries and then updates table data
    //optional filterType prop allows to return results if "both" queries have match "or" if at least one does. defaults to 'both'
    const filterTableData = (filterType?: 'both' | 'or') => {
        const doQueriesExist = searchQueries.some((obj) => {
            if (typeof obj.query === 'string') {
                if (obj.query.length > 0) {
                    return true;
                }
            }
        });
        //makes sure queries exist
        if (doQueriesExist) {

            //removes certain searchQuery types that are used for certain types of filtering (ex: Alpha Filter)
            const cleanedSearchQueries = searchQueries.filter((query) => query?.filterType !== 'alpha');

            //alpha type filter query
            const alphaQuery = searchQueries.find((item) => item.filterType === 'alpha');

            //pre filters data based on an alpha filter
            const preFilteredTableData = (alphaQuery?.target) ? cachedTableData.filter((item) => item[alphaQuery?.target]?.startsWith(alphaQuery?.query)) : cachedTableData;

            const filteredData = preFilteredTableData.filter((item) => {
                const comparedValues: boolean[] = cleanedSearchQueries.map((queryData) => {
                    const query = (typeof queryData.query === 'string') ? queryData.query?.toLowerCase() : '';
                    const value = (item[queryData?.target]) ? item[queryData?.target]?.toString().toLowerCase() : '';
                    if (query.length === 0) {
                        return true;
                    }
                    else if (value?.startsWith(query)) {
                        return true;
                    }
                    return false;
                });
                const doesItemPass = (comparedValues: boolean[]) => {
                    if (filterType === undefined || filterType === 'both') {
                        return (comparedValues.includes(false)) ? false : true;
                    }
                    else if (filterType === 'or') {
                        return (comparedValues.includes(true)) ? true : false;
                    }
                }
                if (doesItemPass(comparedValues)) {
                    return item;
                }
            });
            //update data with filtered data
            updateTableData(filteredData);
        }
        //if queries do not exist reset data
        else {
            updateTableData(cachedTableData);
        }
    }

    //sorts and then updates table data
    const sortTableData = (field: string | boolean, type: "desc" | "asc" | "none") => {

        const activefield = (typeof field === 'string') ? field : '';

        const compareFN = (a: any, b: any) => {
            if (type === 'asc') {
                return (a[activefield] < b[activefield]) ? 1 : -1
            }
            if (type === 'desc') {
                return (a[activefield] > b[activefield]) ? 1 : -1
            }
            return (a[activefield] < b[activefield]) ? 1 : -1
        }

        const sortedData = (typeof activefield === 'string' && type !== 'none') ?
            [...tableData].sort((a, b) => compareFN(a, b)) : [];

        updateTableData((sortedData.length > 0) ? sortedData : cachedTableData);
        setActivePage(0);
    }

    //resets table data to cached table data
    const showAllRows = () => {
        updateTableData(cachedTableData);
    }

    //adds a row to cached table data (adds to top)
    const prependRow = (data: { [key: string]: any }) => {
        if (data) {
            updateCachedTableData([data, ...cachedTableData]);
        }
    }

    //adds a row to cached table data (adds to bottom)
    const appendRow = (data: { [key: string]: any }) => {
        if (data) {
            updateCachedTableData([...cachedTableData, data]);
        }
    }

    //remove cached table data
    const removeCachedTableData = (rowsToBeRemoved: { ptrui_cacheKey: number, [key: string]: any }[]) => {
        const keys = rowsToBeRemoved.map((row) => {
            return row.ptrui_cacheKey;
        });
        const editedData = cachedTableData.filter((row) => {
            if (!(keys.includes(row.ptrui_cacheKey))) {
                return row;
            }
        });
        updateCachedTableData(editedData);
    }

    //update By PtruiKey
    const updateByPtruiKey = (ptruiKey: string | number, newData: any) => {
        const indexRowToBeUpdated = cachedTableData.findIndex(x=> x.ptrui_cacheKey == ptruiKey)
        if(indexRowToBeUpdated >= 0){
            let _cachedTableData = [...cachedTableData];
            _cachedTableData[indexRowToBeUpdated] = newData;
            updateCachedTableData(_cachedTableData);
        }
    }

    //remove By PtruiKey
    const removeByPtruiKey = (ptruiKey: string | number) => {
        const indexRowToBeUpdated = cachedTableData.findIndex(x=> x.ptrui_cacheKey == ptruiKey)
        if(indexRowToBeUpdated >= 0){
            let _cachedTableData = [...cachedTableData];
            _cachedTableData.splice(indexRowToBeUpdated,1);
            updateCachedTableData(_cachedTableData);
        }
    }

    //update By Predicate
    const updateByPredicate = (predicate: (element : any) => boolean, newData: any) => {
        const rowToBeUpdated = cachedTableData.filter(predicate);
        if(rowToBeUpdated.length === 1){
            let _cachedTableData = [...cachedTableData];
            const indexRowToBeUpdated = _cachedTableData.findIndex(predicate);
            _cachedTableData[indexRowToBeUpdated] = newData;
            updateCachedTableData(_cachedTableData);
        }
    }

    //remove By Predicate
    const removeByPredicate = (predicate: (element : any) => boolean) => {
        const rowToBeUpdated = cachedTableData.filter(predicate);
        if(rowToBeUpdated.length === 1){
            let _cachedTableData = [...cachedTableData];
            const indexRowToBeUpdated = _cachedTableData.findIndex(predicate);
            _cachedTableData.splice(indexRowToBeUpdated, 1);
            updateCachedTableData(_cachedTableData);
        }
    }
    //#endregion 

    //#region FILTER Queries

    const searchQueries = State.searchQueries;

    //updates queries
    const updateSearchQueries = (queries: ISearchQuery[]) => {
        // const processedQueries = queries.map((item, index) => ({ ...item, ptrui_cacheKey: index }));
        Store.update(Util.actions.updateSearchQueries, queries);
    }

    //resets queries (Might not being used)
    const resetSearchQueries = () => {
        const resetQueries = searchQueries.map((query) => ({ ...query, query: '' }));
        updateSearchQueries(resetQueries);
    }

    //appends query object
    const addSearchQuery = (query: ISearchQuery) => {
        Store.append("updateSearchQueries", query);
    }
    //#endregion

    //#region ON SELECT

    const isOnRowSelect = State.isOnRowSelect;
    const selectedRows = State.selectedRows;

    //enables on row select in Grid
    const setIsOnRowSelect = (isOnSelect?: boolean) => {
        Store.update(Util.actions.setIsOnRowSelect, (isOnSelect) ? true : false);
    }

    //updates selected rows in Store
    const updateSelectedRows = (data: { [key: string]: any }[]) => {
        Store.update(Util.actions.setSelectedRows, data);
    }

    //appends row to selected rows state
    const addSelectedRow = (rowData: { [key: string]: any }) => {
        Store.append(Util.actions.setSelectedRows, rowData);
    }

    //removes row from selected rows state
    const removeSelectedRow = (rowData: { [key: string]: any }) => {
        updateSelectedRows(selectedRows.filter((row) => (row.ptrui_cacheKey !== rowData.ptrui_cacheKey)));
    }

    //adds single row to Store
    const selectRow = (rowData: { [key: string]: any }) => {
        updateSelectedRows([rowData]);
    }

    //deselect all rows
    const unSelectAllRows = () => {
        updateSelectedRows([]);
    }

    //select all rows
    const selectAllRows = () => {
        const editedTableData = tableData.map((row) => ({ ...row, ptrui_isSelected: true }));
        updateSelectedRows(editedTableData);
    }

    //filters data and renders only ones that are selected
    const showSelectedRowsOnly = () => {
        resetGridPosition();
        updateTableData(selectedRows);
    }

    //saves selected rows to PTRUI Store for persistence
    const updatePersistedSelectedRows = () => {
        gridComp.updateSelectedItems(selectedRows, id);
    }
    //#endregion

    //#region DELETE PROCESS
    const [success, setSuccess] = React.useState(false);
    const [failed, setFailed] = React.useState(false);

    //used to control the delete process flow
    const deleteProcess = {
        isDeleting: isDeleting,
        success: success,
        failed: failed,
        run: (itemsToRemove: { ptrui_cacheKey: number, [key: string]: any }[] | { ptrui_cacheKey: number, [key: string]: any }, onRemoveFN?: (selectedRows: { ptrui_cacheKey: number, [key: string]: any }[]) => boolean) => {
            setFailed(false);
            setSuccess(false);
            setDeleteSuccess(false);
            setIsDeleting(true);
            if (onRemoveFN) {
                setTimeout(async () => {
                    setFailed(false);
                    setSuccess(false);
                    setDeleteSuccess(false);
                    setIsDeleting(true);
                    const items = (Array.isArray(itemsToRemove)) ? itemsToRemove : [itemsToRemove];
                    const isSuccess = await new Promise((resolve) => resolve(onRemoveFN(items)));
                    if (isSuccess) {
                        setSuccess(true);
                    } else {
                        setFailed(true);
                    }
                }, 0);
            }
            return true;
        }
    }

    //sets store delete state
    useNonInitialLayoutEffect(() => {
        if (deleteProcess.success) {
            setTimeout(() => setDeleteSuccess(true), 0);
        }
        else if (deleteProcess.failed) {
            setTimeout(() => {
                setIsDeleting(false);
                setDeleteSuccess(false);
            }, 0);
        }
    }, [deleteProcess.success, deleteProcess.failed]);

    //#endregion

    //#region SCROLLING

    const onCustomScrollBottom = State.onBottomScroll;

    //sets custom onBottomScroll function
    const setCustomScrollBottom = (fn?: (meta: { activePage: number, isPageEnd: boolean }) => void) => {
        if (fn) {
            Store.update(Util.actions.setOnBottomScroll, fn);
        }
    }

    //scroll to row after fetch (test)
    const scrollToIdAfterFetch = (lastRowIndex: number, itemId?: string) => {
        const id = `ptrui_${(itemId) ? (itemId === 'table') ? 'row' : itemId : 'item'}_${lastRowIndex - 1}`;
        const element = document.getElementById(id);
        setTimeout(() => {
            element?.scrollIntoView({ behavior: 'auto', block: 'end' });
        }, 0);
    }


    //fires scroll to when scrollbar reaches top
    const onScrollTop = (lastRowIndex: number, itemId?: string) => {
        const id = `ptrui_${(itemId) ? (itemId === 'table') ? 'row' : itemId : 'item'}_${lastRowIndex + 2}`;
        const element = document.getElementById(id);
        const prevPage = activePage - 1;
        setActivePage(prevPage);
        setTimeout(() => {
            element?.scrollIntoView();
        }, 0);
    }

    //fires scroll to when scrollbar reaches bottom
    const onScrollBottom = (lastRowIndex: number, itemId?: string) => {
        const hasNotReachedTotalData = cachedTableData.length < totalDataCount;
        const id = `ptrui_${(itemId) ? (itemId === 'table') ? 'row' : itemId : 'item'}_${lastRowIndex - 2}`;
        const element = document.getElementById(id);
        const goToNextPage = () => {
            const nextPage = (activePage === 0) ? activePage + 2 : activePage + 1;
            setActivePage(nextPage);
            setTimeout(() => {
                element?.scrollIntoView();
            }, 0);
        }
        const fetchMoreData = () => {
            setShouldCachedDataRefresh(false);
            onCustomScrollBottom({
                activePage: activePage + 1,
                isPageEnd: true
            });
        }
        //console.log(isPageEnd);
        if (isClientRenderDisabled) {
            if (isPageEnd) {
                if (hasNotReachedTotalData) {
                    fetchMoreData();
                }
            } else {
                goToNextPage();
            }
        } else {
            if (!isPageEnd) {
                goToNextPage();
            }
        }
    }

    //#endregion

    return {
        id,
        isClientRenderDisabled,
        shouldCachedDataRefresh,
        dataGridRef,
        loadMoreRef,
        height,
        splashHeight,
        width,
        cachedTableData,
        tableData,
        isLoading,
        bodyRef,
        activeColumnSort,
        searchQueries,
        totalDataCount,
        activePage,
        ExpandableRowComp,
        isStickyColumns,
        isColumnTitles,
        tableRef,
        columnFields,
        columnWidths,
        fetchStatusCode,
        CustomRowComp,
        customRowProps,
        selectedRows,
        isColumnSelector,
        columnRef,
        exports,
        pagingOptions,
        isColumnSort,
        isOnRowSelect,
        activeColumnFilters,
        isPageEnd,
        disableColumnFilters,
        filterDebounceTime,
        customItemProps,
        pageCount,
        fields,
        isNestedComp,
        isOverflow,
        layoutType,
        deleteSuccess,
        deleteProcess,
        setId,
        scrollToIdAfterFetch,
        onScrollBottom,
        onScrollTop,
        setCustomScrollBottom,
        setFields,
        setPageCount,
        onCustomScrollBottom,
        setDeleteSuccess,
        setIsDeleting,
        setLayoutType,
        setIsOverflow,
        setIsNestedComp,
        setCustomItemProps,
        setIsOnPageEnd,
        prependRow,
        appendRow,
        onRefresh,
        setOnRefresh,
        disableClientRendering,
        setActiveColumnSort,
        sortTableData,
        setFetchStatus,
        setLoading,
        setRef,
        setHeight,
        setPagingOptions,
        setCustomRow,
        setExpandableRows,
        disableTableHeaders,
        setFilterDebounce,
        enableColumnSelector,
        enableStickyColumns,
        setIsOnRowSelect,
        setExportOptions,
        setFallbackHeight,
        updateCachedTableData,
        updateTableData,
        appendCachedTableData,
        setActivePage,
        updateDataCount,
        updateRowProps,
        resetGridPosition,
        setTableRef,
        setTableBodyRef,
        setTheadRef,
        isRowExpandable,
        isRowSelected,
        setLoadMoreRef,
        updateSearchQueries,
        resetSearchQueries,
        setColumnRef,
        addColumnField,
        addSearchQuery,
        editColumnField,
        updateColumnFields,
        setIsColumnSort,
        resetScrollBar,
        addSelectedRow,
        removeSelectedRow,
        updateSelectedRows,
        unSelectAllRows,
        selectAllRows,
        selectRow,
        filterTableData,
        updateActiveColumnFilters,
        removeActiveColumnFilter,
        addActiveColumnFilter,
        updateIsPageEnd,
        setShouldCachedDataRefresh,
        showSelectedRowsOnly,
        showAllRows,
        setDisableColumnFields,
        resetActiveColumnSort,
        removeCachedTableData,
        areAllColumnsDisabled,
        updatePersistedSelectedRows,
        updateByPtruiKey,
        removeByPtruiKey,
        updateByPredicate,
        removeByPredicate,
    }

}

export default useStore;