import React from 'react';
import { NavProps } from 'react-bootstrap';
import { SelectedActions as SelectedActionsMol } from '../../../molecules';
import useDataGrid from '../use-data-grid';
import { useResponsive } from '../../../../hooks';

interface Props {
    iconOnly?: boolean;
    className?: string;
    disabled?: boolean;
    enableMobile?: boolean;
}
export default function SelectedActions({ iconOnly, className, disabled, enableMobile }: Props) {

    const { deleteProcess, ...grid } = useDataGrid();
    const res = useResponsive();
    const count = grid.selectedRows.length || 0;
    const totalCount = (typeof grid.totalDataCount === 'number') ? grid.totalDataCount : 0;
    const isDisabled = (disabled) ? true : (grid.isLoading) ? true : (deleteProcess.isDeleting) ? true : false;
    const isIconOnly = (iconOnly) ? true : (!res.lgDown && enableMobile) ? true : false;

    return (
        <>
            <SelectedActionsMol
                small
                count={count}
                totalCount={totalCount}
                onSelectAllChange={(isChecked: boolean) => {
                    if (isChecked) {
                        grid.selectAllRows();
                    }
                    else {
                        grid.unSelectAllRows();
                    }
                }}
                onShowAllChange={(isChecked: boolean) => {
                    if (isChecked) {
                        setTimeout(() => grid.showSelectedRowsOnly(), 0);

                    } else {
                        grid.showAllRows();
                    }
                }}
                onUnselectClick={() => {
                    grid.unSelectAllRows();
                    grid.showAllRows();
                }}
                className={className}
                iconOnly={isIconOnly}
                disabled={isDisabled}
            />
        </>
    )
}