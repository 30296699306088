import React from 'react';
import { Dropdown } from 'react-bootstrap';
import CustomToggle from './custom-toggle';
import uniqid from 'uniqid';
import { IOptionSelector } from './interfaces';
import { InputOption, IInputOption } from '../../atoms';
import InputDropdown from '../input-dropdown';
import { useNonInitialEffect } from '../../../hooks';
import styles from './optionSelector.module.scss';
import { useI18N } from '../../../hooks';

interface Props {
    options: { [key: string]: any }[] | IInputOption.MenuItem[];
    defaultOption?: { [key: string]: any };
    activeOption?: { text: string, value: string | number, [key: string]: any };
    mapTextTo?: string;
    mapValueTo?: string | number;
    customOption?: (props: { option: IInputOption.MenuItem, [key: string]: any }) => JSX.Element;
    placeHolder?: string;
    className?: string;
    icon?: JSX.Element;
    onSelect?: (option?: IInputOption.MenuItem) => void;
    width?: string;
    minWidth?: string;
    size?: 'sm' | 'lg';
    disabled?: boolean;
    readOnly?: boolean;
    loading?: boolean;
    fetchStatus?: string | object | null | boolean;
}

export default function OptionSelector({
    options,
    defaultOption,
    activeOption: activeOptionProp,
    customOption,
    placeHolder,
    className,
    icon,
    onSelect,
    width,
    minWidth,
    size,
    mapTextTo,
    mapValueTo,
    readOnly,
    disabled,
    loading,
    fetchStatus
}: Props) {

    const i18n = useI18N();
    //sets the placeholder to specified or default
    const handlePlaceHolder = () => {

        if (placeHolder) {
            return {
                value: '',
                text: placeHolder
            }
        }
        else {
            return {
                value: '',
                text: i18n.translateThis('Choose an Option', i18n.localize.optionSelector.chooseAnOption)
            }
        }
    }

    //maps option data to correct props
    const mapOptionData = (data: { [key: string]: any }[]) => {
        if (mapTextTo !== undefined && mapValueTo !== undefined) {
            const options: any[] = data?.map((item: any) => {
                return {
                    ...item,
                    text: item[mapTextTo] || '',
                    value: item[mapValueTo] || 0,
                }
            })
            return options
        }
        return data;
    };

    const mapDefaultOption = (defaultOption?: { [key: string]: any } | IInputOption.MenuItem) => {
        const mappedOption =
            (defaultOption !== undefined) ?
                (defaultOption?.text !== undefined && defaultOption?.value !== undefined) ?
                    { text: defaultOption.text, value: defaultOption.value } :
                    (mapValueTo !== undefined && mapTextTo !== undefined) ?
                        { text: defaultOption[mapTextTo], value: defaultOption[mapValueTo] } :
                        undefined : undefined;

        return mappedOption;
    }

    const mappedOptionData = mapOptionData(options);
    const mappedDefaultOption = mapDefaultOption(defaultOption);
    if(mappedDefaultOption?.value == undefined || mappedDefaultOption?.text == undefined)
        console.error('mapTextTo or mapValueTo are undefined in datasoure');
    //sets selectedItem
    const [activeOption, setActiveOption] = React.useState<IOptionSelector.MenuItem>((mappedDefaultOption) ? mappedDefaultOption : handlePlaceHolder());

    React.useEffect(() => {
        if (activeOptionProp) {
            setActiveOption(activeOptionProp);
        }
    }, [activeOptionProp]);

    useNonInitialEffect(() => {
        setActiveOption((mappedDefaultOption) ? mappedDefaultOption : handlePlaceHolder());
    }, [options]);

    return (
        <>
            <Dropdown className={className} style={{ width: (width) ? width : 'auto', minWidth: (minWidth) ? minWidth : 'none' }}>
                <Dropdown.Toggle as={CustomToggle({ activeOption, icon, size, readOnly, disabled })} />
                <InputDropdown className={styles.compContainer} withInputIcon={(icon) ? true : false} loading={loading} error={fetchStatus}>
                    {
                        mappedOptionData?.map((option: IInputOption.MenuItem, index: number) => {
                            const key = uniqid();
                            return (
                                <React.Fragment key={key}>
                                    <InputOption
                                        option={option}
                                        activeOption={activeOption}
                                        customOption={customOption}
                                        setActiveOption={setActiveOption}
                                        index={index}
                                        onSelect={onSelect}
                                    />
                                </React.Fragment>
                            )
                        })
                    }
                </InputDropdown>
            </Dropdown>
        </>
    )
}