import React from 'react';
import { TableRow, TableRowExpand, TableCell, TableCellContent } from '../../../atoms';
import useRow from './use-row';
import useDataGrid from "../use-data-grid";

interface Props {
  rowData: {
    ptrui_cacheKey: number;
    ptrui_sortKey: number;
    [key: string]: any;
  };
  children?: JSX.Element | JSX.Element[];
}

export default function Row({ rowData, children }: Props) {

  const grid = useDataGrid();
  const row = useRow();
  //checks to see if row is active (selected)
  const isActive = (grid.selectedRows.find((row) => row.ptrui_cacheKey === rowData?.ptrui_cacheKey)) ? true : false;
  const isRowExpandable = (grid.ExpandableRowComp) ? grid.isRowExpandable(rowData) : false;
  const isStripped = (rowData) ? (rowData?.ptrui_cacheKey % 2 === 0) ? false : true : false;

  return (
    <>
      {
        (rowData) ?
          <>
            <TableRow
              noTextHighlight={row.disableTextHighlight}
              isActive={isActive}
              isStripped={isStripped}
              selectable={grid.isOnRowSelect}
              onSelect={row.onSelect}
              data={rowData}
            >
              {
                grid.columnFields?.map(
                  ({ field, enabled, trebleKey }: { field: string, enabled: boolean, trebleKey: number }) => {
                    const isCustomCell = (children) ? (React.Children.map(children, (child) => { if (child?.props.targetField === field) { return child } }).length > 0) ? true : false : false;
                    const customClassName = (children) ? (React.Children.map(children, (child) => { if (child?.props.targetField === field) { return child } }))[0]?.props.className : '';
                    //data to be rendered in cell, does type checks to make sure data does not break component
                    const cellData: string | number | null = (typeof rowData[field] === 'string' || typeof rowData[field] === 'number') ? rowData[field] : null;

                    return (
                      <React.Fragment key={trebleKey}>
                        <TableCell
                          width={grid.columnWidths.find((item: any) => item.target === field)?.width}
                          hide={(!enabled) ? true : false}
                          className={(isCustomCell) ? customClassName : ''}
                        >
                          <TableCellContent
                            isExpandable={isRowExpandable}
                            isExpanded={row.isExpanded}
                            index={trebleKey}
                            onClick={row.toggleIsExpanded}
                          >
                            <>
                              {
                                (isCustomCell) ?
                                  React.Children.map(children, (child) => {
                                    if (child?.props.targetField === field) {
                                      return child
                                    }
                                  }) :
                                  <p className={"p-0 m-0"}>
                                    {cellData}
                                  </p>
                              }
                            </>
                          </TableCellContent>
                        </TableCell>
                      </React.Fragment>
                    );
                  }
                )
              }
            </TableRow>

            {
              (grid.ExpandableRowComp) ?
                <TableRowExpand
                  isExpanded={row.isExpanded}
                  customRow={grid.ExpandableRowComp}
                  rowData={rowData}
                /> : null
            }
          </> : null
      }
    </>
  )
}