/*
  DataGrid
*/
import React from "react";
import { Content } from '..';
import { GridProvider } from '../../molecules';

export interface GridProps {
  children: JSX.Element | JSX.Element[];
  height?: string;
  className?: string;
  loading?: boolean;
  data: any[];
  as?: JSX.Element | object;
  pageSize?: number;
  disablePaging?: boolean;
  expandable?: () => JSX.Element;
  exportPDF?: boolean;
  exportExcel?: boolean;
  fetchStatus?: any;
  totalDataCount?: number;
  customExportOptions?: {
    type: 'excel' | 'pdf',
    label: string,
    onClick: () => void
  }[];
  columnTitles?: boolean;
  gridStyles?: React.CSSProperties;
  expandableCheck?: (item: any) => boolean;
  debounceFilters?: number;
  columnSelector?: boolean;
  stickyColumns?: boolean;
  onSelect?: (selectedRows: any[]) => void;
  onRefresh?: () => void;
  rowProps?: {
    [key: string]: any;
  };
}

export default function ClientGrid({
  children,
  height,
  className,
  loading,
  data,
  as,
  pageSize,
  disablePaging,
  expandable,
  exportPDF,
  exportExcel,
  fetchStatus,
  totalDataCount,
  customExportOptions,
  columnTitles,
  gridStyles,
  expandableCheck,
  debounceFilters,
  columnSelector,
  stickyColumns,
  onSelect,
  onRefresh,
  rowProps
}: GridProps) {

  return (
    <>
      <Content.Panel bodyClassName={'p-0'}>
        <GridProvider
          height={height}
          className={className}
          loading={loading}
          data={data}
          as={as}
          pageSize={pageSize}
          disablePaging={disablePaging}
          expandable={expandable}
          exportPDF={exportPDF}
          exportExcel={exportExcel}
          fetchStatus={fetchStatus}
          totalDataCount={totalDataCount}
          customExportOptions={customExportOptions}
          columnTitles={columnTitles}
          gridStyles={gridStyles}
          expandableCheck={expandableCheck}
          debounceFilters={debounceFilters}
          columnSelector={columnSelector}
          stickyColumns={stickyColumns}
          onSelect={onSelect}
          onRefresh={onRefresh}
          customItemProps={rowProps}
        >
          <>
            {children}
          </>
        </GridProvider>
      </Content.Panel>
    </>
  );
}

