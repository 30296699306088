import React from 'react';
import styles from './deletingIndicator.module.scss';

interface Props {
    className?: string;
    size?: number;
    color?: 'white';
    portrait?: boolean;
    landscape?: boolean;
}

export default function DeletingIndicator({ className, size, color, portrait, landscape }: Props) {

    const circleSize = () => {
        const computedSize = (size !== undefined) ? size : 3;
        return {
            height: `${computedSize}rem`,
            width: `${computedSize}rem`,
        }
    }
    const iconSize = () => {
        const computedSize = (size !== undefined) ? size / 3 : 3;
        return {
            fontSize: `${computedSize}rem`
        }
    }



    return (
        <>
            <div className={`d-flex ${(landscape) ? 'align-items-center' : (portrait) ? 'flex-column' : ''}`}>
                <div className={`d-flex justify-content-center align-items-center ${styles.compContainer}`}>
                    <div className={`${styles.pulse}`} style={circleSize()}></div>
                    <div
                        className={`d-flex justify-content-center align-items-center bg-danger m-4 ${styles.circle}`}
                        style={circleSize()}
                    >
                        <i className={`fas fa-trash-alt text-white`} style={iconSize()}></i>
                    </div>
                </div>
                <p className={`text-muted text-center ${(landscape) ? 'mb-0' : ''}`}><small>Deleting...</small></p>
            </div>
        </>
    )
}