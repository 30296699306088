import React from 'react';
import BtnLayoutOptions from '../../../atoms/btn-layout-options';
import useDataGrid from '../use-data-grid';
import { useResponsive } from '../../../../hooks';

interface Props {
    className?: string;
}
export default function LayoutOptions({ className }: Props) {

    const { deleteProcess, ...grid } = useDataGrid();
    const isDisabled = (grid.isLoading) ? true : (deleteProcess.isDeleting) ? true : false;
    const res = useResponsive();

    return (
        <>
            {
                (res.lgDown) ?
                    <BtnLayoutOptions
                        className={className}
                        onSingleLayout={() => grid.setLayoutType(1)}
                        onDoubleLayout={() => grid.setLayoutType(2)}
                        onTripleLayout={() => grid.setLayoutType(3)}
                        active={grid.layoutType}
                        disabled={isDisabled}
                    /> : null
            }
        </>
    )
}