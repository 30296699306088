import React from "react";
import { UserIcon, ListItem, ListItemDivider, ListItemHeader } from '../../atoms';
import IconWithDropdown from '../icon-with-dropdown';
import { useLogin } from '../../../hooks';
import { useLogoutModal, usePageState } from '../../../hooks';
import styles from './userMenu.module.scss';
import { useI18N } from '../../../hooks';

export default function UserMenu() {
    const i18n = useI18N();
    const { user } = useLogin();
    const page = usePageState();
    const logoutModal = useLogoutModal();

    return (
        <>
            <div className='d-flex justify-content-center'>
                {
                    (!page.isMobile) ?
                        <p className='d-flex align-items-center pr-3 mb-0 pt-1'>
                            {
                                (user.firstName.length > 0 && user.lastName.length > 0) ?
                                    <>{user.firstName} {user.lastName}</> :
                                    <>{i18n.translateThis('Please Sign In', i18n.localize.header.userMenu.pleaseSignIn)}</>
                            }
                        </p> : null
                }

                <IconWithDropdown icon={<UserIcon />} caret toolTip={false}>
                    {
                        (page.isMobile) ?
                            <>
                                <ListItemHeader>
                                    {
                                        (user.firstName.length > 0 && user.lastName.length > 0) ?
                                            <>{user.firstName} {user.lastName}</> :
                                            <>{i18n.translateThis('Please Sign In', i18n.localize.header.userMenu.pleaseSignIn)}</>
                                    }
                                </ListItemHeader>
                                <ListItemDivider />
                            </> : <></>

                    }
                    <ListItem
                        icon={<i className="fas fa-sign-out-alt text-muted"></i>}
                        text={i18n.translateThis('Log Out', i18n.localize.header.userMenu.logoutLabel)}
                        className={styles.dropdownLabels}
                        linkClassName={'d-flex align-content-center'}
                        onClick={() => logoutModal.open()}
                    />
                    {/* <ListItem
                    icon={<i className="fas fa-cogs text-muted"></i>}
                    text={'Settings'}
                    className={styles.dropdownLabels}
                    linkClassName={'d-flex align-content-center'}
                    onClick={() => settingsModal.open()}
                /> */}
                </IconWithDropdown>
            </div>
        </>
    )
}
