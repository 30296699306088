import React from 'react';
import { AddItemToGrid } from '../../../molecules';
import useDataGrid from '../use-data-grid';

interface Props {
    iconOnly?: boolean;
    className?: string;
    label?: string;
    rowToAdd?: {
        [key: string]: any;
    }
    disabled?: boolean;
    onAddRow?: (rowToAdd?: { [key: string]: any }) => void;
}
export default function AddRow({ iconOnly, className, label, rowToAdd, disabled, onAddRow: onClick }: Props) {

    const grid = useDataGrid();
    const isDisabled = (disabled) ? true : (grid.isLoading) ? true : false;
    const onAddRow = () => {
        if (rowToAdd) {
            grid.prependRow(rowToAdd);
            if (onClick) {
                onClick(rowToAdd);
            }
        }
    }


    return (
        <>
            <AddItemToGrid
                onClick={onAddRow}
                iconOnly={iconOnly}
                className={className}
                label={(label) ? label : 'Add Row'}
                small
                disabled={isDisabled}
            />
        </>
    )
}