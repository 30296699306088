import React from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import styles from './inputLoading.module.scss';
import { useI18N } from '../../../hooks';

export default function InputLoading() {
    const i18n = useI18N();

    return (
        <>
            <Dropdown.ItemText className='d-flex align-items-center'>
                <span className='text-info'><Spinner className={`${styles.spinnerIcon} mr-2`} animation={'border'} variant={'info'} />{i18n.translateThis('Loading...', i18n.localize.loading)}</span>
            </Dropdown.ItemText>
        </>
    )
}