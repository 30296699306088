import React from "react";
import { withTreble } from 'treble-gsm';
import { Store } from './Store';
import { Container, Row, Col } from 'react-bootstrap';
import { IContent } from './interfaces';
import styles from './content.module.scss';

function ContentComp({ backgroundImage, children, className }: IContent) {

  return (
    <Container
      fluid
      className={`${styles.compContainer} px-lg-0 ${className}`}
      style={
        backgroundImage
          ? { backgroundImage: `url(${backgroundImage})` }
          : {}
      }
    >
      <Row className={`no-gutters ${styles.compContainerRow}`}>
        <Col className='d-flex justify-content-center'>
          {children}
        </Col>
      </Row>
    </Container>
  );
}

const Content = withTreble(ContentComp, { store: Store })
export default Content;
