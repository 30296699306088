import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './addItemToGrid.module.scss';
import { useI18N } from '../../../hooks';

interface Props {
    iconOnly?: boolean;
    small?: boolean;
    large?: boolean;
    className?: string;
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
}
export default function AddItemToGrid({ iconOnly, small, large, className, onClick, label, disabled }: Props) {
    const i18n = useI18N();
    const size = (small) ? 'sm' : (large) ? 'lg' : undefined;

    return (
        <>
            {
                (iconOnly) ?
                    <i onClick={onClick} className={`fas cursor fa-plus-circle text-success ${styles.iconOnly} ${className}`}></i> :
                    <Button
                        size={(size === 'sm') ? 'sm' : (size === 'lg') ? 'lg' : undefined}
                        variant={'success'}
                        className={className}
                        onClick={onClick}
                        disabled={disabled}
                    >
                        <>
                            <span className={'pr-2'}>{<i className="fas fa-plus-circle text-white"></i>}</span>
                            <span className={'pr-1'}>{(label) ? label : i18n.translateThis('Add Item', i18n.localize.AddItemToGrid.addItem)}</span>
                        </>
                    </Button>


            }
        </>
    )
}