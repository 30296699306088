import React from 'react';
import { GridSplash } from '../../atoms';

interface Props {
    width?: number;
    height?: number;
    as?: 'div' | 'tr';
}
export default function GridNoColumnsSplash({ width, height, as }: Props) {

    const Splash = () => (
        <GridSplash
            width={width}
            height={height}
            icon={<i className="fad fa-columns text-info"></i>}
            text={'No Columns Selected...'}
        />
    );

    return (
        <>
            {
                (as === 'div') ?
                    <div style={{ height: height }}>
                        <Splash />
                    </div> :
                    (as === 'tr') ?
                        <tr>
                            <td className={'p-0 border-0'}>
                                <Splash />
                            </td>
                        </tr> :
                        <tr>
                            <td className={'p-0 border-0'}>
                                <Splash />
                            </td>
                        </tr>
            }
        </>
    )
}