/*
    Header
    - holds grid title or custom header actions
*/
import React from "react";
import { Content } from '../..';
import useDataGrid from '../use-data-grid';

interface Props {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}
function Header({ children, className }: Props) {

  const grid = useDataGrid();

  return (
    <>
      <Content.PanelHeader className={`${className}`} bgNone={grid.isNestedComp}>
        <>
          {children}
        </>
      </Content.PanelHeader>
    </>
  );
}

export default React.memo(Header);
