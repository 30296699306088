import React from 'react';
import {
    createStore,
    useScopedTreble,
    useTreble,
    TUseTreble
} from "treble-gsm";
import queryCardData from './middleware/query-card-data';
import normalizeCardData from './middleware/normalize-card-data';
import processState from "./middleware/process-state";


interface IState {
    cardGridData: { [key: number]: { [key: string]: any } };
    queriedCardGridData: { [key: number]: { [key: string]: any } };
    renderedCardData: { [key: string]: any }[][],
    loadingState: boolean;
    cardCheckType: 'radio' | 'checkbox';
    CustomCard: any | null;
    searchQuery: {
        query: string,
        targets: string[]
    }
    alphaFilter: {
        active: string,
        target: string,
        default: string
    }
    searchDebounce: number;
    checkedList: number[];
    selectAll: boolean;
    activePage: number;
    cardGridProviderRef: React.RefObject<null>;
    fetchStatusCode: any
}

interface IActions {
    'updateCardGridData': string;
    'updateQueriedCardGridData': string;
    'setLoadingState': string;
    'setCardCheckType': string;
    'setCustomCard': string;
    'updateSearchQuery': string;
    'updateAlphaFilter': string;
    'setSearchDebounce': string;
    'updateCheckedList': string;
    'setSelectAll': string;
    'updateRenderedCardData': string;
    'updateActivePage': string;
    'setCardGridProviderRef': string;
    'setFetchStatusCode': string;
}
export type TStore = TUseTreble<IState, IActions>;

//create scoped store context
const CardGridContext = useScopedTreble();

//create custom treble hook
export const useCardGridStore = () => useTreble(CardGridContext) as TStore;

//treble store
export const Store = createStore([
    {
        action: 'updateCardGridData',
        state: {
            cardGridData: []
        },
        features: {
            process: (data) => normalizeCardData(data.dispatchValue)
        }
    },
    {
        action: 'updateQueriedCardGridData',
        state: {
            queriedCardGridData: []
        },
        features: {
            process: (data) => queryCardData(data)
        }
    },
    {
        action: 'updateRenderedCardData',
        state: {
            renderedCardData: []
        },
        features: {
            process: (data) => processState(data)
        }
    },
    {
        action: 'setLoadingState',
        state: {
            loadingState: false
        }
    },
    {
        action: 'setSearchDebounce',
        state: {
            searchDebounce: 250
        }
    },
    {
        action: 'setCardCheckType',
        state: {
            cardCheckType: 'checkbox'
        }
    },
    {
        action: 'setCustomCard',
        state: {
            CustomCard: null
        }
    },
    {
        action: 'updateSearchQuery',
        state: {
            searchQuery: {
                query: '',
                targets: []
            }
        }
    },
    {
        action: 'updateAlphaFilter',
        state: {
            alphaFilter: {
                active: 'all',
                target: '',
                default: 'all'
            }
        }
    },
    {
        action: 'updateCheckedList',
        state: {
            checkedList: []
        }
    },
    {
        action: 'setSelectAll',
        state: {
            selectAll: false
        }
    },
    {
        action: 'updateActivePage',
        state: {
            activePage: 0
        }
    },
    {
        action: 'setCardGridProviderRef',
        state: {
            cardGridProviderRef: null
        }
    },
    {
        action: 'setFetchStatusCode',
        state: {
            fetchStatusCode: null
        }
    }

], {
    context: CardGridContext
});

