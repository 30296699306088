import { useAppStore } from '../_Store';
import useLogin from './use-login';
import useMainMenu from './use-main-menu';
import useStartUp from './use-start-up';

const useLogoutModal = () => {

    const [{ ptrui_logoutModalInfo: modalInfo }, Store, Util] = useAppStore();
    const login = useLogin();
    const mainMenu = useMainMenu();
    const startup = useStartUp();

    const isOpen = modalInfo.show;
    const cancel = (modalInfo.cancel) ? modalInfo.cancel : () => null;
    //const confirm = (modalInfo.confirm) ? modalInfo.confirm : () => null;
    const isConfirmAction = (modalInfo.confirm) ? true : false;
    const redirectTo = modalInfo.redirectTo;
    const confirmBtnLabel = modalInfo.confirmBtnText || 'Confirm';
    const open = () => {
        Store.update(Util.actions.ptrui_setLogoutModalInfo, {
            ...modalInfo,
            show: true
        });
    }
    const close = () => {
        Store.update(Util.actions.ptrui_setLogoutModalInfo, {
            ...modalInfo,
            show: false
        });
    }
    const toggle = () => {
        Store.toggle(Util.actions.ptrui_setLogoutModalInfo, isOpen);
    }
    const confirm = () => {
        if (!isConfirmAction) {
            login.clearUser();
            login.clearToken();
            startup.setInitialPath('/');
            if (typeof redirectTo === 'string') {
                window.location.replace(redirectTo);
            } else {
                login.setIsAuth(false);
            }
        }
        else {
            //logout.confirm();//Need to put custom confirm action here
            login.clearUser();
            login.clearToken();
            mainMenu.close();
            startup.setInitialPath('/');
            close();

            if (typeof redirectTo === 'string') {
                window.location.replace(redirectTo);
            }
            else {
                login.setIsAuth(false);
            }
        }
    }

    return {
        isOpen,
        cancel,
        confirm,
        redirectTo,
        confirmBtnLabel,
        isConfirmAction,
        open,
        close,
        toggle
    }
}

export default useLogoutModal;