import React from 'react';
import { default as CardGridComp } from './card-grid';
import Header from './header';
import Body from './body';
import Footer from './footer';
import UilityBar from './utility-bar';
import LayoutOptions from './layout-options';
import ExportToExcel from './export-to-excel';
import ExportToPDF from './export-to-pdf';
import RefreshData from './refresh-data';
import Counters from './counters';
import AlphaFilter from './alpha-filter';
//import SelectedActions from './selected-actions';
import Search from './search';
import RemoveItem from './remove-item';
import { GridProps } from './card-grid';
import { useGrid } from './use-data-grid';

interface ICardGrid {
    (props: GridProps): JSX.Element;
    Header: typeof Header;
    Body: typeof Body;
    Footer: typeof Footer;
    UtilBar: typeof UilityBar;
    ExportToExcel: typeof ExportToExcel;
    ExportToPDF: typeof ExportToPDF;
    LayoutOptions: typeof LayoutOptions;
    RefreshData: typeof RefreshData;
    Counters: typeof Counters;
    AlphaFilter: typeof AlphaFilter;
    RemoveItem: typeof RemoveItem;
    //SelectedActions: typeof SelectedActions;
    Search: typeof Search;
    useGrid: typeof useGrid;
}

const DataCardGrid: ICardGrid = (props) => <CardGridComp {...props} />;
DataCardGrid.Header = Header;
DataCardGrid.Body = Body;
DataCardGrid.Footer = Footer;
DataCardGrid.UtilBar = UilityBar;
DataCardGrid.LayoutOptions = LayoutOptions;
DataCardGrid.ExportToExcel = ExportToExcel;
DataCardGrid.ExportToPDF = ExportToPDF;
DataCardGrid.RefreshData = RefreshData;
DataCardGrid.Counters = Counters;
DataCardGrid.AlphaFilter = AlphaFilter;
//DataCardGrid.SelectedActions = SelectedActions;
DataCardGrid.RemoveItem = RemoveItem;
DataCardGrid.Search = Search;
DataCardGrid.useGrid = useGrid;

export default DataCardGrid;