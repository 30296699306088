import React from 'react';
import { useNonInitialEffect } from '../../../../hooks';
import { InputField } from '../../../atoms';
import useDataGrid from '../use-data-grid';
import useColumnSearchFilter from './use-column-search-filter';
import { useI18N } from '../../../../hooks';

interface Props {
    onChange?: (value?: string) => void;
    value?: string;
    field: string;
}
export default function ColumnSearchFilter({ field, onChange, value: valueProp }: Props) {
    const i18n = useI18N();
    const grid = useDataGrid();
    const search = useColumnSearchFilter(field, onChange);

    //listens for value change and then updates search query
    useNonInitialEffect(() => {
        search.updateQuery(search.inputValue, grid.searchQueries);
    }, [search.inputValue]);

    //addes default search query object
    React.useEffect(() => {
        grid.addSearchQuery({
            target: field,
            query: '',
            exact: false,
            type: "string"
        });
    }, []);

    //watches for external reset
    useNonInitialEffect(() => {
        search.onReset(valueProp);
    }, [valueProp]);

    return (
        <>
            <InputField
                {...search.bindToInput}
                className={'pt-2'}
                placeHolder={i18n.translateThis('Search', i18n.localize.dataGrid.search)}
                size={'sm'}
                type={'text'}
                disabled={grid.isLoading}
            >

            </InputField>
        </>
    )
}