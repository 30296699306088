/*
    Column
    - component for table colums
*/
import React, { useRef } from "react";
import { useNonInitialEffect } from '../../../../hooks';
import { GridColumn } from '../../../molecules';
import ColumnSelectFilter from '../column-select-filter';
import ColumnSearchFilter from '../column-search-filter';
import useColumn from './use-column';
import useDataGrid from '../use-data-grid';

interface Props {
  title: string | JSX.Element;
  sortable?: boolean;
  search?: boolean;
  select?: {
    value: string | number,
    text: string
  }[] | boolean;
  field: string;
  width?: string;
  dataType?: 'string' | 'number' | 'boolean';
  children?: JSX.Element | JSX.Element[];
  hide?: boolean;
  hideTitle?: boolean;
}
export default function Column({ title, sortable, field, search, select, width, dataType, children, hide, hideTitle }: Props) {

  const grid = useDataGrid();
  const filterType = (search) ? 'search' : (select) ? 'select' : undefined;
  const column = useColumn(field);
  const columnRef = useRef(null);
  const memoChildren = React.useMemo(() => children, []);

  React.useLayoutEffect(() => {
    //creates static state in the store out of column props on render
    grid.addColumnField({
      field: field,
      title: title,
      width: width || '',
      enabled: (hide) ? false : true,
      dataType: dataType
    });

    //sets isColumnSort state to true if a column sort exists
    column.isColumnSort(sortable);
  }, []);

  //saved column ref to Store
  React.useEffect(() => {
    grid.setColumnRef(columnRef);
  }, [columnRef]);

  //add or remove active column filters
  React.useEffect(() => {
    column.setActiveFilters(filterType)
  }, [column.isActiveFilter]);

  //hides column
  useNonInitialEffect(() => {
    column.hideColumn((hide) ? false : true);
  }, [hide]);

  //resets sort state when not active
  useNonInitialEffect(() => {
    column.resetSort();
  }, [grid.activeColumnSort.field]);

  return (
    <>
      <GridColumn
        refProp={columnRef}
        width={width}
        hide={(column.isHidden()) ? true : false}
        title={(!hideTitle) ? title : ''}
        topPadding={grid.isColumnSort}
        isSort={sortable}
        isFilter={(search || select) ? true : false}
        isFilterActive={column.isActiveFilter}
        sortType={column.sortType}
        onSortChange={() => column.toggleSortType()}
      >
        <>
          {
            (select) ?
              <ColumnSelectFilter
                field={field}
                options={(typeof select === 'object') ? select : []}
                onChange={column.activateFilterIndicator}
              /> : null
          }
          {
            (search) ?
              <ColumnSearchFilter
                field={field}
                onChange={column.activateFilterIndicator}
                value={grid.searchQueries.find((item) => item.target === field)?.query as string}
              /> : null
          }
          {memoChildren}
        </>
      </GridColumn>
    </>
  );
}
