import IConfig from '../config/interfaces';
import { useAppStore } from '../_Store';
import usePageState from './use-page-state';

const useConfig = () => {

    const [State, Store, Util] = useAppStore();
    const page = usePageState();

    const isAlerts = (State.ptrui_appAlerts) ? true : false;
    const foo = 'text';
    const isMainMenu = (State.ptrui_appMainMenu) ? true : false;
    const isSearch = (State.ptrui_appSearch) ? true : false;
    const isFrontEndLogin = (State.ptrui_isFrontEndLogin) ? true : false;
    const isLanguageSwitcher = (State.ptrui_languageSwitcher) ? true : false;
    const isCheckAuthBeforeRender = (State.ptrui_checkAuthBeforeRender) ? true : false;

    const appLogo = State.ptrui_appLogo;
    const loginPageInfo = { title: State.ptrui_loginPageInfo.title, form: State.ptrui_loginPageInfo.form, altUrlLogin: State.ptrui_loginPageInfo.altUrlLogin };

    const setDefaultLocaleKey = (key?: 'en' | 'es') => {
        if (key) {
            Store.update(Util.actions.ptrui_setLocaleKey, key);
        }
    };

    const setAlerts = (alerts?: boolean) => Store.update(Util.actions.ptrui_enableAppAlerts, (alerts) ? true : false);

    const setSearch = (search?: IConfig.Search | boolean) => {
        if (search) {
            Store.update(Util.actions.ptrui_enableAppSearch, search);
        }
        else {
            Store.update(Util.actions.ptrui_enableAppSearch, false);
        }
    };

    const setMainMenu = (mainMenu?: boolean) => {
        Store.update(Util.actions.ptrui_enableAppMainMenu, (mainMenu) ? true : false);
    };

    const setSettings = (settings?: IConfig.Settings[]) => {
        Store.update(Util.actions.ptrui_enableAppSettings, settings);
    };

    const setAppLogo = (appLogo?: IConfig.AppLogo) => {
        if (appLogo) {
            Store.update(Util.actions.ptrui_setAppLogo, appLogo);
        }
    };

    const setAppVersion = (appVersion?: string | number) => {
        if (appVersion !== undefined) {
            Store.update(Util.actions.ptrui_setAppVersion, appVersion)
        }
    };

    const setBaseHeaders = (apiBaseHeaders?: IConfig.ApiBaseHeaders) => {
        if (apiBaseHeaders) {
            Store.update(Util.actions.ptrui_baseHeader, apiBaseHeaders)
        }
    };

    const setAPIBaseURLTypes = (apiBaseURLTypes?: IConfig.ApiBaseURLTypes) => {
        if (apiBaseURLTypes) {
            Store.update(Util.actions.ptrui_baseURLTypes, apiBaseURLTypes);
        }
    };

    const setAPIFetchCredentials = (apiFetchCredentials?: IConfig.ApiFetchCredentials) => {
        if (apiFetchCredentials) {
            Store.update(Util.actions.ptrui_updateApiFetchCredentials, apiFetchCredentials);
        }
    };

    const setAppLogin = (appLogin?: IConfig.AppLogin) => {
        if (appLogin) {
            Store.update(Util.actions.ptrui_setloginPageInfo, { title: appLogin.formTitle, form: appLogin.form, altUrlLogin: appLogin.altUrlLogin });
        }
        Store.update(Util.actions.ptrui_setIsFrontEndLogin, (appLogin) ? true : false);
    };

    const setAppLogout = (appLogout?: IConfig.AppLogout) => {
        if (appLogout) {
            Store.update(Util.actions.ptrui_setLogoutModalInfo, {
                ...State.ptrui_logoutModalInfo,
                confirm: appLogout?.confirmAction,
                cancel: appLogout?.cancelAction,
                redirectTo: appLogout?.redirectTo
            });
        }
    };

    const setCollapseWidgetBar = (isCollapsed?: boolean) => {
        if (isCollapsed) {
            page.setIsWidgetBarOpen(false);
        }
        else {
            page.setIsWidgetBarOpen(true);
        }
    }

    const setNoAuthRedirect = (noAuthRedirect?: string) => Store.update(Util.actions.ptrui_noAuthRedirect, noAuthRedirect);
    const setCheckAuthBeforeRender = (checkAuthBeforeRender?: boolean) => Store.update(Util.actions.ptrui_setCheckAuthBeforeRender, checkAuthBeforeRender);
    const setLanguageSwitcher = (languageSwitcher?: boolean) => Store.update(Util.actions.ptrui_setLanguageSwitcher, languageSwitcher);
    const setExternali18nKeys = (externali18nKeys?: any) => 
    {
        Store.update(Util.actions.ptrui_setExternali18nKeys, externali18nKeys);
    }

    return {
        setAlerts,
        setSearch,
        setMainMenu,
        setSettings,
        setAppLogo,
        setAppVersion,
        setBaseHeaders,
        setAPIBaseURLTypes,
        setAPIFetchCredentials,
        setAppLogin,
        setAppLogout,
        setNoAuthRedirect,
        setCheckAuthBeforeRender,
        setLanguageSwitcher,
        setCollapseWidgetBar,
        setDefaultLocaleKey,
        setExternali18nKeys,
        isAlerts,
        isMainMenu,
        isSearch,
        isFrontEndLogin,
        isLanguageSwitcher,
        isCheckAuthBeforeRender,
        appLogo,
        loginPageInfo
    }

}

//only exposing one method for now (doing this because config.tsx has trouble handling state, cannot figure out why)
const usePublicConfig = () => {
    const config = useConfig();
    const setBaseHeaders = config.setBaseHeaders;
    const setVersion = config.setAppVersion;
    return {
        setBaseHeaders,
        setVersion
    }
}

export { usePublicConfig }
export default useConfig;