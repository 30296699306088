/*
    English Locales
*/
import ILocalize from './interface';

const en: ILocalize = {
    header: {
        userMenu: {
            logoutLabel: 'Log Out',
            logoutModal: {
                message: 'Are you sure you want to Log Out?',
                buttons: {
                    cancel: 'No Thanks',
                    confirm: 'Log Out'
                }
            },
            pleaseSignIn : "Please Sign In",
        },
        languageSwitcher: 'Choose a Language'
    },
    footer: {
        copyright: 'Copyright 2021 ProTrans International In. All rights reserved.'
    },
    mainMenu: {
        title: 'Main Menu',
        home: 'Home'
    },
    subMenuBar: {
        search: {
            placeHolder: 'Search'
        }
    },
    timeSelector : {
        chooseATimeLabel : 'Choose a Time'
    },
    dateSelector : {
        chooseADateLabel : 'Choose a Date',
        chooseDateRangeLabel : 'Choose Date Range'
    },
    dayPicker : {
        reset : "Reset",
        today : "Today"
    },
    customToggle : {
        search : 'Search...'
    },
    optionSelector : {
        chooseAnOption : "Choose An Option"
    },
    btnRemove : {
        removeItem : 'Remove Item'
    },
    InputError : {
        fetchError : 'Fetch Error'
    },
    RefetchDataBtn : {
        refetchData : 'Refetch Data',
        fetching : '...Fetching'
    },
    AddItemToGrid : {
        addItem : 'Agregar Elemento'
    },
    columnSelector : {
        selectColumns : 'Select Columns',
        selectAll : 'Select All',
        columns : 'Columns'
    },
    createGridItem : {
        createItem : 'Create Item',
        noForm : 'No Form has been added yet.'
    },
    exportBtnExcel : {
        exportExcel : 'Export Excel'
    },
    exportBtnPDF : {
        exportPdf : 'Export PDF'
    },
    fileUpload : {
        upload : "Upload"
    },
    gridSplash : {
        text : "Something Went Wrong!",
        smallText : "Fetch Error:"
    },
    input : {
        search : 'Search'
    },
    languageSwitcher : {
        headerText : 'Pick a language',
        settingLanguage : 'Setting language to English.'
    },
    refreshData: {
        fetching : 'Fetching...',
        refreshData : 'Refresh Data',
        refreshError : 'Refresh Error'
    },
    removeGridItems : {
        deleteQuestion : "Are you sure you want to delete",
        cancel : "Cancel"
    },
    resetFilters : {
        resetFilters : 'Reset Filters'
    },
    selectedActions : {
        showSelectedOnly : 'Show Selected Only',
        selectAll : 'Select All',
        clearAll : 'Clear All',
        selected : 'Selected',
    },
    alertMenu : {
        alerts : 'ALERTS'
    },
    exportTo : {
        exporting : 'Exporting',
        exported : 'Exported',
        item : 'item',
        items : 'items',
    },
    confirmDialog : {
        areYouSure : "Are you sure",
        cancel : "Cancel",
        confirm : "Confirm"
    },
    languageModal : {
        title : 'Change Language'
    },
    checkAuth: {
        authenticating : 'Authenticating'
    },
    loginForm: {
        signingIn : 'Signing In'
    },
    cardGrid : {
        search : "Search"
    },
    clientCardGrid : {
        search : "Search"
    },
    clientGrid : {
        search : "Search"
    },
    dataCardGrid : {
        search : "Search"
    },
    dataGrid : {
        search : "Search"
    },
    loading :  "Loading...",
    fetching : "Fetching...",
    sorryNoResults : "Sorry, No Results",
    all : "All",
    filtered : "Filtered",
    selected : "Selected",
    current: "Current"
}

export default en;