import React from 'react';
import useDataGrid from '../use-data-grid';
import { useResponsive } from '../../../../hooks';

interface Props {
    layoutType?: 1 | 2 | 3;
}
export default function WatchLayout({ layoutType }: Props) {

    const res = useResponsive();
    const grid = useDataGrid();

    //auto sets layout based on window or screen size
    React.useEffect(() => {
        if (res.lgDown) {
            grid.setLayoutType(layoutType);
        }
        else if (res.mdDown) {
            grid.setLayoutType(1);
        }
    }, [res.lgDown, res.mdDown]);

    return (<></>);
}