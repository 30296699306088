import React from 'react';
import Button from 'react-bootstrap/Button';
import { BtnRemove } from '../../atoms';
import ModalBasic from '../modal-basic';
import { useNonInitialEffect } from '../../../hooks';
import styles from './removeGridItem.module.scss';
import { useI18N } from '../../../hooks';

interface Props {
    iconOnly?: boolean;
    small?: boolean;
    large?: boolean;
    className?: string;
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
    modalSize?: "sm" | "lg" | "md" | "xl";
    modalText?: JSX.Element | string;
    onModalOpen?: () => void;
    onModalClose?: () => void;
    itemsToRemove?: { ptrui_cacheKey: number, [key: string]: any }[];
    onRemove?: (itemsToRemove: { ptrui_cacheKey: number, [key: string]: any }[]) => boolean;
    deleteBtnLabel?: string;
    hideCounter?: boolean;
}
export default function RemoveGridItem({ iconOnly, small, large, className, label, disabled, modalSize, onModalOpen, onModalClose, itemsToRemove, onRemove, modalText, deleteBtnLabel, hideCounter, onClick }: Props) {
    const i18n = useI18N();
    const size = (small) ? 'sm' : (large) ? 'lg' : undefined;
    const [show, setShow] = React.useState(false);
    const isDisabled = (disabled) ? true : (itemsToRemove) ? (itemsToRemove.length === 0) ? true : false : false;
    const onClickFN = () => {
        if (onClick) {
            onClick();
        }
        setShow(true);
    }
    const onHide = () => {
        setShow(false);
        if (onModalClose) {
            onModalClose();
        }
    };
    const onDelete = () => {
        if (onRemove && itemsToRemove) {
            if (onRemove(itemsToRemove)) {
                setShow(false);
            };
        }
    }
    const onCancel = () => {
        setShow(false);
    }

    useNonInitialEffect(() => {
        if (show) {
            if (onModalOpen) {
                onModalOpen();
            }
        }
    }, [show]);

    return (
        <>
            <ModalBasic
                size={(modalSize) ? modalSize : undefined}
                show={show}
                onHide={() => onHide()}
                centered={false}
                animation={true}
                backdrop={'static'}
            >
                <ModalBasic.Body>
                    <>
                        <div className='d-flex justify-content-center align-items-center p-4'>
                            <i className={`${styles.icon} fad fa-exclamation-triangle text-danger`}></i>
                        </div>
                        <div className='text-center'>
                            <p className='text-center'>
                                {
                                    (modalText) ? modalText :
                                        <>
                                            {i18n.translateThis('Are you sure you want to delete', i18n.localize.removeGridItems.deleteQuestion)} <strong> {itemsToRemove?.length}</strong> ?
                                        </>
                                }
                            </p>
                        </div>
                    </>
                </ModalBasic.Body>
                <ModalBasic.Footer className={'d-flex justify-content-between'}>
                    <Button variant={'danger'} onClick={onDelete}>
                        <i className="fas fa-trash-alt pr-2"></i>
                        {(deleteBtnLabel) ? deleteBtnLabel : 'Delete'}
                    </Button>
                    <Button variant={'primary'} onClick={onCancel}>
                        {i18n.translateThis('Cancel', i18n.localize.removeGridItems.deleteQuestion)}
                    </Button>
                </ModalBasic.Footer>
            </ModalBasic>
            <BtnRemove
                onClick={onClickFN}
                iconOnly={iconOnly}
                disabled={isDisabled}
                className={className}
                size={size}
                label={label}
                hideCounter={hideCounter}
                itemsToRemove={itemsToRemove}
            />
        </>
    )
}